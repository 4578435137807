import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import FeedBackStarField from "./FeedBackStarField";
import firebase from "src/firebase";
import MultiLineTextArea from "../../../global/elements/TypographyElements/MultiLineTextArea";
import AppButton from "../../../global/elements/AppButton";
import { updateMission } from "../../../../services/missions";
import {
  functionStatus,
  notificationType,
  orderCollectionData,
  userRoles,
} from "../../../../lib/constants";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { updateProject } from "src/services/project";
import { getUser } from "src/services/auth";
import { createNotification } from "src/services/notification";

const styles = {
  btnContainer: {
    float: "right",
  },
  cancelBtn: {
    float: "right",
    marginRight: "30px",
  },
};

function FeedbackOrder({ data, type, refreshPage }) {
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = getUser("user").role === userRoles.admin;
  const isClient = getUser("user").role === userRoles.client;
  const [editMode, setEditMode] = useState(
    !data?.overAllExperience > 0 || false
  );
  const [loading, setLoading] = useState(false);
  const [ratingData, setRatingData] = useState({
    overAllExperience: data?.overAllExperience || 0,
    qualityOfService: data?.qualityOfService || 0,
    qualityOfData: data?.qualityOfData || 0,
    valueForMoney: data?.valueForMoney || 0,
    // recommendationLikelihood: data?.recommendationLikelihood || 0,
    returningLikelihood: data?.returningLikelihood || 0,
    clientFeedback: data?.clientFeedback || "",
  });

  const onChangeHandlerRating = (name, newValue) => {
    setRatingData((prev) => ({ ...prev, [name]: newValue }));
  };

  const submitHandler = async () => {
    if (editMode) {
      if (!ratingData.overAllExperience > 0) {
        enqueueSnackbar("Overall experience is required.", {
          variant: "warning",
        });
        return;
      }
      setLoading(true);
      let status;
      if (type === orderCollectionData.missions) {
        status = await updateMission(firebase, data?.id, ratingData);
      } else {
        status = await updateProject({
          projectId: data?.id,
          updates: ratingData,
        });
      }
      if (status?.status === functionStatus.success) {
        const subHeading = `Client has submiited a feedback for the ${
          type === orderCollectionData.missions
            ? data.projectName
            : data.missionName
        } order.`;
        const heading = "Feedback has been submitted by a client.";
        const actionLink =
          type === orderCollectionData.missions
            ? `/app/missions/${data.id}#client-feedback`
            : `/app/admin/projects/${data.id}#client-feedback`;
        if (isClient) {
          createNotification(
            firebase,
            notificationType.adminNotification,
            subHeading,
            heading,
            actionLink
          );
        }

        refreshPage();
        enqueueSnackbar(
          `Feedback ${
            data?.overAllExperience > 0 ? "updated" : "submitted"
          } successfully!!!`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar("Error in submitting feedback.", { variant: "error" });
      }
      setEditMode(false);
      setLoading(false);
    } else {
      setEditMode(true);
    }
  };

  const cancelEditing = () => {
    setEditMode(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="How happy are you with your overall experience for this order?"
          name="overAllExperience"
          value={ratingData?.overAllExperience || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
          isRequired
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="How would you rate the quality of the service you received?"
          name="qualityOfService"
          value={ratingData?.qualityOfService || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="How would you rate the quality of the data you received?"
          name="qualityOfData"
          value={ratingData?.qualityOfData || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="Do you feel the price you paid for this order was a good value for money?"
          name="valueForMoney"
          value={ratingData?.valueForMoney || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
        />
      </Grid>

      {/* <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="How likely are you to recommend Globhe to others who could be interested in its services?"
          name="recommendationLikelihood"
          value={ratingData?.recommendationLikelihood || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
        />
      </Grid> */}
      <Grid item xs={12} lg={6}>
        <FeedBackStarField
          label="How likely are you to return to Globhe next time you need such services?"
          name="returningLikelihood"
          value={ratingData?.returningLikelihood || 0}
          onChange={onChangeHandlerRating}
          precision={0.5}
          disabled={!editMode}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1Med">Client&apos;s feedback</Typography>
        <MultiLineTextArea
          defaultValue={ratingData.clientFeedback}
          placeholder="Give us your feedback"
          isDisabled={!editMode}
          variant="plain"
          minRows={2}
          multilineText={!editMode}
          required={false}
          name="clientFeedback"
          displayText={editMode}
          handleChange={(e) =>
            onChangeHandlerRating("clientFeedback", e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <AppButton
          label={
            editMode
              ? "Submit"
              : ratingData?.overAllExperience > 0
              ? isAdmin
                ? "Edit client\u0027s feedback"
                : "Edit your feedback"
              : isAdmin
              ? "Share Client\u0027s Feedback"
              : "Share Feedback"
          }
          onClick={submitHandler}
          addtionalStyle={styles.btnContainer}
          submittingState={loading}
          look={editMode ? "green" : "black"}
          noIcon={!editMode}
        />
        {editMode && (
          <AppButton
            look="black"
            label="Cancel"
            onClick={cancelEditing}
            addtionalStyle={styles.cancelBtn}
          />
        )}
      </Grid>
    </Grid>
  );
}

FeedbackOrder.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  refreshPage: PropTypes.func.isRequired,
};

export default FeedbackOrder;
