import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import SingleLocationMap from "../../../CreateOrder/Components/SingleLocationMap";
import MissionContext from "./MissionContext";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  getCountryName,
  getOrderType,
  getOrderTypeName,
} from "../../../../../lib/helpers";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import StatusBadge from "../../../../global/elements/StatusBadge";
import {
  colorStatus,
  missionFeasibility,
  missionPlanningStates,
  missionPlanningStatusLabel,
  missionPlanningType,
  missionTypeConstant,
  userRoles,
} from "../../../../../lib/constants";
import { getUser } from "../../../../../services/auth";
import theme from "../../../../../theme";
import AppButton from "../../../../global/elements/AppButton";
import OrderMenu from "./OrderMenu";
import UploadFileInfoContainer from "src/components/global/elements/UploadFileInfoContainer";
import PropTypes from "prop-types";

const styles = {
  text: {
    margin: "auto",
    marginLeft: "10px",
  },
  typeBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
  },
  textMargin: {
    marginBottom: "0 !important",
  },
  greyText: {
    color: theme.palette.black.paragraphText,
  },
  statusGrid: {
    textAlign: "right",
  },
  rightAlign: {
    display: "flex",
    gap: 2,
    justifyContent: "right",
  },
  image: {
    width: "100%",
    margin: "auto",
    maxHeight: "100px",
    objectFit: "contain",
  },
  imageContainer: {
    alignSelf: "center",
  },
};

const AdminClientMissionPlanning = ({ tabValue }) => {
  const missionContextData = useContext(MissionContext);
  const currentUserRole = getUser("user").role;
  const getStatusLabels = (status) => {
    if (missionContextData?.mission.status === missionPlanningStates.canelled) {
      return missionPlanningStatusLabel.canelled;
    } else if (
      missionContextData?.mission.status === missionPlanningStates.completed
    ) {
      return missionPlanningStatusLabel.completed;
    }
    switch (status) {
      case missionPlanningStates.canelled: {
        return missionPlanningStatusLabel.canelled;
      }
      case missionPlanningStates.reviewed: {
        return missionPlanningStatusLabel.reviewed;
      }
      case missionPlanningStates.notFeasible: {
        return missionPlanningStatusLabel.notFeasible;
      }
      case missionPlanningStates.reschedule: {
        return missionPlanningStatusLabel.reschedule;
      }
      case missionPlanningStates.readyToUpload: {
        return missionPlanningStatusLabel.readyToUpload;
      }
      case missionPlanningStates.pendingUpload: {
        return missionPlanningStatusLabel.pendingUpload;
      }
      case missionPlanningStates.inReview: {
        return missionPlanningStatusLabel.inReview;
      }
      case missionPlanningStates.delayed: {
        return missionPlanningStatusLabel.delayed;
      }
      default:
        return null;
    }
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Grid container xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Mission Details
          </Typography>
          <Box>
            <OrderMenu
              title="Order Type"
              content={getOrderType(missionContextData?.mission)}
              contentRight={getOrderTypeName(missionContextData?.mission)}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid
            item
            xs={8.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box>
              <Typography variant="h6" sx={styles.text}>
                {missionContextData?.mission?.missionName}
              </Typography>
              <Typography variant="body1" sx={styles.text}>
                {missionContextData?.mission?.id}
              </Typography>
            </Box>
            {getStatusLabels(missionContextData?.missionPlanning?.status) && (
              <StatusBadge
                name={
                  getStatusLabels(missionContextData?.missionPlanning?.status)
                    ?.label
                }
                status={
                  getStatusLabels(missionContextData?.missionPlanning?.status)
                    ?.color
                }
              />
            )}
          </Grid>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={6} sx={styles.typeBox}>
              <LocationOnOutlinedIcon fontSize="medium" />
              <Typography variant="body1" sx={styles.textMargin} ml={1}>
                {getCountryName(missionContextData?.mission)}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={styles.typeBox}>
              <EditRoadSharpIcon fontSize="medium" />
              <Typography variant="body1" sx={styles.textMargin} ml={1}>
                {missionContextData?.mission?.areaSize?.toFixed(2)} sq km
              </Typography>
            </Grid>
          </Grid>
          {tabValue === missionPlanningType.postFlight &&
            missionContextData?.missionPlanning?.data?.ableToCaptureData !==
              undefined && (
              <Grid sx={{ mb: 2 }}>
                <Typography variant="subtitle1Med" fontSize={20}>
                  Was the Drone Operator able to access the site and fly?
                  <Box component="span" sx={{ ml: 2 }}>
                    {missionContextData.missionPlanning.data.ableToCaptureData
                      ? "Yes"
                      : "No"}
                  </Box>
                </Typography>
              </Grid>
            )}

          {tabValue === missionPlanningType.postFlight &&
            missionContextData?.missionPlanning?.data?.ableToCaptureData && (
              <Grid sx={{ mb: 2 }}>
                <Typography variant="subtitle1Med" fontSize={20}>
                  Mission Still Feasible:
                  <Box component="span" sx={{ ml: 2 }}>
                    {missionContextData.missionPlanning.data.orderStillFeasible
                      ? "Yes"
                      : "No"}
                  </Box>
                </Typography>
              </Grid>
            )}
          {tabValue === missionPlanningType.preFlight &&
            missionContextData?.missionPlanning?.data?.missionFeasible && (
              <Grid sx={{ mb: 2 }}>
                <Typography variant="subtitle1Med" fontSize={20}>
                  Has this Mission been marked feasible by Drone Operator:
                  <Box component="span" sx={{ ml: 2 }}>
                    {missionContextData?.missionPlanning?.data?.missionFeasible}
                  </Box>
                </Typography>
              </Grid>
            )}

          {tabValue === missionPlanningType.preFlight &&
            missionContextData?.missionPlanning?.data?.missionFeasible ===
              missionFeasibility.yes && (
              <Grid item sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  mb={1}
                  display="flex"
                  alignItems="center"
                >
                  Flying date:
                  <Box
                    sx={{
                      ml: 2,
                      fontSize: 18,
                    }}
                  >
                    {
                      missionContextData?.missionPlanning?.data
                        ?.currentFlightDate
                    }
                  </Box>
                </Typography>
              </Grid>
            )}

          {missionContextData?.missionPlanning?.data?.selectedReason && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}>
              <Typography variant="h5">Issue:</Typography>
              <StatusBadge
                name={missionContextData.missionPlanning.data.selectedReason}
                status={
                  currentUserRole === userRoles.client
                    ? colorStatus?.globheGreen
                    : colorStatus.blue
                }
              />
            </Box>
          )}

          {missionContextData?.missionPlanning?.data?.detailedReason && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 3 }}>
              <Typography variant="h5">Detailed Reason:</Typography>
              <Typography variant="subtitle1Med" sx={styles.greyText}>
                {missionContextData.missionPlanning.data.detailedReason}
              </Typography>
            </Box>
          )}

          {missionContextData?.missionPlanning?.data?.photoProof?.length >
            0 && (
            <Grid item container xs={12} mt={3}>
              <Grid item xs={12} mb={2}>
                <Typography variant="h5">Images Proofs :</Typography>
              </Grid>

              {missionContextData?.missionPlanning?.data?.photoProof?.map(
                (photo, i) => (
                  <UploadFileInfoContainer
                    key={i}
                    fileName={photo.fileName}
                    fileUrl={photo.fileUrl}
                    fileId={photo.id}
                    showVisibility={true}
                  />
                )
              )}
            </Grid>
          )}

          {currentUserRole === userRoles.client &&
            (missionContextData?.missionPlanning?.status ===
              missionPlanningStates.notFeasible ||
              missionContextData?.missionPlanning?.status ===
                missionPlanningStates.pendingUpload) && (
              <Box sx={{ ...styles.rightAlign, mt: 2 }}>
                <AppButton
                  label="Contact sales"
                  look="inverted"
                  onClick={(e) =>
                    missionContextData.handleTabChange(
                      e,
                      missionContextData?.mission.missionType ===
                        missionTypeConstant.clientOrder
                        ? 3
                        : 2
                    )
                  }
                  sx={{ mr: 2 }}
                />
                <AppButton
                  label="Order a new data"
                  look="green"
                  url="/app/order/create-order"
                  externalLink
                />
              </Box>
            )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLocationMap
          orderType={missionContextData?.mission?.orderType}
          locationMap={missionContextData?.mission?.locationMap}
          locationLabel={missionContextData?.mission?.locationLabel}
          areaSize={missionContextData?.mission?.areaSize}
          showOrderType={false}
        />
      </Grid>
    </Grid>
  );
};

AdminClientMissionPlanning.propTypes = {
  tabValue: PropTypes.string.isRequired,
};

export default AdminClientMissionPlanning;
