import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import QuoteInvoiceSection from "./QuoteInvoiceSection";
import { useStateIfMounted } from "use-state-if-mounted";
import {
  clientQuote,
  getDeliveryPackageByMissionId,
} from "../../../services/missions";
import firebase from "src/firebase";
import { getUser } from "../../../services/auth";
import { missionStatus, userRoles } from "../../../lib/constants";
import AdminQuoteForm from "./AdminQuoteForm";
import SingleOrderQuotesTable from "../../global/elements/SingleOrderQuotesTable";
import {
  convertMissionAreaSizeToKm,
  setQuoteModifyBy,
} from "../../../lib/helpers";
import Dialogue from "../../global/Dialogue";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { AdminDialogueBody } from "./QuotePopups";
import QuoteBoxesAndCreate from "./QuoteBoxesAndCreate";
import BasicDetailsInQuotes from "./BasicDetailsInQuotes";
import { boxShadowStyle } from "../../../lib/styleConstants";
import { useSnackbar } from "notistack";
import { allowSendQuote } from "../projects/Components/AddQuotes";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1em",
  },
  form: {
    marginTop: "1em",
  },
  subscmessage: {
    boxShadow: `0px 0px 36px ${theme.palette.grey.lightWarmGrey}E6`,
    padding: "1em",
  },
  gridItem: {
    padding: "60px 20px",
    paddingTop: 0,
  },
};
const QuoteDetail = ({
  mission = {
    areaSize: undefined,
    subscriptionId: undefined,
    orderType: undefined,
    status: undefined,
    enterPriseId: undefined,
  },
  refreshMission = undefined,
  setRefreshMission = undefined,
  setLoadingMission = undefined,
  loadingMission = undefined,
  currency = undefined,
}) => {
  const [deliveryPackage, setDeliveryPackage] = useStateIfMounted(null);
  const [showAdminDialogue, setShowAdminDialogue] = useState(false);
  const [showNoQuoteDialogue, setShowNoQuoteDialogue] = useState(false);
  const [discountBool, setDiscountBool] = useState(false);
  const [quoteInvoiceData, setQuoteInvoiceData] = useState(null);
  const [subsMission, setSubsMission] = useState(false);
  const [areaSize, setAreaSize] = useState("");
  const [totalQuotesCount, setTotalQuotesCount] = useState(null);
  const user = getUser("userProfile");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isAdmin = user.role === userRoles.admin;
  const isClient = user.role === userRoles.client;

  useEffect(() => {
    if (mission?.subscriptionId) {
      setSubsMission(true);
    }
    // let calcArea = convertMissionAreaSizeToHectares({ ...mission });  //to add only if we want to show hectares in the quote
    let calcArea = convertMissionAreaSizeToKm({ ...mission });
    setAreaSize(calcArea);
  }, [mission]);

  useEffect(() => {
    const getPackages = async () => {
      const res = await getDeliveryPackageByMissionId(mission?.id, firebase);
      setDeliveryPackage(res);
    };
    getPackages();
  }, [mission?.id]);

  const sendClientQuote = async () => {
    setIsLoading(true);
    let issuedBy = setQuoteModifyBy(user);
    const quote = {
      ...quoteInvoiceData,
      issuedBy,
    };
    const response = await clientQuote(
      firebase,
      mission,
      quote,
      totalQuotesCount
    );
    if (response.success) {
      enqueueSnackbar(response.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
    setIsLoading(false);
    refreshMission();
  };

  return (
    <Box sx={styles.innerWrapper}>
      {subsMission ? (
        <Box sx={styles.subscmessage}>
          <Typography variant="h6">
            Quotes and invoices are not available for subscription orders
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.container}>
          {isAdmin && (
            <Box sx={{ ...styles.gridItem, ...boxShadowStyle }}>
              <BasicDetailsInQuotes />
            </Box>
          )}
          <QuoteInvoiceSection
            missionDetails={mission}
            deliveryPackage={deliveryPackage}
            isAdmin={isAdmin}
            isClient={isClient}
            refreshMission={refreshMission}
          />
          <Box>
            <QuoteBoxesAndCreate
              showNoQuoteDialogue={showNoQuoteDialogue}
              mission={mission}
              areaSize={areaSize}
              currency={currency}
            />
            {isAdmin &&
              mission.status !== missionStatus.completed &&
              allowSendQuote.includes(mission?.status) && (
                <Box sx={styles.form}>
                  <Typography variant="h5">
                    Fill the following details before sending the quote to the
                    client
                  </Typography>
                  {totalQuotesCount != null && (
                    <AdminQuoteForm
                      setShowQuoteDialog={setShowAdminDialogue}
                      setDiscountBool={setDiscountBool}
                      discountBool={discountBool}
                      setQuoteInvoiceData={setQuoteInvoiceData}
                      mission={mission}
                      totalQuotesCount={totalQuotesCount}
                      currency={currency}
                    />
                  )}
                </Box>
              )}
          </Box>
          <Box>
            <SingleOrderQuotesTable
              missionData={mission}
              setShowNoQuoteDialogue={setShowNoQuoteDialogue}
              setRefreshMission={setRefreshMission}
              setLoadingMission={setLoadingMission}
              loadingMission={loadingMission}
              refreshMission={refreshMission}
              setTotalQuotesCount={setTotalQuotesCount}
            />
          </Box>
          {showAdminDialogue && (
            <Dialogue
              showDialogue={showAdminDialogue}
              setShowDialogue={setShowAdminDialogue}
              DialogueBody={AdminDialogueBody()}
              showCancelButton={true}
              cancelButtonText={"Cancel"}
              sumitButtonText={"Confirm"}
              showSubmitLoader={isLoading}
              onSubmit={() => sendClientQuote()}
              submitButtonLook={"primary"}
              hideIcon={true}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

QuoteDetail.propTypes = {
  mission: PropTypes.shape({
    id: PropTypes.string.isRequired,
    areaSize: PropTypes.number,
    subscriptionId: PropTypes.string,
    orderType: PropTypes.string,
    status: PropTypes.string,
    enterPriseId: PropTypes.string,
    clientId: PropTypes.string.isRequired,
  }),
  refreshMission: PropTypes.func,
  setRefreshMission: PropTypes.func,
  setLoadingMission: PropTypes.func,
  loadingMission: PropTypes.bool,
  currency: PropTypes.string,
};
// QuoteDetail.defaultProps = {
//   mission: PropTypes.shape({
//     areaSize: undefined,
//     subscriptionId: undefined,
//     orderType: undefined,
//     status: undefined,
//     enterPriseId: undefined,
//   }),
//   refreshMission: undefined,
//   setRefreshMission: undefined,
//   setLoadingMission: undefined,
//   loadingMission: undefined,
//   currency: undefined,
// };
export default QuoteDetail;
