import PropTypes from "prop-types";
import React, { useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  CardContent,
  Grid,
} from "@mui/material";
import { Link } from "gatsby";
import { countryListAlpha2 } from "../../../lib/constants";

import theme from "../../../theme";
const { DateTime } = require("luxon");
import defaultImage from "src/assets/images/profileImage.jpg";
import ReactCountryFlag from "react-country-flag";
import StatusBadge from "../../global/elements/StatusBadge";
import AppButton from "../../global/elements/AppButton";
import Rating from "@mui/material/Rating";

export const style = {
  rejectPilot: {
    color: theme.palette.status.errorDark,
  },
  bold_info: {
    cursor: "pointer",
  },
  label: {
    lineHeight: "normal",
    color: theme.palette.black.darkSeaBlack,
    fontSize: "15px",
  },
  table_cell: {
    minWidth: 110,
    wordWrap: "break-word",
    padding: { md: 5 },
  },
  table_head: {
    display: "none",
  },
  link_row: {
    cursor: "pointer",
  },
  single_field_div: {
    display: "flex",
    padding: "15px",
    borderBottom: `1px solid ${theme.palette.grey.lightWarmGrey}`,
  },
  single_field_heading_div: {
    width: "40%",
  },
  single_field_heading: {
    color: theme.palette.black.coolGrey,
    float: "left",
    wordWrap: "break-word",
  },
  single_field_data_div: {
    width: "55%",
  },
  cursorChange: {
    cursor: "pointer",
  },
  avtarBox: {
    display: "flex",
  },
  avtarImage: {
    width: "50px",
    height: "50px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    "& img": {
      padding: "2px",
      borderRadius: "50%",
    },
  },
};

const PilotWithRating = ({ rating, avatar, name }) => (
  <Grid container alignItems={"center"} sx={style.avtarBox}>
    <Avatar
      alt="Operator's Avatar"
      src={avatar || defaultImage}
      sx={style.avtarImage}
    />
    <Grid container width={"70%"} flexDirection={"column"} marginLeft={1}>
      <Typography variant="body1">{name || "N/A"}</Typography>
      <Rating
        name={"overallRating"}
        value={rating || 0}
        precision={0.5}
        readOnly
        sx={{ fontSize: "1rem" }}
      />
    </Grid>
  </Grid>
);

const MissionPilotsList = ({
  assignMission = null,
  source = null,
  missionData = undefined,
  columnForPilot = undefined,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const getInvitedDate = (missionData, id) => {
    let date = "";
    if (
      missionData &&
      missionData.sentToPilotsInfo &&
      missionData.sentToPilotsInfo.length
    ) {
      let pilotObj = missionData.sentToPilotsInfo.filter(
        (msn) => msn.pilotId === id
      );

      if (pilotObj && pilotObj[0] && pilotObj[0].invitedDate) {
        date = DateTime.fromISO(pilotObj[0].invitedDate).toISODate();
      }
    }
    return date;
  };

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "date": {
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value["dateCreated"]).toISODate()}
          </Typography>
        );
      }
      case "dateInvited": {
        return (
          <Typography variant="body1">
            {getInvitedDate(missionData, value?.id)}{" "}
          </Typography>
        );
      }
      case "array":
        return (
          <Typography variant="body1">
            {value["equipment"] && value["equipment"].length > 0
              ? value["equipment"].toString()
              : "n/a"}
          </Typography>
        );
      default:
        return;
    }
  };

  const getCountryFlag = (countryName) => {
    let countryCode = Object.keys(countryListAlpha2).filter(
      (key) => countryListAlpha2[key] === countryName
    );
    return (
      <ReactCountryFlag
        countryCode={countryCode[0] || "SE"}
        aria-label={countryName}
        svg
        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
        cdnSuffix="svg"
        style={{
          position: "relative",
          top: "2px",
          fontSize: "22px",
          borderRadius: "100%",
        }}
        title={countryName}
      />
    );
  };

  const onApproveHandler = async (row) => {
    setIsLoading(true);
    await assignMission(row);
    setIsLoading(false);
  };
  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        margin: "auto",
        boxShadow: { xs: "none", sm: "0px 0px 4px rgba(0, 0, 0, 0.25)" },
        padding: { xs: 0, sm: "2%" },
        border: "none",
      }}
    >
      <TableContainer
        sx={{
          maxHeight: 620,
          display: { xs: "none", sm: "block", md: "block" },
          borderColor: theme.palette.primary.pistachio,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead id={style.table_head}>
            <TableRow>
              {columnForPilot?.map((column, i) => (
                <TableCell
                  key={`first-${i}` || column.id}
                  align={column.align}
                  style={
                    column.sort
                      ? { ...style.cursorChange, ...style.table_cell }
                      : style.table_cell
                  }
                >
                  <Box
                    sx={style.label}
                    // onClick={() => {
                    //   if (column.sort) {
                    //     sortByHandler(column.id);
                    //   }
                    // }}
                  >
                    {column.label}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {missionData.length > 0 &&
              missionData &&
              missionData?.map((row, index) => {
                return (
                  <TableRow
                    key={`second-${index}`}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{
                      "& > *": {
                        height: 120,
                        paddingTop: 3,
                        paddingBottom: 3,
                      },
                    }}
                    style={style.link_row}
                  >
                    {/*Interested pilots*/}
                    {columnForPilot?.map((column) => {
                      const value = row[column?.id] || "n/a";
                      return (
                        <TableCell
                          sx={{ overflowWrap: "anywhere" }}
                          key={`third-${column.id}`}
                          align={column.align}
                        >
                          {column.field === "date" ? (
                            valueConvertHandler("date", row)
                          ) : column.field === "capital" ? (
                            <Typography style={style.bold_info} variant="body1">
                              {value}
                            </Typography>
                          ) : column.field === "status" ? (
                            <Box>
                              {
                                <StatusBadge
                                  name={"Interested" || "N/A"}
                                  status="green"
                                />
                              }
                            </Box>
                          ) : column.field === "flag" ? (
                            <Box sx={style.avtarBox}>
                              {getCountryFlag(value)}
                              <Typography variant="body1" marginLeft={1}>
                                {value || "N/A"}
                              </Typography>
                            </Box>
                          ) : column.field === "avtar" ? (
                            <Link
                              to={
                                row?.pilotId
                                  ? `/app/pilots/${row.pilotId}`
                                  : row.id && `/app/pilots/${row.id}`
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <Box sx={style.avtarBox}>
                                <Avatar
                                  alt="Operator's avatar"
                                  src={row?.profileImageURL || defaultImage}
                                  sx={style.avtarImage}
                                />{" "}
                                <Typography
                                  variant="body1"
                                  marginTop={1}
                                  marginLeft={1}
                                >
                                  {value || "N/A"}
                                </Typography>
                              </Box>
                            </Link>
                          ) : column.field === "pilotWithRating" ? (
                            <Link
                              to={
                                row?.pilotId
                                  ? `/app/pilots/${row.pilotId}`
                                  : row.id && `/app/pilots/${row.id}`
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <PilotWithRating
                                name={value}
                                rating={row?.ratings}
                                avatar={row?.profileImageURL}
                              />
                            </Link>
                          ) : column.field === "approve" ? (
                            <AppButton
                              look="blue"
                              noIcon
                              small
                              label="Approve"
                              onClick={() => onApproveHandler(row)}
                              submittingState={isLoading}
                            />
                          ) : column.field === "price" ? (
                            <Typography variant="body1">${value}</Typography>
                          ) : column.field === "normal" ? (
                            <Typography variant="body1">
                              {value || "N/A"}
                            </Typography>
                          ) : column.field === "dateInvited" ? (
                            valueConvertHandler("dateInvited", row)
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: { sm: "none", md: "none" },
          padding: "5px",
        }}
      >
        {missionData.length > 0 &&
          missionData &&
          missionData?.map((row, i) => {
            return (
              <Box
                sx={{
                  margin: "15px 0px",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                }}
                key={`sixth-${i}`}
              >
                <CardContent>
                  {columnForPilot?.map((column, key) => {
                    const value = row[column.id] || "n/a";
                    return (
                      <Box sx={style.single_field_div} key={`seventh-${key}`}>
                        <Box sx={style.single_field_heading_div}>
                          <Typography
                            variant="h5"
                            style={style.single_field_heading}
                          >
                            {column.label}
                          </Typography>
                        </Box>
                        <Box sx={style.single_field_data_div}>
                          <Box style={style.single_field_data}>
                            {column.field === "date" ? (
                              valueConvertHandler("date", row)
                            ) : column.field === "capital" ? (
                              <Typography style={style.bold_info} variant="h5">
                                {value || "N/A"}
                              </Typography>
                            ) : column.field === "status" ? (
                              <Box>
                                {
                                  <StatusBadge
                                    name={"Interested"}
                                    status="green"
                                  />
                                }
                              </Box>
                            ) : column.field === "flag" ? (
                              <Box sx={style.avtarBox}>
                                {getCountryFlag(value)}
                                <Typography variant="h5" marginLeft={1}>
                                  {value || "N/A"}
                                </Typography>
                              </Box>
                            ) : column.field === "avtar" ? (
                              <Box sx={style.avtarBox}>
                                <Avatar
                                  alt={row?.pilot?.pilotName}
                                  src={
                                    row?.pilot?.profileImageURL || defaultImage
                                  }
                                  sx={style.avtarImage}
                                />{" "}
                                <Typography
                                  variant="h5"
                                  marginTop={1}
                                  marginLeft={1}
                                >
                                  {value || "N/A"}
                                </Typography>
                              </Box>
                            ) : column.field === "approve" ? (
                              <AppButton
                                look="blue"
                                noIcon
                                small
                                label="Approve"
                                onClick={() => assignMission(row)}
                              />
                            ) : column.field === "price" ? (
                              <Typography variant="h5">${value}</Typography>
                            ) : column.field === "normal" ? (
                              <Typography variant="h5">
                                {value || "N/A"}
                              </Typography>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </CardContent>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

MissionPilotsList.propTypes = {
  missionData: PropTypes.object.isRequired,
  assignMission: PropTypes.func,
  columnForPilot: PropTypes.array.isRequired,
  source: PropTypes.string,
  mission: PropTypes.object,
};

// MissionPilotsList.defaultProps = {
//   unassignPilotHandler: null,
//   uninvitePilotHandler: null,
//   assignMission: null,
//   submitMissionHandler: null,
//   onEditingFinished: null,
//   source: null,
//   mission: null,
// };

export default MissionPilotsList;
