import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import {
  getCurrentDateTimeMillis,
  setQuoteModifyBy,
} from "../../../../lib/helpers";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import AdminQuoteForm from "../../quotes/AdminQuoteForm";
import { AdminDialogueBody } from "../../quotes/QuotePopups";
import { getUser } from "../../../../services/auth";
import { createQuote, updateProject } from "../../../../services/project";
import { missionStatus, projectStatus } from "../../../../lib/constants";
import { getQuotesList } from "../../../../services/project";
import firebase from "src/firebase";
import { clientProjectQuoteEmail } from "../../../../services/clientEmail";
import { createNotification } from "../../../../services/notification";

const styles = {
  form: {
    marginTop: "1em",
  },
};

export const allowSendQuote = [
  missionStatus.pilotSelected,
  missionStatus.quoteSentToClient,
  missionStatus.quoteDeclinedByClient,
  missionStatus.quoteReissuedToClient,
];

const AddQuote = ({
  projectId = undefined,
  project = undefined,
  currency = undefined,
  setReloadTable = false,
  setDisableUploadButton = false,
  role = undefined,
  reloadTable = undefined,
  setQuotes = undefined,
  reloadProject = undefined,
}) => {
  const [discountBool, setDiscountBool] = useState(false);
  const [showAdminDialogue, setShowAdminDialogue] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [quoteInvoiceData, setQuoteInvoiceData] = useState(null);
  const [totalQuotesCount, setTotalQuoteCount] = useState(null);
  const user = getUser("userProfile");

  const fetchQuotes = useCallback(async () => {
    try {
      const data = await getQuotesList(firebase, projectId, role);
      if (data?.quotes) {
        setQuotes(data.quotes);
        setTotalQuoteCount(data?.quotes?.length);
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, [projectId, role, setQuotes]);

  useEffect(() => {
    if (
      project?.status === projectStatus.initial ||
      project?.status === projectStatus.draft
    ) {
      setDisableUploadButton(true);
    }
    fetchQuotes();
  }, [fetchQuotes, project?.status, setDisableUploadButton]);

  const sendClientProjectQuote = async () => {
    try {
      let issuedBy = setQuoteModifyBy(user);
      const quote = {
        ...quoteInvoiceData,
        issuedBy,
      };

      return new Promise((resolve, reject) => {
        createQuote({
          projectId,
          quote,
        })
          .then((data) => {
            if (data.status == "Success") {
              resolve({
                status: "Success",
                message: "Quote Added successfully",
              });
              if (totalQuotesCount === 0) {
                const updateForProject = {
                  quoteCreatedForClientOn: getCurrentDateTimeMillis(),
                };
                updateProject(projectId, updateForProject);
              }

              setReloadTable(!reloadTable);
              reloadProject();
              fetchQuotes();
              setShowAdminDialogue(false);
              // Send quote email to client & create notification for client
              if (project.clientId) {
                clientProjectQuoteEmail({ project, quote });
                createNotification(
                  firebase,
                  project.enterPriseId || project.clientId,
                  "A quote for your order is now available.",
                  "A quote for your order is now available.",
                  `/app/client/projects/${projectId}`
                );
              }
            } else {
              reject({
                status: "Error",
                message: data?.message || "Error in adding quote",
              });
            }
          })
          .catch((e) => {
            reject({
              status: "Error",
              message: e?.message || "Error in adding Quote to the mission",
            });
          });
        reloadProject();
      });
    } catch (e) {
      console.log("Error", e);
      throw { status: "Error", message: e?.message || "Error in adding quote" };
    }
  };

  return (
    <Box>
      <Box sx={styles.form}>
        <Typography variant="h5">
          Fill the following details before sending the quote to the client
        </Typography>
        {totalQuotesCount != null && (
          <AdminQuoteForm
            setShowQuoteDialog={setShowAdminDialogue}
            setDiscountBool={setDiscountBool}
            discountBool={discountBool}
            setQuoteInvoiceData={setQuoteInvoiceData}
            mission={project}
            totalQuotesCount={totalQuotesCount}
            isProject
          />
        )}
      </Box>
      <Box>
        {showAdminDialogue && (
          <Dialogue
            showDialogue={showAdminDialogue}
            setShowDialogue={setShowAdminDialogue}
            DialogueHeadText={""}
            DialogueBody={AdminDialogueBody()}
            showCancelButton={true}
            cancelButtonText={"Cancel"}
            sumitButtonText={"Yes"}
            showSubmitLoader={showSubmitLoader}
            setShowSubmitLoader={setShowSubmitLoader}
            onSubmit={() => sendClientProjectQuote()}
            submitButtonLook={"primary"}
          />
        )}
      </Box>
    </Box>
  );
};

AddQuote.propTypes = {
  projectId: PropTypes.string,
  addQuote: PropTypes.func.isRequired,
  submitBtnFlag: PropTypes.bool.isRequired,
  project: PropTypes.object,
  currency: PropTypes.string,
  reloadTable: PropTypes.bool.isRequired,
  setReloadTable: PropTypes.bool,
  setDisableUploadButton: PropTypes.bool,
  role: PropTypes.string,
  setQuotes: PropTypes.func.isRequired,
};

// AddQuote.defaultProps = {
//   projectId: undefined,
//   project: undefined,
//   currency: undefined,
//   setReloadTable: false,
//   setDisableUploadButton: false,
//   role: undefined,
// };
export default AddQuote;
