import { Box, AppBar, Grid } from "@mui/material";
import firebase from "src/firebase";
import pt from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useGetMission } from "../../../../hooks/useGetMission";
import { useGetPilotPackages } from "../../../../hooks/useGetPilotPackages";
import {
  missionStatus,
  PilotPackageStatus,
  userRoles,
  interestInviteStatus,
  pilotProgressStatus,
  orderLogStatus,
  missionTypeConstant,
  contractWaiverLabels,
  packageStatusConstants,
  activeMissionStatuses,
} from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";
import NoMissionsMessage from "../shared/NoMissionsMessage";
import PilotSingleMissionStatus from "../PilotSingleMission/PilotSingleMissionStatus";
import {
  addPilotPackage,
  submitPilotPackage,
  updatePilotPackage,
} from "../../../../services/pilot-packages";
import { BlockOutlined } from "@mui/icons-material";
import { addPilotFile } from "../../../../services/pilot-files";
import theme from "../../../../theme";
import {
  getContractUsingMissionId,
  getPilotContractForProject,
} from "../../../../services/contracts";
import AppButton from "../../../global/elements/AppButton";
import { getCurrentDateTime } from "../../../../lib/helpers";
import { getInterestedPilots } from "../../../../services/interest-invite";
import { createNotification } from "../../../../services/notification";
import Layout from "../../../../Layout";
import { generateKMLData } from "../../../../lib/helpers";
import { createOrderStatus } from "../../../../services/orderStatus";
import { StyledTabs, StyledTab } from "../../../../lib/styleConstants";
import TabPanel from "../../../global/elements/TabPanel";
import PilotPackagesTab from "../FilesPackages/PilotPackagesTab";
import PilotInvoiceTab from "../PilotSingleMission/PilotInvoiceTab";
import OrderTopNavComponent from "./components/OrderTopNavComponent";
import MissionContext from "./components/MissionContext";
import OrderDetailsComponent from "./components/OrderDetailsComponent";
import InterestDialog from "./components/InterestDialog";
import DeclineByPilot from "./components/DeclineByPilot";
import UndoPilotRejection from "./components/UndoPilotRejection";
import SiteStatus from "../PilotSingleMission/SiteStatus";
import MissionPlanning from "../MissionPlanning";
import {
  checkAndUpdateMissionStatus,
  getMissionPlanningStatus,
} from "../../../../services/missions";
import TwoWayCommunicationPilot from "../../../global/TwoWayCommunication/TwoWayCommunicationPilot";
import PilotNextActions from "./components/PilotNextActions";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  "@global": {
    body: {
      background: "#f5f5f5",
    },
  },
  root: {
    background: theme.palette.grey.white,
    "& .next-mission-step": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .next-mission-step .remove-pilot-btn": {
      marginTop: "2rem",
    },
    "& .column-content": {
      padding: "2rem",
      background: theme.palette.grey.white,
      border: `1px solid ${theme.palette.grey.lightWarmGrey}`,
      marginTop: "2rem",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& .mission-info": {
      position: "relative",
      alignContent: "flex-start",
    },
    "& .mission-info .edit-button": {
      position: "absolute",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100%",
    },
    "& .mission-info.admin": { paddingBottom: "100px !important" },
    "& h2": { textAlign: "center" },

    "& .single-mission": {
      paddingBottom: "2rem",
      minHeight: "calc(100vh - 170px)",
    },
    "& .pick-pilot": { padding: "4rem 0" },
  },
  inquiry: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    textAlign: "left",
    "& p": {
      marginBottom: "1em",
      color: theme.palette.grey[600],
    },
    marginBottom: "40px",
  },
  marginBottom: {
    marginBottom: "40px",
  },
  mainContent: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "auto",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minHeight: { md: "calc(100vh - 120px)" },
    backgroundColor: theme.palette.grey.white,
  },
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
  },
  packageTabLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  map: {
    width: { xs: " 100%", md: "100%" },
    height: "100%",
  },
  tabShadowBox: {
    width: "100% !important",
    background: theme.palette.grey.white,
  },
};

const PageModes = Object.freeze({
  DEFAULT: 0,
  INVITE_PILOTS: 1,
  ASSIGN_PILOTS: 2,
  EDIT_MISSION: 3,
});

const editableMissionStatus = [
  missionStatus.dataProcessing,
  missionStatus.pilotPackageRejected,
  missionStatus.sentForReviewToClient,
  missionStatus.sentForReviewToGlobhe,
];
const editablePackageStatus = [
  packageStatusConstants.approved,
  packageStatusConstants.underReview,
  packageStatusConstants.requestedChanges,
];

const PilotSingleMissionPage = ({ missionID }) => {
  const {
    mission,
    loading: loadingMission,
    refreshMission,
  } = useGetMission(missionID);

  const {
    packages: pilotPackages,
    loading: loadingPilotPackages,
    refreshPackages,
  } = useGetPilotPackages(missionID);

  const currentUser = getUser("userProfile");
  const [tabValue, setTabValue] = useState(0);
  const [contract, setContract] = useState(null);
  const [declined, setDeclined] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [interestExists, setInterestExists] = useState(false);
  const [addedInterst, setInterst] = useState(null);
  const [otherPilotInterests, setOtherPilotInterests] = useState([]);
  const [pageMode, setPageMode] = useState(PageModes.DEFAULT);
  const [kmlData, setkmlData] = useState(null);
  const [redirectURL, setRedirectURL] = useState(null);
  const [contractRequired, setContractRequired] = useState(false);
  const [showInvoiceTab, setShowInvoiceTab] = useState(false);
  const [missionPlanning, setMissionPlanning] = useState(null);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };
  useEffect(() => {
    if (mission) {
      if (mission.locationMap) {
        const data = generateKMLData(mission);
        if (data) {
          setkmlData(data);
        }
      }

      if (
        mission.status === missionStatus.completed &&
        (mission.missionType === missionTypeConstant.clientOrder ||
          mission.allowInvoice)
      ) {
        setShowInvoiceTab(true);
      }

      checkAndUpdateMissionStatus(mission);
    }
  }, [mission]);

  useEffect(() => {
    if (location && location.state && location.state.REDIRECT_URL) {
      setRedirectURL(location.state.REDIRECT_URL);
    }
  }, []);

  const fetchInterest = useCallback(() => {
    getInterestedPilots({
      firebase,
      missionId: missionID,
      status: interestInviteStatus.pilotRequested,
    })
      .then((data) => {
        if (data && data.interests && data.interests.length > 0) {
          // check current piot interest
          const currentPilotInterest = data.interests.find(
            (interest) => interest.pilotId == currentUser.id
          );
          if (currentPilotInterest) {
            setInterestExists(true);
            setInterst({
              price: currentPilotInterest.price,
              id: currentPilotInterest.id,
            });
          } else {
            setInterestExists(false);
            setInterst(null);
          }
          const otherPilots = data.interests.filter(
            (interest) => interest.pilotId != currentUser.id
          );
          if (otherPilots && otherPilots.length > 0) {
            setOtherPilotInterests(otherPilots);
          } else {
            setOtherPilotInterests([]);
          }
        } else {
          setOtherPilotInterests([]);
          setInterestExists(false);
          setInterst(null);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }, [missionID, currentUser.id]);

  useEffect(() => {
    fetchInterest();
  }, [fetchInterest]);
  //It will not fetch contract details for pilot who has missed the mision, it would be unecessary call for the missed pilot
  const fetchContract = useCallback(async () => {
    const checkContractVisibility = (contract) => {
      if (
        (mission?.missionType === missionTypeConstant.clientOrder ||
          mission?.missionType === missionTypeConstant.projectMission) &&
        !(contract?.iUnderstandAll || currentUser.role === userRoles.admin) &&
        !(
          mission?.contractAcceptedViaAdmin === contractWaiverLabels.accepted
        ) &&
        !(mission?.status === missionStatus.completed)
      ) {
        setContractRequired(true);
      }
    };

    try {
      if (mission.missionType === missionTypeConstant.clientOrder) {
        const contractDetails = await getContractUsingMissionId(missionID);
        setContract(contractDetails?.contract[0]);
        checkContractVisibility(contractDetails?.contract[0]);
      } else if (mission.missionType === missionTypeConstant.projectMission) {
        const contractDetails = await getPilotContractForProject({
          projectId: mission.projectIds,
          pilotId: mission.assignedPilot,
        });
        setContract(contractDetails?.contract[0]);
        checkContractVisibility(contractDetails?.contract[0]);
      }
    } catch (e) {
      console.log("Error", e);
    }
  }, [mission, missionID, setContract, setContractRequired, currentUser.role]);

  useEffect(() => {
    if (
      (mission?.status === missionStatus.quoteAcceptedByClient ||
        mission?.status === missionStatus.active) &&
      currentUser?.id == mission?.assignedPilot
    ) {
      fetchContract();
    }
  }, [currentUser?.id, fetchContract, mission]);
  const updatePilotPackageHandle = useCallback(async () => {
    if (pilotPackages && pilotPackages[0]?.isDeleted) {
      const currentTime = getCurrentDateTime();
      const updatePilot = {
        pilot: currentUser?.id,
        dateUpdated: currentTime,
        isDeleted: false,
      };
      await updatePilotPackage(
        firebase,
        missionID,
        pilotPackages[0]?.id,
        updatePilot
      );
      setTabValue(pilotPackages?.length ?? 0);
      return refreshPackages();
    }
  }, [pilotPackages, currentUser?.id, missionID, setTabValue, refreshPackages]);

  useEffect(() => {
    const createPackage = async () => {
      await addPilotPackage(firebase, missionID);
      refreshPackages();
    };
    const canCreatePackage =
      pilotPackages &&
      !pilotPackages[0]?.isDeleted &&
      (mission?.status === missionStatus.active ||
        mission?.status === missionStatus.dataProcessing ||
        mission?.status === missionStatus.pilotPackageRejected) &&
      (pilotPackages.length === 0 ||
        pilotPackages[pilotPackages.length - 1].status ===
          PilotPackageStatus.rejected ||
        pilotPackages[pilotPackages.length - 1].packageStatusByAdmin ===
          PilotPackageStatus.rejected);
    if (canCreatePackage) {
      createPackage();
      // setTabValue(pilotPackages?.length ?? 0); // Uncomment if needed
    } else if (
      mission?.sentToPilots?.length !== 0 &&
      pilotPackages &&
      !pilotPackages[0]?.isDeleted
    ) {
      // setTabValue(pilotPackages?.length ?? 0); // Uncomment if needed
    } else if (mission?.assignedPilot) {
      updatePilotPackageHandle();
    } else {
      setTabValue(0);
    }
  }, [
    currentUser?.id,
    currentUser.role,
    mission?.assignedPilot,
    mission?.sentToPilots?.length,
    mission?.status,
    missionID,
    pilotPackages,
    refreshPackages,
    updatePilotPackageHandle,
  ]);
  useEffect(() => {
    if (
      mission &&
      mission?.assignedPilot &&
      (mission?.missionType === missionTypeConstant.clientOrder ||
        mission?.missionType === missionTypeConstant.projectMission)
    ) {
      fetchMissionPlanning(mission.id);
    }
  }, [mission]);

  useEffect(() => {
    if (window.location.hash === "#communications") {
      setTabValue(showInvoiceTab ? 2 : 1);
    } else {
      setTabValue(0);
    }
  }, [showInvoiceTab, pageMode]);

  const fetchMissionPlanning = async (id) => {
    const data = await getMissionPlanningStatus(id);
    setMissionPlanning(data);
  };

  const handlePilotDeclined = () => {
    setDeclined(true);
  };

  const handleAddToLatestSubmission = async (selectedPilotFiles) => {
    for (const pilotFile of selectedPilotFiles) {
      await addPilotFile({
        firebase,
        missionID,
        packageID: pilotPackages[pilotPackages.length - 1].id,
        fileName: pilotFile.fileName,
        fileType: pilotFile.fileType,
        fileSize: pilotFile.fileSize ?? null,
        url: pilotFile.url,
        previewUrl: pilotFile.previewUrl,
        thumbnailUrl: pilotFile.thumbnailUrl,
        fileDataType: pilotFile.fileDataType,
        largeFile: pilotFile.largeFile,
      });
    }
  };

  const openShowInterest = () => {
    setShowInterest(true);
  };

  const MissionNotAvailable = () => (
    <NoMissionsMessage
      title={`The mission with id ${missionID} is not available`}
      description="It might have been removed or assigned to another drone operator."
      actionButton={
        <AppButton
          label="Back to mission list"
          look="black"
          url="/app/missions"
          internalLink
        />
      }
    />
  );

  const handlePackageSubmit = async (packageID) => {
    await submitPilotPackage(firebase, missionID, packageID);
    let obj = {
      missionId: missionID,
      status: orderLogStatus.pilotPackageAdded,
    };
    createOrderStatus(firebase, obj)
      .then(() => {})
      .catch((e) => {
        console.log("Error", e);
      });
    if (
      mission.clientId &&
      mission.missionType == missionTypeConstant.clientOrder
    ) {
      await createNotification(
        firebase,
        mission.clientId,
        "Your data for this mission has been submitted and is currently being validated.",
        "Your data for this mission has been submitted and is currently being validated.",
        `/app/client/orderDetails/${mission.id}`
      );
    }
    refreshPackages();
    refreshMission();
  };

  const onCloseInterestDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowInterest(false);
  };

  const MissionInquiry = () => {
    if (mission.declinedByPilots.includes(currentUser?.id)) {
      return <UndoPilotRejection />;
    }
    //remove the siteStatus once we deploy the new features to assess mission
    return (
      <Box sx={styles.inquiry}>
        {showInterest && <InterestDialog />}
        {mission?.projectIds &&
          mission?.allowEmptyData &&
          mission.status !== missionStatus.completed && (
            <SiteStatus
              projectId={mission?.projectIds}
              mission={mission}
              contract={contract}
              refreshMission={refreshMission}
            />
          )}
        <OrderDetailsComponent />
      </Box>
    );
  };
  const getTabIndex = (showInvoiceTab, mission, index) => {
    const isProjectOrClientOrder =
      mission?.missionType === missionTypeConstant.projectMission ||
      mission?.missionType === missionTypeConstant.clientOrder;

    if (
      showInvoiceTab &&
      isProjectOrClientOrder &&
      mission?.status !== missionStatus.completed
    ) {
      return index + 4;
    } else if (showInvoiceTab) {
      return index + 3;
    } else if (isProjectOrClientOrder) {
      return index + 3;
    } else {
      return index + 2;
    }
  };
  const MissionContent = () => (
    <Box>
      <Box>
        <AppBar position="static" sx={styles.appBar} elevation={0}>
          <StyledTabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <StyledTab
              key="missionDetails"
              label="Mission Details"
              {...a11yProps(0)}
            />
            {showInvoiceTab && (
              <StyledTab key="invoices" label="invoices" {...a11yProps(1)} />
            )}
            {!(
              new Date(mission.biddingEnded) < new Date() &&
              mission?.assignedPilot !== currentUser.id
            ) && (
              <StyledTab
                key="communication"
                label="Communication"
                {...a11yProps(showInvoiceTab ? 2 : 1)}
              />
            )}
            {(mission?.missionType === missionTypeConstant.projectMission ||
              mission?.missionType === missionTypeConstant.clientOrder) &&
              activeMissionStatuses.includes(mission?.status) &&
              mission?.assignedPilot === currentUser?.id && (
                <StyledTab
                  key="missionPlanning"
                  label="Mission planning"
                  {...a11yProps(showInvoiceTab ? 3 : 2)}
                />
              )}
            {(currentUser?.id == mission?.assignedPilot ||
              currentUser?.role == userRoles.admin ||
              mission?.status === missionStatus.unassigned ||
              mission?.status === missionStatus.dataProcessing) &&
              pilotPackages?.map(
                (pilotPackage, index) =>
                  !pilotPackage.isDeleted && (
                    <StyledTab
                      key={pilotPackage.id}
                      label={
                        <Box sx={styles.packageTabLabel}>
                          {(pilotPackage.status ===
                            PilotPackageStatus.rejected ||
                            pilotPackage.packageStatusByAdmin ===
                              packageStatusConstants.rejected) && (
                            <BlockOutlined
                              fontSize="small"
                              styles={{ marginRight: 8 }}
                            />
                          )}
                          {pilotPackages.length > 1
                            ? `Submission ${index + 1}`
                            : "Submission"}
                        </Box>
                      }
                      {...a11yProps(
                        showInvoiceTab &&
                          (mission?.missionType ===
                            missionTypeConstant.projectMission ||
                            mission?.missionType ===
                              missionTypeConstant.clientOrder)
                          ? index + 4
                          : showInvoiceTab
                            ? index + 3
                            : !showInvoiceTab &&
                                (mission?.missionType ===
                                  missionTypeConstant.projectMission ||
                                  mission?.missionType ===
                                    missionTypeConstant.clientOrder)
                              ? index + 3
                              : index + 2
                      )}
                    />
                  )
              )}
          </StyledTabs>
        </AppBar>
      </Box>

      <Box sx={styles.tabShadowBox}>
        <TabPanel value={tabValue} index={0} key="missionDetails">
          <MissionInquiry />
        </TabPanel>
        {showInvoiceTab && (
          <TabPanel value={tabValue} index={1} key="invoices">
            <Box>
              <PilotInvoiceTab
                missionData={mission}
                currentUser={currentUser}
                refreshMission={refreshMission}
              />
            </Box>
          </TabPanel>
        )}
        {!(
          new Date(mission.biddingEnded) < new Date() &&
          mission?.assignedPilot !== currentUser.id
        ) && (
          <TabPanel
            value={tabValue}
            index={showInvoiceTab ? 2 : 1}
            key="communication"
          >
            <TwoWayCommunicationPilot />
          </TabPanel>
        )}
        {(mission?.missionType === missionTypeConstant.projectMission ||
          mission?.missionType === missionTypeConstant.clientOrder) &&
          activeMissionStatuses.includes(mission?.status) && (
            <TabPanel
              value={tabValue}
              index={showInvoiceTab ? 3 : 2}
              key="missionPlanning"
            >
              <MissionPlanning />
            </TabPanel>
          )}

        {pilotPackages
          ?.filter((pilotPackage) => !pilotPackage.isDeleted)
          ?.map((pilotPackage, index) => {
            const allowPilot =
              editableMissionStatus.includes(mission.status) &&
              editablePackageStatus.includes(
                pilotPackage.packageStatusByAdmin
              ) &&
              editablePackageStatus.includes(
                pilotPackage.packageStatusByClient
              );

            return (
              <TabPanel
                value={tabValue}
                index={getTabIndex(showInvoiceTab, mission, index)}
                key={pilotPackage.id}
              >
                <Box>
                  <PilotPackagesTab
                    packageId={pilotPackage.id}
                    mission={mission}
                    contractRequired={contractRequired}
                    contract={contract}
                    isEditable={allowPilot}
                    onPackageSubmit={async () =>
                      await handlePackageSubmit(pilotPackage.id)
                    }
                    onAddToLatestSubmission={async (selectedPilotFiles) =>
                      await handleAddToLatestSubmission(selectedPilotFiles)
                    }
                    mediaPackage={pilotPackage}
                  />
                </Box>
              </TabPanel>
            );
          })}
      </Box>
    </Box>
  );

  return (
    <>
      <Layout
        loading={loadingMission || loadingPilotPackages}
        className={styles.root}
      >
        <MissionContext.Provider
          value={{
            mission,
            redirectURL,
            pilotProgressStatus,
            currentUser,
            handlePilotDeclined,
            interestExists,
            openShowInterest,
            kmlData,
            contract,
            PageModes,
            showInterest,
            setShowInterest,
            onCloseInterestDialog,
            addedInterst,
            otherPilotInterests,
            fetchInterest,
            refreshMission,
            declined,
            setDeclined,
            setInterst,
            handleTabChange,
            missionPlanning,
            contractRequired,
          }}
        >
          <Box>
            {!loadingMission &&
              !loadingPilotPackages &&
              (mission ? (
                <>
                  <Box>
                    {(pageMode === PageModes.DEFAULT ||
                      pageMode === PageModes.EDIT_MISSION) && (
                      <>
                        <OrderTopNavComponent />
                      </>
                    )}
                  </Box>
                  <Grid container>
                    {(currentUser?.id == mission?.assignedPilot ||
                      currentUser.role == userRoles.admin ||
                      mission?.status === missionStatus.unassigned) && (
                      <Grid item xs={12} mt={3}>
                        <PilotSingleMissionStatus mission={mission} />
                      </Grid>
                    )}
                    <Grid item xs={12} mt={3}>
                      <PilotNextActions
                        packageLength={pilotPackages?.length}
                        pilotPackages={pilotPackages}
                      />
                    </Grid>

                    {/* ----------decline by pilot form---------- */}

                    <Grid item xs={12} mt={3} mb={3}>
                      {declined && <DeclineByPilot />}
                    </Grid>
                  </Grid>

                  <Box sx={styles.mainContent}>
                    <MissionContent />
                  </Box>
                </>
              ) : (
                <MissionNotAvailable />
              ))}
          </Box>
        </MissionContext.Provider>
      </Layout>
    </>
  );
};

PilotSingleMissionPage.propTypes = {
  missionID: pt.string.isRequired,
};

export default PilotSingleMissionPage;
