import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Dialogue from "../../../global/Dialogue";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AppButton from "../../../global/elements/AppButton";
import { siteNotFeasibleReason } from "../../../../lib/constants";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import SwitchSelect from "../../../global/elements/SwitchSelect";
const styles = {
  popupCls: {
    marginBottom: "30px",
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "start",
  },
  // rightAlign: {
  //   textAlign: "end",
  // },
};
const ChangeFeasibiltyPopup = ({
  orderFeasibilityPopup,
  setOrderFeasibilityPopup,
  onSubmit,
  data,
  loading,
}) => {
  const DialogueBody = () => {
    const formInitialValues = {
      notFeasible: data?.notFeasible || false,
      notFeasibleReason: data?.notFeasibleReason || "",
    };

    const validationSchemaData = Yup.object().shape({
      notFeasible: Yup.bool().required("Please select an option"),
      notFeasibleReason: Yup.string()
        .nullable()
        .when("notFeasible", {
          is: true,
          then: Yup.string().required("Please select a reason."),
        }),
    });

    return (
      <Box sx={styles.popupCls}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchemaData}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form id="quoteRejectForm">
                <Grid container>
                  <Grid item xs={12} mb={3}>
                    <Typography variant="h5">Order Feasibility</Typography>
                  </Grid>
                  <Grid item xs={12} mb={3} sx={styles.leftAlign}>
                    <Typography variant="h6">
                      {"Is this order feasible?"}
                    </Typography>

                    <SwitchSelect
                      checked={!values.notFeasible}
                      handleChange={(e) => {
                        setFieldValue("notFeasible", !e.target.checked);
                      }}
                      name="notFeasible"
                    />
                  </Grid>
                  {values.notFeasible && (
                    <Grid item xs={12} mb={3} sx={styles.leftAlign}>
                      <Typography variant="h6">
                        {
                          "Please select a reason why this order is not feasible."
                        }
                      </Typography>
                      <AutoCompleteInput
                        // setFieldValue={setFieldValue}
                        options={siteNotFeasibleReason}
                        // label={"Select the issue"}
                        name={"notFeasibleReason"}
                        // type="text"
                        // autoComplete={true}
                        // autoCompleteArray={siteNotFeasibleReason}
                        required={true}
                        multiple={false}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sx={styles.leftAlign}>
                    <AppButton
                      label={"Cancel"}
                      look={"negative"}
                      onClick={() => setOrderFeasibilityPopup(false)}
                    />
                  </Grid>
                  <Grid item xs={6} sx={styles.rightAlign}>
                    <AppButton
                      label={"Submit"}
                      type={"submit"}
                      form={"quoteRejectForm"}
                      submittingState={loading}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    );
  };
  return (
    <Box>
      <Dialogue
        showDialogue={orderFeasibilityPopup}
        // setShowDialogue={setOrderFeasibilityPopup}
        DialogueHeadText={""}
        DialogueBody={DialogueBody()}
        // showCancelButton={true}
        // cancelButtonText={"Cancel"}
        // sumitButtonText={"Submit"}
        // showSubmitLoader={loading}
        // onSubmit={() => onSubmit()}
        // submitButtonLook={"primary"}
        tableType={true}
      />
    </Box>
  );
};

ChangeFeasibiltyPopup.propTypes = {
  orderFeasibilityPopup: PropTypes.bool.isRequired,
  setOrderFeasibilityPopup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ChangeFeasibiltyPopup;
