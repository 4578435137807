import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import MapTypes from "./MapTypes";
import { missionMapTypes, sensorsType } from "../../../../lib/constants";
import { orderBox } from "../../../../lib/styleConstants";
import MapTypeInfo from "./MapTypeInfo";
import theme from "../../../../theme";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import SwitchSelect from "../../../global/elements/SwitchSelect";

const styles = {
  mapTypesCls: {
    padding: "26px",
  },
  dividerCls: {
    width: "1px",
    height: "100%",
    backgroundColor: theme.palette.grey.dividersColor,
  },
  dividerContainer: {
    display: { xs: "none", md: "flex" },
    height: "100%",
  },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
};

const ChooseProducts = ({
  mapTypes = [],
  touched = {},
  setFieldValue = undefined,
  values = {},
  errors = {},
  hideJpg = false,
  colMdSpacing = 2,
  colXsSpacing = 12,
  numberCount = null,
  setMapTypesFilter = undefined,
  triggerReload = false,
  setTriggerReload = undefined,
  additionalStyle = {},
  showOnDataLibrary = undefined,
}) => {
  const [clickedMap, setClickedMap] = useState("");
  const [jpgFlag, setJpgFlag] = useState(false);
  let selectedItems = [];

  const addMapTypes = () => {
    selectedItems = [...mapTypes, clickedMap];
    setFieldValue("mapTypes", selectedItems);
    if (setMapTypesFilter) {
      setMapTypesFilter(selectedItems);
      setTriggerReload(!triggerReload);
    }
    setClickedMap(null);
  };

  const mapTypesToShow = () => {
    const allSensors = values.droneSensors;
    const previouslySelectedDeliverablesLabel = values.mapTypes;

    let delivrablesLabelForAllSensors = [];
    allSensors?.forEach((sensor) => {
      const supportedDelivrables = sensorsType.find(
        (snr) => sensor === snr.label
      );
      supportedDelivrables.allowedMapTypes.forEach((dlv) => {
        if (!delivrablesLabelForAllSensors.includes(dlv)) {
          delivrablesLabelForAllSensors.push(dlv);
        }
      });
    });
    if (showOnDataLibrary) {
      return missionMapTypes;
    }
    let delivrablesForAllSensors = missionMapTypes.filter((mt) =>
      delivrablesLabelForAllSensors.includes(mt.label)
    );
    if (previouslySelectedDeliverablesLabel?.length > 0) {
      const previouslySelectedDeliverables = missionMapTypes.filter((mt) =>
        previouslySelectedDeliverablesLabel.includes(mt.label)
      );

      previouslySelectedDeliverables.forEach((psdl) => {
        if (!delivrablesLabelForAllSensors.includes(psdl.label)) {
          delivrablesForAllSensors.push(psdl);
        }
      });
    }

    return delivrablesForAllSensors;
  };

  mapTypesToShow();
  return (
    <Box sx={{ ...orderBox, ...additionalStyle }}>
      <TextStructures
        text={
          !showOnDataLibrary
            ? "Choose your data deliverables"
            : "Choose the deliverables/sensors"
        }
        number={numberCount}
      />
      <Box sx={styles.mapTypesCls}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={clickedMap ? 8 : 12}>
            <MapTypes
              mapTypes={mapTypesToShow()}
              colMdSpacing={colMdSpacing || 2}
              colXsSpacing={colXsSpacing || 12}
              clickable
              selectedMapTypes={mapTypes}
              onRemove={(data) => {
                setFieldValue("mapTypes", data);
                if (setMapTypesFilter) {
                  setMapTypesFilter(data);
                  setTriggerReload(!triggerReload);
                }
              }}
              onSelect={(data) => setClickedMap(data)}
              setJpgFlag={setJpgFlag}
              hideJpg={hideJpg}
            />
            {errors.mapTypes && touched.mapTypes ? (
              <Typography variant="body3" sx={styles.errorMsg}>
                {errors.mapTypes}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={0.5}>
            {clickedMap && (
              <Box sx={styles.dividerContainer}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={styles.dividerCls}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={3.5}>
            {clickedMap && (
              <MapTypeInfo
                label={clickedMap}
                onClick={addMapTypes}
                jpgFlag={jpgFlag}
              />
            )}
          </Grid>
          {!showOnDataLibrary && (
            <Grid item xs={12} mt={3}>
              <Typography variant="subtitle1Med">
                Do you require RTK?
              </Typography>
              <SwitchSelect
                checked={values.rtkRequire}
                handleChange={(e) => {
                  setFieldValue("rtkRequire", e.target.checked);
                }}
                name="rtkRequire"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

ChooseProducts.propTypes = {
  mapTypes: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  hideJpg: PropTypes.bool,
  colMdSpacing: PropTypes.number,
  colXsSpacing: PropTypes.number,
  numberCount: PropTypes.number,
  setMapTypesFilter: PropTypes.func,
  triggerReload: PropTypes.bool,
  showOnDataLibrary: PropTypes.bool,
  setTriggerReload: PropTypes.func,
  additionalStyle: PropTypes.object,
  values: PropTypes.object,
};

// ChooseProducts.defaultProps = {
//   mapTypes: [],
//   touched: {},
//   values: {},
//   errors: {},
//   hideJpg: false,
//   colMdSpacing: 2,
//   colXsSpacing: 12,
//   numberCount: null,
//   setMapTypesFilter: undefined,
//   triggerReload: false,
//   setTriggerReload: undefined,
//   additionalStyle: {},
// };

export default React.memo(ChooseProducts);
