import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CsvKmlUploadInstructions } from "../../../../lib/constants";
import Instruction from "./Instruction";
import UploadDropZone from "./UploadDropZone";
import theme from "../../../../theme";
import PropTypes from "prop-types";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";

const styles = {
  bodyHead: {
    marginTop: "1em",
  },
  instructions: {
    marginTop: "3em",
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
  },
  uploadContainer: { marginBottom: "3em" },
  fileCountDetails: {
    paddingTop: "1em",
    marginTop: "1em",
  },
  borderCls: {
    borderTop: `1px solid ${theme.palette.black.darkSeaBlack}`,
  },
};

const UploadData = ({
  setData = undefined,
  showGlobalLoad = false,
  projectId = undefined,
  showInfo = true,
  projectName = undefined,
  showCountMessage = false,
  setShowCountMessage = () => {},
  uploadValidData = [],
  setUploadValidData = () => {},
  setErrorDisplay = () => {},
}) => {
  const [courruptedData, setCorruptedData] = useState([]);
  useEffect(() => {
    if (uploadValidData.length < 1) {
      setShowCountMessage(false);
      setErrorDisplay(false);
    }
  }, [uploadValidData, setShowCountMessage, setErrorDisplay]);

  return (
    <Box>
      {showInfo && (
        <>
          <Box sx={styles.bodyHead}>
            <Typography variant="body1">
              To add individual sites to your project, please follow the
              instructions below.
            </Typography>
          </Box>
          <Box sx={{ ...styles.instructions, ...styles.uploadContainer }}>
            {CsvKmlUploadInstructions.map((instruction, i) => (
              <Instruction data={instruction} key={i} />
            ))}
          </Box>
        </>
      )}
      <Box>
        <UploadDropZone
          setData={setData}
          projectId={projectId}
          courruptedData={courruptedData}
          setCorruptedData={setCorruptedData}
          validData={uploadValidData}
          setValidData={setUploadValidData}
          setShowCountMessage={setShowCountMessage}
          showGlobalLoad={showGlobalLoad}
          showInstruction={!showInfo}
          projectName={projectName}
        />
      </Box>
      <Box
        sx={{
          ...styles.fileCountDetails,
          borderTop: showInfo
            ? `1px solid ${theme.palette.black.darkSeaBlack}`
            : "none",
        }}
      >
        {uploadValidData.length > 0 && showCountMessage && (
          <StatusAlertContainer
            severity="success"
            coloredBackground
            title="Sites uploaded!"
            heading={`In the uploaded file we extracted ${uploadValidData.length} sites.`}
          />
        )}
        {courruptedData.length - 1 > 0 && showCountMessage && (
          <StatusAlertContainer
            severity="error"
            coloredBackground
            title="Sites were not uploaded!"
            heading={`There are some errors coming in ${
              courruptedData.length - 1
            } rows.`}
          />
        )}
      </Box>
    </Box>
  );
};

UploadData.propTypes = {
  setData: PropTypes.func,
  projectId: PropTypes.string,
  showGlobalLoad: PropTypes.bool,
  showInfo: PropTypes.bool,
  projectName: PropTypes.string.isRequired,
  showCountMessage: PropTypes.bool,
  setShowCountMessage: PropTypes.func,
  uploadValidData: PropTypes.array,
  setUploadValidData: PropTypes.func.isRequired,
  setErrorDisplay: PropTypes.func.isRequired,
};
// UploadData.defaultProps = {
//   setData: undefined,
//   showGlobalLoad: false,
//   projectId: undefined,
//   showInfo: true,
// };
export default UploadData;
