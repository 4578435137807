import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import theme from "../../../theme";
import PropTypes from "prop-types";

const OrderStillFeasibilitySelector = ({ value, setFieldValue, name }) => {
  const styles = {
    colorPilot: {
      "&.Mui-checked": {
        color: theme.palette.secondary.main,
      },
    },
    marginL: { marginRight: "45px" },
  };
  const handleChange = (event) => {
    setFieldValue(name, event.target.value === "true");
  };

  return (
    <FormControl>
      <RadioGroup
        row
        name={name}
        value={value == null ? "" : value.toString()}
        onChange={handleChange}
      >
        <FormControlLabel
          value="true"
          control={<Radio sx={styles.colorPilot} />}
          label="Yes"
          sx={styles.marginL}
        />
        <FormControlLabel
          value="false"
          control={<Radio sx={styles.colorPilot} />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
};
OrderStillFeasibilitySelector.propTypes = {
  value: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
export default OrderStillFeasibilitySelector;
