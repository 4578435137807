import React, { useContext, useCallback, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import MissionContext from "./MissionContext";
import AppButton from "../../../../global/elements/AppButton";
import UserNextActionsBanner from "./UserNextActionsBanner";
import RejectModal from "./RejectModal";
import {
  confirmClientOrder,
  skipQuoteFcn,
} from "../../../../../services/missions";
import {
  missionStatus,
  paymentStatus,
  missionTypeConstant,
  AdminNextActions,
  projectStatus,
  quoteStatus,
  packageStatusConstants,
} from "../../../../../lib/constants";
import theme from "src/theme";
import { useSnackbar } from "notistack";
import Dialogue from "src/components/global/Dialogue";
import { getTabIndex } from "src/lib/helpers";
import firebase from "src/firebase";
import { DateTime } from "luxon";

const AdminNextAction = ({
  actionText: defaultActionText = "Your next actions",
  actionSubText: defaultActionSubText = "",
  actionSubText2: defaultActionSubText2 = "",
  tabNumber = 2,
  background: defaultBackground = undefined,
  bigText: defaultBigText = false,
  projectMission = false,
  openPopUp = () => {},
  pilotPackages = [],
  projectDetails = undefined,
  lastQuoteStatus = undefined,
}) => {
  const actionData = useContext(MissionContext);
  const { mission } = actionData || {};
  const { enqueueSnackbar } = useSnackbar();
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSkipQuoteDialog, setShowSkipQuoteDialog] = useState(false);
  const [confirmMissionLoading, setConfirmMissionLoading] = useState(false);
  const [skipQuoteLoading, setSkipQuoteLoading] = useState(false);

  const handleConfirmClick = () => setShowConfirmDialog(true);
  const handleSkipQuoteClick = () => setShowSkipQuoteDialog(true);
  const handleRejectOrder = () => setShowRejectDialog(true);

  const latestPackageIndex = pilotPackages.length - 1;
  const quoteAndInvoice = useCallback(() => {
    actionData?.handleTabChange("e", 1);
  }, [actionData]);

  const checkData = useCallback(() => {
    const tabIndex =
      pilotPackages?.length +
      getTabIndex({ missionType: mission?.missionType });
    actionData?.handleTabChange("e", tabIndex - 1);
  }, [actionData, pilotPackages?.length, mission?.missionType]);

  const confirmInitialClientOrder = async () => {
    setConfirmMissionLoading(true);
    try {
      await confirmClientOrder(firebase, mission);
      setShowConfirmDialog(false);
      actionData?.refreshMission();
    } catch (e) {
      console.error("Error", e);
      enqueueSnackbar(e.message || "Failed to confirm order", {
        variant: "error",
      });
    } finally {
      setConfirmMissionLoading(false);
    }
  };

  const skipQuoteForMission = async () => {
    setSkipQuoteLoading(true);
    try {
      await skipQuoteFcn(mission);
      setShowSkipQuoteDialog(false);
      actionData?.refreshMission();
    } catch (e) {
      console.error("Error", e);
      enqueueSnackbar("Failed to skip quote. Please try again.", {
        variant: "error",
      });
    } finally {
      setSkipQuoteLoading(false);
    }
  };

  const getBannerState = useCallback(() => {
    let state = {
      actionText: defaultActionText,
      actionSubText: defaultActionSubText,
      actionSubText2: defaultActionSubText2,
      tabNumber,
      background: defaultBackground,
      bigText: defaultBigText,
      button1: null,
      button2: null,
    };

    if (mission && !projectMission) {
      switch (mission.status) {
        case missionStatus.initialClientOrder:
          state = {
            ...state,
            actionText: AdminNextActions.newOrder,
            actionSubText: AdminNextActions.confirmOrder,
            button1: (
              <AppButton
                look="blue"
                label="Confirm Order"
                onClick={handleConfirmClick}
                submittingState={confirmMissionLoading}
                fullWidth
              />
            ),
            button2: (
              <AppButton
                look="inverted"
                label="Reject order"
                noIcon
                isDisabled={!mission.missionType}
                onClick={handleRejectOrder}
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.confirmedMission:
        case missionStatus.biddingOngoing:
        case missionStatus.pilotInvitedForBidding:
          state = {
            ...state,
            actionText: AdminNextActions.inviteOperator,
            actionSubText: AdminNextActions.inviteOperatorSubText,
            button1: (
              <AppButton
                look="blue"
                label="Invite Drone Operators"
                onClick={openPopUp}
                fullWidth
              />
            ),
            button2: (
              <AppButton
                look="inverted"
                label={
                  mission.status === missionStatus.confirmedMission
                    ? "Select a Single Operator"
                    : "Change Bidding Deadline"
                }
                onClick={() =>
                  actionData?.setPageMode(
                    mission.status === missionStatus.confirmedMission
                      ? actionData?.PageModes.ASSIGN_PILOTS
                      : actionData?.PageModes.EDIT_MISSION
                  )
                }
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.biddingEnded:
          state = {
            ...state,
            actionText: "Bidding Has Ended!",
            actionSubText: AdminNextActions.biddingEnded,
            button1: (
              <AppButton
                look="blue"
                label="Extend Bidding Deadline"
                onClick={() =>
                  actionData?.setPageMode(actionData?.PageModes.EDIT_MISSION)
                }
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.pilotSelected:
          state = {
            ...state,
            actionText: AdminNextActions.createQuote,
            actionSubText: `${AdminNextActions.sendQuote} ${AdminNextActions.skipQuoteDesc}`,
            button1: (
              <AppButton
                look="blue"
                label={AdminNextActions.createQuote}
                onClick={quoteAndInvoice}
                fullWidth
              />
            ),
            button2: (
              <AppButton
                look="inverted"
                label={AdminNextActions.skipQuote}
                onClick={handleSkipQuoteClick}
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.quoteReissuedToClient:
        case missionStatus.quoteSentToClient:
          state = {
            ...state,
            actionText: AdminNextActions.quoteSent,
            actionSubText: AdminNextActions.reviewQuote,
          };
          break;

        case missionStatus.quoteDeclinedByClient:
          state = {
            ...state,
            actionText: "Quote been rejected",
            actionSubText: AdminNextActions.declineQuote,
            button1: (
              <AppButton
                look="blue" // Changed from "black" to match other buttons and ensure visibility
                label={AdminNextActions.createQuote}
                onClick={quoteAndInvoice}
                fullWidth
              />
            ),
          };
          break;
        default:
          break;
      }
    }

    if (mission) {
      switch (mission.status) {
        case missionStatus.quoteAcceptedByClient:
          state = {
            ...state,
            actionText: "Quote Accepted",
            actionSubText: AdminNextActions.assignPilots,
          };
          break;

        case missionStatus.pilotAssigned:
          state = {
            ...state,
            actionText: AdminNextActions.missionInReviewByPilot,
            bigText: true,
          };
          break;

        case missionStatus.missionNotFeasible:
          state = {
            ...state,
            actionText: "Location is not accessible",
            background: theme.palette.status.warningLight,
            button1: (
              <AppButton
                look="black"
                label="Review Location"
                onClick={() =>
                  actionData?.setPageMode(actionData?.PageModes.EDIT_MISSION)
                }
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.flyingDateSet:
          state = {
            ...state,
            actionText: actionData?.missionPlanning?.data?.currentFlightDate
              ? `Flying date set: ${DateTime.fromJSDate(
                  new Date(actionData?.missionPlanning?.data?.currentFlightDate)
                ).toFormat("dd/MM/yyyy")}`
              : "Flying date not set yet",
            bigText: true,
            actionSubText: AdminNextActions.flyingDateSet,
          };
          break;
        case missionStatus.rescheduled:
          state = {
            ...state,
            actionText: actionData?.missionPlanning?.data?.currentFlightDate
              ? `Mission Rescheduled to: ${DateTime.fromJSDate(
                  new Date(actionData?.missionPlanning?.data?.currentFlightDate)
                ).toFormat("dd/MM/yyyy")}`
              : "Rescheduled date not set yet",
            bigText: true,
          };
          break;

        case missionStatus.dataProcessing:
          state = {
            ...state,
            actionText: AdminNextActions.dataProcessing,
            bigText: true,
          };
          break;

        case missionStatus.dataUploading:
        case missionStatus.pilotPackageRejected:
          state = {
            ...state,
            actionText: "Data Collected",
            actionSubText: AdminNextActions.dataUploading,
          };
          break;

        case missionStatus.sentForReviewToGlobhe:
          if (
            pilotPackages[latestPackageIndex]?.packageStatusByAdmin ===
            packageStatusConstants.requestedChanges
          ) {
            state = {
              ...state,
              actionText: "Changes Requested",
              actionSubText:
                AdminNextActions.requestedChanges +
                pilotPackages[latestPackageIndex]?.requestedReason,
            };
            break;
          }
          state = {
            ...state,
            actionText: "Verify the data",
            actionSubText: AdminNextActions.verifyData,
            tabNumber:
              pilotPackages?.length +
              getTabIndex({ missionType: mission?.missionType }),
            button1: (
              <AppButton
                look="blue"
                label="Verify the data"
                onClick={checkData}
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.sentForReviewToClient:
          if (
            pilotPackages[latestPackageIndex]?.packageStatusByAdmin ===
            packageStatusConstants.requestedChanges
          ) {
            state = {
              ...state,
              actionText: "Changes Requested",
              bigText: true,
              actionSubText:
                AdminNextActions.requestedChanges +
                pilotPackages[latestPackageIndex]?.requestedReason,
            };
            break;
          } else if (
            pilotPackages[latestPackageIndex]?.packageStatusByClient ===
            packageStatusConstants.requestedChanges
          ) {
            state = {
              ...state,
              actionText: "Changes Requested by Client",
              bigText: true,
              actionSubText:
                AdminNextActions.requestedChangesByClient +
                pilotPackages[latestPackageIndex]?.requestedReason,
            };
            break;
          }
          state = {
            ...state,
            actionText: AdminNextActions.qualityCheck,
          };
          break;

        case missionStatus.dataRejectedByClient:
          state = {
            ...state,
            actionText: "Data Rejected by Client",
            actionSubText: AdminNextActions.dataRejected,
            background: theme.palette.status.warningLight,
            tabNumber:
              pilotPackages?.length +
              getTabIndex({ missionType: mission?.missionType }),
            button1: (
              <AppButton
                look="blue"
                label="Recheck the data"
                onClick={checkData}
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.dataAcceptedByClient:
          state = {
            ...state,
            tabNumber:
              pilotPackages?.length +
              getTabIndex({ missionType: mission?.missionType }),
            actionText: "Data Accepted by Client !!",
            actionSubText: AdminNextActions.dataAccepted,
            button1: (
              <AppButton
                look="blue"
                label="Add to Delivery"
                onClick={checkData}
                fullWidth
              />
            ),
          };
          break;

        case missionStatus.completed:
          if (
            mission.paymentStatusPilot === paymentStatus.unpaid &&
            mission.missionType === missionTypeConstant.clientOrder
          ) {
            state = {
              ...state,
              actionText: "Please update pilot payment status",
              actionSubText:
                "Navigate to the 'Quotes & Invoices' tab and under the Operator's Payment click on the check Invoice button to update the pilot payment status.",
              bigText: true,
              button1: (
                <AppButton
                  look="blue"
                  label="Update status"
                  onClick={quoteAndInvoice}
                  fullWidth
                />
              ),
            };
          } else if (
            mission.missionType === missionTypeConstant.clientOrder &&
            mission.paymentStatusClient === paymentStatus.unpaid
          ) {
            state = {
              ...state,
              actionText: "Payment is overdue for Client",
              actionSubText:
                "In Mission Details please click on edit icon of Order Details and mark the Client payment as paid if done so.",
              background: theme.palette.status.warningLight,
            };
          }
          break;
        default:
          break;
      }
    }

    if (projectDetails?.status) {
      const lastStatus = lastQuoteStatus;
      switch (projectDetails.status) {
        case projectStatus.initial:
          if (lastStatus === quoteStatus.declined) {
            state = {
              ...state,
              actionText: "Quote Rejected By Client",
              actionSubText: AdminNextActions.declineQuote,
            };
          } else if (lastStatus === quoteStatus.quoteSent) {
            state = {
              ...state,
              actionText: AdminNextActions.quoteSent,
              actionSubText: AdminNextActions.reviewQuote,
            };
          } else if (lastStatus === quoteStatus.deleted) {
            state = {
              ...state,
              actionText: "Quote Deleted",
              actionSubText:
                "You have Deleted the current Quote sent to the Client. Please create a new Quote for the Client.",
            };
          } else {
            state = {
              ...state,
              actionText: "Initial Project Order",
              actionSubText: AdminNextActions.initialProject,
            };
          }
          break;

        case projectStatus.completed:
          state = {
            ...state,
            actionText: "Your Data is ready for Download",
            actionSubText: AdminNextActions.downloadData,
            background: theme.palette.status.download,
          };
          break;

        case projectStatus.archived:
          state = {
            ...state,
            actionText: "This Project has been Archived",
            background: theme.palette.status.warningLight,
          };
          break;

        case projectStatus.deleted:
          state = {
            ...state,
            actionText: "This Project has been Deleted",
            background: theme.palette.status.warningLight,
          };
          break;

        case projectStatus.active:
          state = {
            ...state,
            actionText: "Active Project!",
            actionSubText: AdminNextActions.activeProject,
          };
          break;

        case projectStatus.draft:
          state = {
            ...state,
            actionText: "This order is not yet submitted",
            actionSubText:
              "Your project is currently in draft mode and is not yet submitted. Feel free to make any adjustments or add more sites as needed. Once you’re ready to proceed, simply submit your order below and you’ll receive a quote within 24 hours",
            background: theme.palette.status.warningLight,
          };
          break;

        case projectStatus.draftClient:
          state = {
            ...state,
            actionText: "Pending Submission",
            actionSubText:
              "Here you find your unsubmitted multi-location or recurrent orders. They are saved here and are not submitted yet. Make sure you have inserted all your sites and filled all your details. Once you are ready, go to the order and click on 'Submit order'",
            background: theme.palette.status.warningLight,
          };
          break;

        default:
          break;
      }
    }

    return state;
  }, [
    mission,
    projectMission,
    pilotPackages,
    openPopUp,
    quoteAndInvoice,
    confirmMissionLoading,
    checkData,
    actionData,
    defaultActionText,
    defaultActionSubText,
    defaultActionSubText2,
    tabNumber,
    defaultBackground,
    defaultBigText,
    projectDetails,
    lastQuoteStatus,
    latestPackageIndex,
  ]);

  const bannerState = useMemo(() => getBannerState(), [getBannerState]);

  if (!bannerState || !bannerState.actionText) return null;

  return (
    <Box>
      <UserNextActionsBanner
        actionText={bannerState.actionText}
        actionSubText={bannerState.actionSubText}
        tabNumber={bannerState.tabNumber}
        background={bannerState.background}
        button1={bannerState.button1}
        button2={bannerState.button2}
        bigText={bannerState.bigText}
      />
      <RejectModal
        showRejectDialog={showRejectDialog}
        setShowRejectDialog={setShowRejectDialog}
      />
      <Dialogue
        showDialogue={showConfirmDialog}
        setShowDialogue={setShowConfirmDialog}
        DialogueHeadText="Confirm Order"
        DialogueBody={
          <Typography variant="body1" padding="1em 0">
            Are you sure you want to confirm this order?
          </Typography>
        }
        showCancelButton={true}
        cancelButtonText="Cancel"
        sumitButtonText="Confirm"
        onSubmit={confirmInitialClientOrder}
        showSubmitLoader={confirmMissionLoading}
        submitButtonLook="blue"
      />
      <Dialogue
        showDialogue={showSkipQuoteDialog}
        setShowDialogue={setShowSkipQuoteDialog}
        DialogueHeadText="Skip Quote"
        DialogueBody={
          <>
            <Typography variant="body1" padding="0.5rem 0">
              Do you want to continue without providing a quote?
            </Typography>
            <Typography
              padding="0.5rem 0"
              sx={{ fontStyle: "italic", fontSize: "0.9rem" }}
            >
              *{AdminNextActions.skipQuoteDesc}
            </Typography>
          </>
        }
        showCancelButton={true}
        cancelButtonText="Cancel"
        sumitButtonText="Skip Quote"
        onSubmit={skipQuoteForMission}
        showSubmitLoader={skipQuoteLoading}
        submitButtonLook="blue"
      />
    </Box>
  );
};

AdminNextAction.propTypes = {
  actionText: PropTypes.string,
  actionSubText: PropTypes.string,
  actionSubText2: PropTypes.string,
  tabNumber: PropTypes.number,
  background: PropTypes.string,
  bigText: PropTypes.bool,
  openPopUp: PropTypes.func.isRequired,
  pilotPackages: PropTypes.array,
  projectMission: PropTypes.bool,
  projectDetails: PropTypes.object,
  lastQuoteStatus: PropTypes.string,
};

export default React.memo(AdminNextAction);
