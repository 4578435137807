import React, { useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AppButton from "../../../global/elements/AppButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  ModelCSVUrl,
  notificationType,
  userRoles,
} from "../../../../lib/constants";
import Dropzone from "react-dropzone";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import {
  fileCheck,
  getProjectRef,
  makeCsvRedable,
  uploadAFile,
} from "../../../../services/project";
import firebase from "src/firebase";
import ProgressBar from "../../../global/ProgressBar";
import Dialogue from "../../../global/Dialogue";
import theme from "../../../../theme";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import PropTypes from "prop-types";
import UploadButton from "../../../global/elements/UploadButton";
import {
  CsvKmlUploadInstructions,
  CsvKmlUploadInstructionsStep1,
  CsvKmlUploadInstructionsStep2,
  CsvNotes,
  CsvFinalNotes,
} from "../../../../lib/constants";
import { createNotification } from "../../../../services/notification";
import { getUser } from "../../../../services/auth";
import StepInstruction from "./StepInstruction";
import { Link } from "gatsby";
import exampleCsvImage from "../../../../../src/assets/images/exampleCsvUpload.png";

const styles = {
  modelCsvButton: {
    whiteSpace: "nowrap",
    width: { xs: "100%", md: "auto" },
    marginBottom: { xs: "20px", md: "auto" },
  },
  modalContainer: {
    background: theme.palette.grey.white,
    textAlign: "center",
  },
  dialogPopupDesc: {
    color: theme.palette.black.coolGrey,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
  greenText: {
    color: theme.palette.status.success,
  },
  loadingDiv: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  dialogueWrapper: { margin: "1em 0.4em " },
  helpGrid: {
    backgroundColor: theme.palette.primary.palePistachio,
    padding: "30px",
  },
  stepGroup: {
    paddingTop: "20px",
  },
  mainInputBox: {
    width: { xs: "100%", md: "auto" },
    marginBottom: { xs: "20px", md: "auto" },
  },
  exampleCsvImage: {
    height: { xs: "100px", sm: "170px", xl: "200px" },
    width: { xs: "100%", md: "75%", lg: "70%", xl: "58%" },
  },
  anotherLinkText: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    textDecorationColor: theme.palette.secondary.main,
    cursor: "pointer",
  },
};
const UploadDropZone = ({
  setData = undefined,
  courruptedData = [],
  setCorruptedData = undefined,
  validData = [],
  setValidData = undefined,
  setShowCountMessage = false,
  showGlobalLoad = false,
  projectId = undefined,
  showInstruction = false,
  projectName = "",
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openCSVFieldSelector, setOpenCSVFieldSelector] = useState(false);
  const [csvLabels, setCsvLabels] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [percent, setPercent] = useState(0);
  const [rawData, setRawData] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const [missionCreating, setMissionCreating] = useState(false);
  const projectRef = useRef();

  const formInitialValues = {
    missionName: "",
    description: "",
    latitude: "",
    longitude: "",
  };

  const validationSchema = Yup.object().shape({
    missionName: Yup.string().nullable(),
    description: Yup.string().nullable(),
    latitude: Yup.string().required("Please select latitude").nullable(),
    longitude: Yup.string().required("Please select longitude").nullable(),
  });
  const handleDialogueSubmit = () => {
    OnSuccess();
    if (validData?.length > 0) {
      setMissionCreating(true);
      uploadCSVFile(validData);
    } else {
      enqueueSnackbar(`Add atleast one valid data to create a mission.`, {
        variant: "error",
      });
    }
  };
  const Refresh = () => {
    setShowUploadProgress(false);
    setShowCountMessage(false);
    setValidData([]);
    setCorruptedData([]);
    setPercent(0);
  };
  const OnSuccess = () => {
    setShowUploadProgress(true);
    setShowCountMessage(true);
    setShowSubmitLoader(true);
  };
  const uploadCSVFile = async (validData) => {
    try {
      const uploadFileName = `${Number(new Date())}-${fileData.name}`;
      let path = `/Csvfiles/${uploadFileName}`;
      const fileUploadStatus = await uploadAFile(
        firebase,
        fileData,
        path,
        setPercent
      );
      if (fileUploadStatus.status === "Success") {
        let fileInfo = {
          fileData: fileData.name,
          fileSize: fileData.size,
          fileType: fileData.type,
          allCoordinatesInfo: validData,
        };
        //firebase project update file update
        const updateFileInfoToProject = await firebase
          .functions()
          .httpsCallable("updateFileInfoToProject");
        const data = await updateFileInfoToProject({
          projectId: projectId ? projectId : projectRef?.current?.id,
          fileUrl: fileUploadStatus?.fileURL,
          fileInfo: fileInfo,
          rawData: rawData,
          oprationType: "csv",
        });

        if (data.data.status === "Success") {
          setData({
            missionData: data,
            projectReference: projectRef.current,
          });
          setMissionCreating(false);
          setOpenDialogue(false);
          setShowCountMessage(true);
          setOpenCSVFieldSelector(false);
          setShowSubmitLoader(false);
          enqueueSnackbar(`${data.data.message}`, {
            variant: "success",
          });
        } else {
          setShowCountMessage(false);
          setShowSubmitLoader(false);
          enqueueSnackbar(`${data.data.message}.`, {
            variant: "error",
          });
        }
      } else {
        setShowCountMessage(false);
        enqueueSnackbar(`${fileUploadStatus.message}.`, {
          variant: "error",
        });
      }
    } catch (err) {
      console.log("Error", err);
      setOpenDialogue(false);
      setOpenCSVFieldSelector(false);
      enqueueSnackbar(
        `${err.message || "Error in creating create a mission."}`,
        {
          variant: "error",
        }
      );
    }
  };

  const getProjectReference = async () => {
    try {
      const projectReference = await getProjectRef();
      projectRef.current = projectReference;
      return projectReference;
    } catch (e) {
      console.log("Error", e);
      return null;
    }
  };

  const formSubmitHandler = async (values) => {
    setOpenDialogue(true);
    setMissionCreating(true);
    const updatedDataSet = makeCsvRedable(parsedData, values, projectName);
    if (updatedDataSet.status === "Success") {
      setCorruptedData(updatedDataSet?.invalidData || []);
      setValidData(updatedDataSet?.validData || []);
      let projectReference = null;
      if (!projectId) {
        projectReference = await getProjectReference();
        if (projectReference) {
          projectRef.current = projectReference;
        }
      }

      if (!projectId && !projectReference) {
        setOpenDialogue(false);
        enqueueSnackbar(`Error in generating project order!!!`, {
          variant: "error",
        });
        return;
      }

      if (updatedDataSet?.invalidData?.length > 1) {
        if (!(updatedDataSet?.validData?.length > 0)) {
          setOpenDialogue(false);
          enqueueSnackbar(
            `CSV file should contain atleast one valid data to create a mission.`,
            {
              variant: "error",
            }
          );
        }
      } else {
        if (updatedDataSet?.validData?.length > 0) {
          setMissionCreating(true);
          // setOpenDialogue(true);
          setShowSubmitLoader(true);
          uploadCSVFile(updatedDataSet?.validData);
        } else {
          setOpenDialogue(false);
          enqueueSnackbar(
            `CSV file should contain atleast one valid data to create a mission.`,
            {
              variant: "error",
            }
          );
        }
      }
    } else {
      setOpenDialogue(false);
      enqueueSnackbar(
        `${updatedDataSet.message || "Error in getting data from CSV file"}.`,
        {
          variant: "error",
        }
      );
    }
  };
  const DownloadCSV = () => {
    window.open(ModelCSVUrl);
  };

  const createMissionUsingCSV = async (files, rejectedFiles) => {
    Refresh();
    const file = files[0];
    if (!file) return;
    setFileData(file);
    const parsedData = await fileCheck(file, rejectedFiles, "csv");
    setRawData(parsedData.rawData);
    setParsedData(parsedData?.parsedCSVData);
    if (parsedData.status === "Success") {
      let validHeader = false;
      let allColumns = [];

      if (parsedData?.columns && parsedData?.columns?.length > 0) {
        parsedData?.columns?.forEach((singleColumn) => {
          if (singleColumn) {
            allColumns.push(singleColumn);
          }
        });
      }
      if (allColumns?.length >= 2) {
        validHeader = true;
        setCsvLabels(allColumns);
      } else {
        enqueueSnackbar(
          `Please Remove any blank rows from the top of the csv file.`,
          {
            variant: "error",
          }
        );
      }
      if (validHeader) {
        if (
          parsedData?.parsedCSVData &&
          parsedData?.parsedCSVData?.length > 0
        ) {
          setOpenCSVFieldSelector(true);
        } else {
          enqueueSnackbar(
            `CSV file should contain atleast one valid data to create a mission.`,
            {
              variant: "error",
            }
          );
        }
      }
    }
  };
  const handleKMLUpload = async (acceptedFiles, rejectedFiles) => {
    try {
      setOpenDialogue(true);
      setMissionCreating(true);
      Refresh();
      setOpenCSVFieldSelector(false);
      const kmlFileStatus = await fileCheck(
        acceptedFiles,
        rejectedFiles,
        "kml",
        projectName
      );
      if (kmlFileStatus.status === "Success") {
        let file = kmlFileStatus.file;
        setFileData(file);
        const uploadFileName = `${+new Date()}-${file.name}`;
        let path = `kmlFiles/${uploadFileName}`;
        const fileUploadStatus = await uploadAFile(
          firebase,
          file,
          path,
          setPercent
        );
        if (fileUploadStatus?.status === "Success") {
          let projectReference = null;
          // if (!projectId) {
          //   projectReference = await getProjectRef();
          //   setProjectRef(projectReference);
          // }

          if (!projectId) {
            projectReference = await getProjectReference();
            if (projectReference) {
              projectRef.current = projectReference;
            }
          }

          if (!projectId && !projectReference) {
            setOpenDialogue(false);
            enqueueSnackbar(`Error in generating project order`, {
              variant: "error",
            });
            return;
          }
          // setOpenDialogue(true);
          setMissionCreating(true);
          setShowSubmitLoader(true);
          let fileInfo = {
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
            allCoordinatesInfo: kmlFileStatus?.validKMLData,
          };
          // firebase project update file update
          const updateFileInfoToProject = await firebase
            .functions()
            .httpsCallable("updateFileInfoToProject");
          const data = await updateFileInfoToProject({
            projectId: projectId ? projectId : projectRef?.current?.id,
            fileUrl: fileUploadStatus?.fileURL,
            fileInfo: fileInfo,
            rawData: kmlFileStatus?.rawData,
            oprationType: "kml",
          });

          if (data.data.status === "Success") {
            setData({
              missionData: data,
              projectReference: projectRef.current,
            });
            setValidData(data.data.createdFileData.allMissionCoordinatesInfo);
            OnSuccess();
            enqueueSnackbar(`${data.data.message}`, {
              variant: "success",
            });
            setMissionCreating(false);
            setOpenDialogue(false);
            setShowSubmitLoader(false);
          } else {
            setOpenDialogue(false);
            setShowSubmitLoader(false);
            enqueueSnackbar(`${data.data.message}.`, {
              variant: "error",
            });
          }
        }
      } else {
        setOpenDialogue(false);
        enqueueSnackbar(`${kmlFileStatus?.message}.`, {
          variant: "error",
        });
        const currentUser = getUser("userProfile");
        if (currentUser.role === userRoles.client) {
          await createNotification(
            firebase,
            notificationType.adminNotification,
            "Client getting error in KML upload.",
            "Client getting error in KML upload.",
            `/app/admin/manage-client/${currentUser.id}`
          );
          const redirectUrl = `https://app.globhe.com/app/admin/manage-client/${currentUser.id}`;
          const dataToSend = {
            clientName: currentUser.name,
            clientEmail: currentUser.email,
            errorMessage: kmlFileStatus?.message || "Not a valid kml",
            profileDirectLink: redirectUrl,
          };
          const kmlNotValidEmail = firebase
            .functions()
            .httpsCallable("kmlNotValidEmail");

          kmlNotValidEmail(dataToSend);
        }
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const DeleteDialogueBody = () => {
    return (
      <Box sx={styles.dialogueWrapper}>
        {!missionCreating ? (
          <Box sx={styles.modalContainer}>
            <Typography variant="h6">
              {courruptedData?.length > 1 ? (
                <>
                  You have {courruptedData.length - 1} corrupted data and{" "}
                  {validData.length} valid data.
                </>
              ) : (
                <>
                  File is uploaded{" "}
                  <Box component={"span"} sx={styles.greenText}>
                    successfully!
                  </Box>
                </>
              )}
            </Typography>
            <br />
            {courruptedData?.length > 1 && (
              <Typography variant="h5" sx={styles.dialogPopupDesc}>
                Are you sure you want to continue uploading file with{" "}
                {validData.length} valid data ?{" "}
              </Typography>
            )}
          </Box>
        ) : (
          <Box sx={styles.loadingDiv}>
            <Typography variant="h6">Please wait!</Typography>
            <br />
            <Box>
              <UniversalLoader />
            </Box>
            <br />
            <br />
            <Typography variant="subtitle1Med">
              Your file is getting uploaded
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={12}>
          <Grid container sx={{ textAlign: "right", justifyContent: "end" }}>
            {/* {showInstruction && (
              <Grid item xs={12} md={8}>
                <Typography variant="body1">
                  To quickly add your location, please upload a CSV or a KML
                  file. If you are using a CSV file, please follow the below
                  instructions:
                </Typography>
              </Grid>
            )} */}
            <Grid item xs={12} md={3} lg={2}>
              <AppButton
                label={"Example CSV"}
                customIcon={<FileDownloadOutlinedIcon />}
                onClick={DownloadCSV}
                addtionalStyle={styles.modelCsvButton}
                look="inverted"
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Dropzone
                accept={[".csv", ".kml"]}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  if (acceptedFiles[0].type === "text/csv") {
                    createMissionUsingCSV(acceptedFiles, rejectedFiles);
                  } else {
                    handleKMLUpload(acceptedFiles, rejectedFiles);
                  }
                }}
                name="missionCSV"
              >
                {({ getRootProps, getInputProps }) => (
                  <Box>
                    <div
                      {...getRootProps({
                        onClick: (event) =>
                          showGlobalLoad ? event?.stopPropagation() : null,
                      })}
                    >
                      <input {...getInputProps()} name="missionCSV" />
                      <UploadButton
                        text="Upload your csv or kml"
                        isDisabled={showGlobalLoad}
                        customIcon={<FileUploadOutlinedIcon />}
                        look={showGlobalLoad ? "blackDisabled" : "black"}
                        addtionalStyle={styles.mainInputBox}
                      />
                    </div>
                  </Box>
                )}
              </Dropzone>
            </Grid>
          </Grid>
        </Grid>
        {!validData.length > 0 && (
          <Grid item xs={12}>
            <Grid container sx={styles.helpGrid}>
              {showInstruction && (
                <Grid item xs={12}>
                  <StepInstruction
                    elements={CsvKmlUploadInstructionsStep1}
                    step="Instructions for uploading your files:"
                    quickDescription={
                      <Typography>
                        Please ensure your CSV file is formatted with the
                        following four columns. An example file can be
                        downloaded{" "}
                        <span
                          style={styles.anotherLinkText}
                          onClick={DownloadCSV}
                        >
                          here
                        </span>
                      </Typography>
                    }
                    endingDescription={
                      "Here is what a typical CSV file looks like"
                    }
                  />
                </Grid>
              )}
              <Grid xs={12}>
                <Box
                  component="img"
                  src={exampleCsvImage}
                  sx={styles.exampleCsvImage}
                />
              </Grid>
              {showInstruction &&
                !openCSVFieldSelector &&
                !validData.length > 0 && (
                  <Grid item xs={12} sx={styles.stepGroup}>
                    <StepInstruction
                      elements={CsvKmlUploadInstructions}
                      // step="OR Upload your KML File"
                      quickDescription={
                        "Please ensure that you are using a KML file, we currently do not support KMZ files."
                      }
                      endingDescription={
                        <Typography>
                          If you experience any difficulties please contact us
                          at
                          <a
                            href={"/app/support"}
                            target={"_blank"}
                            rel="noreferrer"
                            style={styles.anotherLinkText}
                          >
                            {" "}
                            support
                          </a>
                        </Typography>
                      }
                    />
                  </Grid>
                )}
              {showInstruction && openCSVFieldSelector && (
                <>
                  <Grid item xs={12} sx={styles.stepGroup}>
                    <StepInstruction
                      elements={CsvKmlUploadInstructionsStep2}
                      step="Step 2: Mapping fields"
                      quickDescription="For each field, please map the corresponding column headers from your CSV file to the fields on our site:"
                    />
                  </Grid>
                  <Grid item xs={12} sx={styles.stepGroup}>
                    <StepInstruction elements={CsvNotes} step="Note" />
                  </Grid>
                  <Grid item xs={12} sx={styles.stepGroup}>
                    <StepInstruction
                      elements={CsvFinalNotes}
                      step="Finalizing"
                    />
                  </Grid>
                  <Grid item xs={12} />

                  <Grid item xs={12} md={10}>
                    <Typography variant="body1">
                      If you encounter any difficulties or have questions,
                      don&apos;t hesitate to reach out to our support team for
                      assistance.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <AppButton
                      internalLink
                      url="/app/support"
                      look="black"
                      label={"Contact support"}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}

        {openCSVFieldSelector && (
          <Grid item xs={12}>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              // onSubmit={formSubmitHandler} //make status to uploaded and store in storage
            >
              {({ values }) => {
                return (
                  <Form id="csvFieldSelectorForm">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          Here you can select the corresponding column as
                          described above:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AutoCompleteInput
                          options={csvLabels}
                          name="missionName"
                          label="Site Name / Site ID"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AutoCompleteInput
                          options={csvLabels}
                          name="description"
                          label="Description"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AutoCompleteInput
                          options={csvLabels}
                          name="latitude"
                          label="Latitude"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <AutoCompleteInput
                          options={csvLabels}
                          name="longitude"
                          label="Longitude"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AppButton
                          // type="submit"
                          form="csvFieldSelectorForm"
                          look="green"
                          label={"Submit"}
                          onClick={() => formSubmitHandler(values)}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        )}
        <Grid item xs={12}>
          {fileData?.name &&
            percent > 0 &&
            showUploadProgress &&
            validData.length > 0 && (
              <Grid item sx={styles.progressBarPosition}>
                <ProgressBar origFileName={fileData?.name} progress={percent} />
              </Grid>
            )}
        </Grid>
      </Grid>

      {openDialogue && (
        <Dialogue
          showDialogue={openDialogue}
          setShowDialogue={setOpenDialogue}
          DialogueHeadText={"Upload File"}
          DialogueBody={DeleteDialogueBody()}
          showCancelButton={false}
          showSubmitButton={false}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Continue"}
          showSubmitLoader={showSubmitLoader}
          setShowSubmitLoader={setShowSubmitLoader}
          onSubmit={handleDialogueSubmit}
          submitButtonLook={"primary"}
        />
      )}
    </>
  );
};

UploadDropZone.propTypes = {
  setData: PropTypes.func,
  projectId: PropTypes.string,
  courruptedData: PropTypes.array,
  setCorruptedData: PropTypes.func,
  validData: PropTypes.array,
  setValidData: PropTypes.func,
  setShowCountMessage: PropTypes.bool,
  showGlobalLoad: PropTypes.bool,
  showInstruction: PropTypes.bool,
  projectName: PropTypes.string,
};
// UploadDropZone.defaultProps = {
//   setData: undefined,
//   courruptedData: [],
//   setCorruptedData: undefined,
//   validData: [],
//   setValidData: undefined,
//   setShowCountMessage: false,
//   showGlobalLoad: false,
//   projectId: undefined,
//   showInstruction: false,
//   projectName: "",
// };
export default UploadDropZone;
