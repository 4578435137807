import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { withDataStyle, withoutDataStyle } from "../../lib/styleConstants";
import theme from "../../theme";
import { quoteDataType } from "../../lib/constants";

const styles = {
  mbStyle: {
    marginTop: "auto",
  },
  dataBox: {
    display: "flex",
    paddingTop: "2em",
    justifyContent: "flex-end",
    width: "100%",
  },
  dataBoxFlex: {
    alignItems: "flex-end",
  },
  withData: {
    fontSize: { xs: "86px", md: "96px" },
    lineHeight: 1,
    color: theme.palette.black.darkSeaBlack,
    opacity: 1,
    fontWeight: 500,
  },

  opacity: {
    opacity: 0.1,
  },
};

const DashboardData = ({
  data = undefined,
  collectedDataFlag = false,
  inGbFlag = "MB",
  unitType = undefined,
  showTextOnly = false,
}) => {
  return (
    <>
      <Box sx={{ ...styles.dataBox, ...styles.dataBoxFlex }}>
        {showTextOnly ? (
          <Typography
            variant="h1"
            sx={
              data ? styles.withData : { ...styles.withData, ...styles.opacity }
            }
          >
            {data}
          </Typography>
        ) : (
          <Typography
            variant="h1"
            sx={
              data ? styles.withData : { ...styles.withData, ...styles.opacity }
            }
          >
            {unitType === quoteDataType.areaSize
              ? data
              : Math.ceil(data) || "-"}
          </Typography>
        )}

        {collectedDataFlag && (
          <Typography
            variant="h5"
            sx={{
              ...styles.mbStyle,
              paddingBottom: "8px",
              color: data ? withDataStyle : withoutDataStyle,
            }}
          >
            {inGbFlag}
          </Typography>
        )}
      </Box>
    </>
  );
};

DashboardData.propTypes = {
  data: PropTypes.object.isRequired,
  inGbFlag: PropTypes.string,
  collectedDataFlag: PropTypes.bool,
  unitType: PropTypes.string,
  showTextOnly: PropTypes.bool,
};

// DashboardData.defaultProps = {
//   inGbFlag: "MB",
//   collectedDataFlag: false,
//   unitType: undefined,
//   showTextOnly: false,
// };

export default DashboardData;
