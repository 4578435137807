import firebase from "src/firebase";
import { useCallback, useEffect, useRef } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getClientOrdersList } from "src/services/client";
import { getUser } from "../services/auth";
import { paginationAction } from "src/lib/constants";

export const useGetClientOrdersList = (status, subStatus, enterpriseId) => {
  let initalSortBy = { sortBy: "dateCreated", sortValue: "desc" };
  const [orders, setOrders] = useStateIfMounted([]);
  const [allOrders, setAllOrders] = useStateIfMounted([]);
  const [lastLoadedOrder] = useStateIfMounted(null);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [sortBy, setSortBy] = useStateIfMounted(initalSortBy);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [isLastPage, setIsLastPage] = useStateIfMounted(false);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const lastLoadedOrderRef = useRef(lastLoadedOrder);
  const currentPageRef = useRef(currentPage);

  const resetDataFcn = useCallback(() => {
    setCurrentPage(1);
    setIsLastPage(false);
    setDataLoadedTill(0);
    setAllOrders([]);
    setLastPageNumber(0);
    lastLoadedOrderRef.current = null;
  }, [
    setAllOrders,
    setCurrentPage,
    setDataLoadedTill,
    setIsLastPage,
    setLastPageNumber,
  ]);

  const valueChangeHandler = useCallback(
    (value, field) => {
      resetDataFcn();
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter, [field]: value }; // Create a new filter object
        return newFilter;
      });
    },
    [resetDataFcn, setFilter]
  );

  const fetchMissions = useCallback(async () => {
    const user = getUser("user");
    setIsLoading(true);

    if (user?.isAnonymous) {
      setIsLoading(false);
      currentPageRef.current = 1;
      setIsLastPage(true);
    } else {
      if (dataloadedTill >= currentPage) {
        let prevPageMissionData = allOrders.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );

        setOrders(prevPageMissionData);
        // setPreviousPage(currentPage - 1);
        setIsLoading(false);
      } else {
        getClientOrdersList(
          status,
          subStatus,
          lastLoadedOrderRef.current,
          limit,
          filter,
          sortBy,
          enterpriseId
        )
          .then((data) => {
            const { missions, isLast, lastLoaded } = data;
            setOrders(missions);
            setAllOrders((prevMissions) => {
              const updatedMissions = missions.filter(
                (newMission) =>
                  !prevMissions.some((mission) => mission.id === newMission.id)
              );
              return [...prevMissions, ...updatedMissions];
            });
            isLast && setLastPageNumber(currentPage);
            setIsLastPage(isLast);
            lastLoadedOrderRef.current = lastLoaded;
            setDataLoadedTill(currentPageRef.current);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("Error", err.message);
            setIsLoading(false);
          });
      }
    }
  }, [
    setIsLoading,
    setIsLastPage,
    dataloadedTill,
    currentPage,
    allOrders,
    limit,
    setOrders,
    status,
    subStatus,
    filter,
    sortBy,
    enterpriseId,
    setAllOrders,
    setLastPageNumber,
    setDataLoadedTill,
  ]);

  useEffect(() => {
    if (!firebase) {
      return;
    }
    fetchMissions();
  }, [fetchMissions]);

  const limitHandler = useCallback(
    (value) => {
      setLimit(value);
      resetDataFcn();
    },
    [resetDataFcn, setLimit]
  );

  const sortByHandler = useCallback(
    (sort) => {
      resetDataFcn();
      if (sort === sortBy.sortBy) {
        const order = sortBy.sortValue === "asc" ? "desc" : "asc";
        setSortBy({ sortBy: sort, sortValue: order });
      } else {
        setSortBy({ sortBy: sort, sortValue: "asc" });
      }
    },
    [resetDataFcn, setSortBy, sortBy.sortBy, sortBy.sortValue]
  );

  const paginationBtnClick = useCallback(
    (type) => {
      setCurrentPage((prevPage) => {
        const nextPage =
          type === paginationAction.next ? prevPage + 1 : prevPage - 1;

        // Update currentPageRef for manual reference use
        currentPageRef.current = nextPage;

        // Trigger re-render by updating state
        return nextPage;
      });
    },
    [setCurrentPage]
  );

  return {
    orders,
    isLoading,
    limit,
    limitHandler,
    sortByHandler,
    paginationBtnClick,
    isLastPage,
    valueChangeHandler,
    currentPage,
    lastPageNumber,
  };
};
