import { invitePilotToMission } from "../../services/missions";
import firebase from "src/firebase";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import React, { useState } from "react";
import AppButton from "../global/elements/AppButton";
import { useStateIfMounted } from "use-state-if-mounted";
import InvitePilotList from "../app/admin/InvitePilotList";
import UniversalLoader from "../global/elements/UniversalLoader";
import { useSnackbar } from "notistack";

const styles = {
  textLabel: {
    color: theme.palette.black.coolGrey,
    fontSize: "16px",
    fontWeight: "500",
    padding: "0 20px 10px 20px",
  },
  header: {
    color: theme.palette.black.coolGrey,
    padding: "15px 20px 15px 20px",
  },
  weitht5: {
    fontWeight: "500",
  },
  contentBox: {
    color: theme.palette.black.coolGrey,
    padding: "10px 20px 25px 20px",
    flex: 1,
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row", rowGap: "1em" },
    justifyContent: { xs: "flex-start", sm: "space-between" },
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1em",
    margin: { xs: "0 0 1em 0", sm: "0 1em 1em 0" },
  },
  fullScreenLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
};

export default function AlertDialog({
  Country = undefined,
  handlePopupClose = () => {},
  refreshMission = () => {},
  InvitePopupOpen = false,
  mission = {},
}) {
  const [selectedPilots, setSelectedPilots] = useStateIfMounted([]);
  const [allPilots, setAllPilots] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useState(true);
  const [invitingInProgress, setInvitingInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleLoadingState = (loadingState) => {
    setIsLoading(loadingState);
  };

  async function inviteAllPilots() {
    setInvitingInProgress(true);
    let res = null;

    try {
      if (allPilots?.length) {
        res = await Promise.all(
          allPilots?.map(async (pilot) => {
            await invitePilotToMission(firebase, pilot?.id, mission);
          })
        );
      } else {
        res = await Promise.all(
          selectedPilots?.map(async (pilot) => {
            await invitePilotToMission(firebase, pilot?.id, mission);
          })
        );
      }

      if (res) {
        handlePopupClose();
        refreshMission();
        enqueueSnackbar("Pilots Invited Successfully", { variant: "success" });
        return res;
      }
    } catch (error) {
      console.error("Error inviting pilots:", error);
    } finally {
      setInvitingInProgress(false);
    }
  }

  const handleClose = () => {
    handlePopupClose();
    setIsLoading(true);
  };

  return (
    <Box>
      <Dialog
        open={InvitePopupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title" sx={styles.header}>
          <Typography variant="body1" sx={styles.weitht5}>
            Invite drone operators
          </Typography>
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" sx={styles.contentBox}>
          <InvitePilotList
            country={Country}
            inviteFromCountry={true}
            selected={selectedPilots}
            setSelected={setSelectedPilots}
            setAllPilots={setAllPilots}
            mission={mission}
            onLoadingStateChange={handleLoadingState}
            globalInvitingState={invitingInProgress}
          />
        </DialogContentText>
        <DialogActions sx={styles.buttonContainer}>
          <Box sx={{ padding: "0px 0px 0px 20px" }}>
            {selectedPilots?.length > 0 && (
              <Typography variant="body1" sx={styles.weitht5}>
                You have selected {allPilots?.length || selectedPilots?.length}{" "}
                drone operator(s). Are you sure you want to invite all of them?
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "1em" }}>
            <AppButton
              look="inverted"
              noIcon
              onClick={() => {
                handlePopupClose();
                refreshMission();
              }}
              label="Close"
              isDisabled={invitingInProgress}
            />
            <AppButton
              onClick={() => inviteAllPilots()}
              marginLeft
              isDisabled={selectedPilots?.length > 0 ? false : true}
              look={selectedPilots?.length > 0 ? "black" : "blackDisabled"}
              label="Invite drone operators"
              submittingState={invitingInProgress}
            />
          </Box>
        </DialogActions>
        {isLoading && (
          <Box
            sx={{
              ...styles.fullScreenLoader,
              backgroundColor: "white",
            }}
          >
            <UniversalLoader isFromInvite={true} />
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
AlertDialog.propTypes = {
  Country: PropTypes.string,
  missionID: PropTypes.string,
  handlePopupClose: PropTypes.func,
  refreshMission: PropTypes.func,
  InvitePopupOpen: PropTypes.bool,
  mission: PropTypes.object,
};
