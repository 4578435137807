import React, { useContext, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import MissionContext from "./MissionContext";
import AppButton from "../../../../global/elements/AppButton";
import UserNextActionsBanner from "./UserNextActionsBanner";
import theme from "src/theme";
import {
  missionStatus,
  paymentStatus,
  missionTypeConstant,
  ClientNextAction,
  projectStatus,
  quoteStatus,
  packageStatusConstants,
} from "../../../../../lib/constants";
import { getTabIndex } from "../../../../../lib/helpers";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

const ClientNextActions = ({
  openCancelOrderPopup = () => {},
  packageLength = undefined,
  pilotPackages = [],
  projectDetails = undefined,
  lastQuoteStatus = undefined,
}) => {
  const actionData = useContext(MissionContext);
  const { mission } = actionData || {};
  const latestPackageIndex = packageLength - 1;
  const getBannerState = useCallback(() => {
    let state = {
      actionText: "",
      actionSubText: "",
      tabNumber: 2,
      background: undefined,
      bigText: false,
      button1: null,
      button2: null,
    };
    if (mission) {
      switch (mission.status) {
        case missionStatus.initialClientOrder:
          state = {
            ...state,
            actionText: "Awaiting Globhe Review!!",
            bigText: true,
            actionSubText: ClientNextAction.underReview,
          };
          break;

        case missionStatus.confirmedMission:
          state = {
            ...state,
            actionText: "Order Confirmed !!",
            bigText: true,
            actionSubText: ClientNextAction.confirmOrder,
          };
          break;

        case missionStatus.biddingOngoing:
        case missionStatus.pilotInvitedForBidding:
          state = {
            ...state,
            actionText: "Bidding Ongoing!!",
            actionSubText: ClientNextAction.biddingOngoing,
            bigText: true,
          };
          break;

        case missionStatus.biddingEnded:
          state = {
            ...state,
            actionText: "Bidding Ended !!",
            actionSubText: ClientNextAction.biddingEnded,
          };
          break;

        case missionStatus.pilotSelected:
          state = {
            ...state,
            actionText: "Drone Operator Selected !!",
            actionSubText: ClientNextAction.operatorSelected,
            bigText: true,
          };
          break;

        case missionStatus.quoteSentToClient:
        case missionStatus.quoteReissuedToClient:
          state = {
            ...state,
            actionText: "Your quote is ready !",
            actionSubText: ClientNextAction.quoteReady,
            button1: (
              <AppButton
                look="black"
                label="Quote Ready"
                onClick={() => actionData?.handleTabChange("e", 1)}
                fullWidth
                noIcon
              />
            ),
          };
          break;

        case missionStatus.quoteDeclinedByClient:
          state = {
            ...state,
            actionText: ClientNextAction.newQuote,
            bigText: true,
          };
          break;

        case missionStatus.quoteAcceptedByClient:
          state = {
            ...state,
            actionText: ClientNextAction.quoteAccepted,
            bigText: true,
          };
          break;

        case missionStatus.pilotAssigned:
          state = {
            ...state,
            actionText: ClientNextAction.operatorAssigned,
            bigText: true,
            actionSubText: ClientNextAction.operatorSubAssigned,
          };
          break;

        case missionStatus.missionNotFeasible:
          state = {
            ...state,
            actionText: "Location is not accessible",
            background: theme.palette.status.errorLight,
            button1: (
              <AppButton
                look="black"
                label="Review Location"
                onClick={() =>
                  actionData?.setPageMode(actionData?.PageModes.EDIT_MISSION)
                }
                fullWidth
                noIcon
              />
            ),
            button2: (
              <AppButton
                look="inverted"
                label="Cancel Order"
                onClick={openCancelOrderPopup}
                fullWidth
                noIcon
              />
            ),
          };
          break;

        case missionStatus.flyingDateSet:
          state = {
            ...state,
            actionText: actionData?.missionPlanning?.data?.currentFlightDate
              ? `Flying date set: ${DateTime.fromJSDate(
                  new Date(actionData?.missionPlanning?.data?.currentFlightDate)
                ).toFormat("dd/MM/yyyy")}`
              : "Flying date not set yet",
            bigText: true,
            actionSubText: ClientNextAction.flyingDateSet,
          };
          break;
        case missionStatus.rescheduled:
          state = {
            ...state,
            actionText: actionData?.missionPlanning?.data?.currentFlightDate
              ? `Mission Rescheduled to: ${DateTime.fromJSDate(
                  new Date(actionData?.missionPlanning?.data?.currentFlightDate)
                ).toFormat("dd/MM/yyyy")}`
              : "Rescheduled date not set yet",
            actionSubText: ClientNextAction.rescheduled,
            bigText: true,
          };
          break;

        case missionStatus.dataProcessing:
          state = {
            ...state,
            actionText: ClientNextAction.dataProcessing,
            bigText: true,
          };
          break;

        case missionStatus.dataUploading:
          state = {
            ...state,
            actionText: "Data collected!",
            actionSubText: ClientNextAction.dataUploading,
          };
          break;

        case missionStatus.sentForReviewToGlobhe:
        case missionStatus.dataRejectedByClient:
          state = {
            ...state,
            actionText: ClientNextAction.dataReview,
            bigText: true,
          };
          break;

        case missionStatus.pilotPackageRejected:
          state = {
            ...state,
            actionText: ClientNextAction.dataRejectedByGlobhe,
            background: theme.palette.status.errorLight,
            actionSubText: ClientNextAction.dataRejected,
          };
          break;

        case missionStatus.sentForReviewToClient: {
          const tabNumber =
            packageLength + getTabIndex({ missionType: mission?.missionType });
          if (
            pilotPackages[latestPackageIndex]?.packageStatusByClient ===
            packageStatusConstants.requestedChanges
          ) {
            state = {
              ...state,
              actionText: "Changes Requested",
              bigText: true,
              actionSubText:
                ClientNextAction.requestedChanges +
                pilotPackages[latestPackageIndex]?.requestedReason,
            };
            break;
          }
          state = {
            ...state,
            actionText: ClientNextAction.passedCheck,
            actionSubText: ClientNextAction.reviewData,
            tabNumber,
            button1: (
              <AppButton
                look="black"
                label="Review Data"
                onClick={() => actionData?.handleTabChange("e", tabNumber - 1)}
                fullWidth
                noIcon
              />
            ),
          };
          break;
        }

        case missionStatus.dataAcceptedByClient:
          state = {
            ...state,
            actionText: "Data Accepted !!",
            actionSubText: ClientNextAction.dataAccepted,
          };
          break;

        case missionStatus.completed:
          if (
            mission.paymentStatusClient === paymentStatus.unpaid &&
            mission.missionType === missionTypeConstant.clientOrder
          ) {
            state = {
              ...state,
              actionText: ClientNextAction.paymentOverdue,
              actionSubText: ClientNextAction.paymnentSubText,
              background: theme.palette.status.warningLight,
              button1: (
                <AppButton
                  look="black"
                  label="View Invoice"
                  onClick={() => actionData?.handleTabChange("e", 1)}
                  fullWidth
                  noIcon
                />
              ),
            };
          } else {
            const tabIndex =
              packageLength +
              getTabIndex({ missionType: mission?.missionType });
            state = {
              ...state,
              actionText: "Your data is ready !",
              actionSubText: ClientNextAction.downloadData,
              button1: (
                <AppButton
                  look="black"
                  label="Data is available"
                  onClick={() => actionData?.handleTabChange("e", tabIndex)}
                  fullWidth
                  noIcon
                />
              ),
            };
          }
          break;

        case missionStatus.archive:
          state = {
            ...state,
            actionText: ClientNextAction.archived,
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        case missionStatus.cancelled:
          state = {
            ...state,
            actionText: ClientNextAction.cancelled,
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        case missionStatus.rejected:
          state = {
            ...state,
            actionText: ClientNextAction.rejected,
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        case missionStatus.draft:
          state = {
            ...state,
            actionText: "This is a Draft Order.",
            actionSubText:
              "This order is part of a draft project. You haven't submitted the project yet.",
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        case missionStatus.initialProjectOrder:
          state = {
            ...state,
            actionText: "Initial Project Order",
            actionSubText:
              "The project that this order is part of is currently being reviewed by GLOBHE.",
          };
          break;

        default:
          state = null;
          break;
      }
      return state;
    }

    if (projectDetails?.status) {
      const lastStatus = lastQuoteStatus;
      switch (projectDetails.status) {
        case projectStatus.initial:
          if (lastStatus === quoteStatus.declined) {
            state = {
              ...state,
              actionText: "Quote Declined!",
              actionSubText: ClientNextAction.declineQuote,
              background: theme.palette.primary.pistachio,
            };
          } else if (lastStatus === quoteStatus.quoteSent) {
            state = {
              ...state,
              actionText: "Quote Available!",
              actionSubText: ClientNextAction.reviewQuote,
              background: theme.palette.primary.pistachio,
            };
          } else if (lastStatus === quoteStatus.deleted) {
            state = {
              ...state,
              actionText: "Quote Deleted!",
              actionSubText: ClientNextAction.quoteDeleted,
              background: theme.palette.primary.pistachio,
            };
          } else {
            state = {
              ...state,
              actionText: "Initial Project Order",
              actionSubText: ClientNextAction.initialProject,
              background: theme.palette.primary.pistachio,
            };
          }
          break;

        case projectStatus.completed:
          state = {
            ...state,
            actionText: "Your Data is ready for Download",
            actionSubText: ClientNextAction.downloadProject,
            background: theme.palette.primary.main,
            bigText: true,
          };
          break;

        case projectStatus.active:
          state = {
            ...state,
            actionText: "Active Project!",
            actionSubText: ClientNextAction.activeProject,
            background: theme.palette.primary.pistachio,
            bigText: true,
          };
          break;

        case projectStatus.draft:
          state = {
            ...state,
            actionText: "This order is not yet submitted",
            actionSubText:
              "Your project is currently in draft mode and is not yet submitted. Feel free to make any adjustments or add more sites as needed. Once you’re ready to proceed, simply submit your order below and you’ll receive a quote within 24 hours",
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        case projectStatus.draftClient:
          state = {
            ...state,
            actionText: "Pending Submission",
            actionSubText:
              "Here you find your unsubmitted multi-location or recurrent orders. They are saved here and are not submitted yet. Make sure you have inserted all your sites and filled all your details. Once you are ready, go to the order and click on 'Submit order'",
            background: theme.palette.status.warningLight,
            bigText: true,
          };
          break;

        default:
          break;
      }
      return state;
    }
  }, [
    mission,
    projectDetails?.status,
    openCancelOrderPopup,
    actionData,
    packageLength,
    pilotPackages,
    latestPackageIndex,
    lastQuoteStatus,
  ]);

  const bannerState = useMemo(() => getBannerState(), [getBannerState]);

  if (!bannerState || !bannerState.actionText) return null;

  return (
    <Box>
      <UserNextActionsBanner
        actionText={bannerState.actionText}
        actionSubText={bannerState.actionSubText}
        tabNumber={bannerState.tabNumber}
        background={bannerState.background}
        button1={bannerState.button1}
        button2={bannerState.button2}
        bigText={bannerState.bigText}
      />
    </Box>
  );
};

ClientNextActions.propTypes = {
  openCancelOrderPopup: PropTypes.func.isRequired,
  packageLength: PropTypes.number.isRequired,
  projectDetails: PropTypes.object.isRequired,
  pilotPackages: PropTypes.array.isRequired,
  lastQuoteStatus: PropTypes.string.isRequired,
};

export default React.memo(ClientNextActions);
