import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Avatar } from "@mui/material";
import PropTypes from "prop-types";
import firebase from "src/firebase";
import { Form, Formik } from "formik";
import { preventEnterSubmit } from "src/lib/form-helpers";
import Dropzone from "react-dropzone";
import AppButton from "../../../global/elements/AppButton";
import { uploadProfileImageClient } from "src/services/client";
import * as Yup from "yup";
import theme from "../../../../theme";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import countries from "src/lib/countries";
import {
  editClientProfile,
  getClientById,
  updateHubSpotContact,
} from "../../../../services/client";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { useSnackbar } from "notistack";
import {
  indudstryList,
  EUCountriesWithCommonVATrule,
  defaultCurrency,
  currencyList,
} from "../../../../lib/constants";
import { navigate } from "gatsby";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import FieldTitle from "../../../global/elements/FieldTitle";
import { guestUserSignInRoute } from "../../../../lib/constants";
import { getUser } from "../../../../services/auth";
import Dialogue from "../../../global/Dialogue";
import GuestUserDialogueBody from "../../AnonymousUser";
import { submitCancelBox } from "../../../../lib/styleConstants";
import UploadButton from "../../../global/elements/UploadButton";
import AppContext from "../../../../contexts/AppContext";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const styles = {
  root: {
    marginTop: "4vh",
  },
  profileImage: {
    width: { xs: "75px", sm: "100px", lg: "140px" },
    height: { xs: "75px", sm: "100px", lg: "140px" },
    margin: "auto",
    marginLeft: { xs: 0, sm: "auto" },
    "& img": {
      padding: { xs: "3px", sm: "4px", lg: "7px" },
      borderRadius: "50%",
    },
  },
  defaultImage: {
    width: { xs: "75px", sm: "100px", lg: "140px" },
    height: { xs: "75px", sm: "100px", lg: "140px" },
    padding: { xs: "3px", sm: "4px", lg: "7px" },
    margin: "auto",
    backgroundColor: "none",
    borderRadius: "50%",
    position: "relative",
    "& svg": {
      fontSize: { xs: "65px", sm: "80px", lg: "120px" },
    },
  },

  profileIcon: {
    fontSize: { xs: "65px", sm: "80px", lg: "120px" },
    position: "absolute",
    top: {
      xs: "calc(50% - 32px)",
      sm: "calc(50% - 40px)",
      lg: "calc(50% - 60px)",
    },
    left: {
      xs: "calc(50% - 32.5px)",
      sm: "calc(50% - 40px)",
      lg: "calc(50% - 60px)",
    },
    backgroundColor: theme.palette.grey.lightWarmGrey,
    padding: { xs: "15px", sm: "20px", lg: "30px" },
    borderRadius: "50%",
  },
  profilePhotoContainer: {
    textAlign: "center",
    marginLeft: { xs: 0, sm: "auto" },
    height: { sm: "150px", lg: "200px" },
    borderBottom: {
      xs: `1px solid ${theme.palette.primary.main}`,
      sm: "none",
    },
    paddingBottom: { xs: "2vh" },
    marginBottom: { xs: "2vh" },
  },
  photoContainer: {
    marginBottom: { lg: "20px" },
  },
  inputLabel: {
    marginBottom: "5px",
    fontWeight: 400,
    color: theme.palette.grey.deepWarmGrey,
  },
  inputField: {
    border: "none",
    marginBottom: "20px",
    marginRight: "15px",
    "& fieldset": {
      borderStyle: "groove",
      border: `1px solid ${theme.palette.black.darkSeaBlack}`,
    },
    "& label.Mui-focused": {
      color: theme.palette.black.darkSeaBlack,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.black.darkSeaBlack,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.black.darkSeaBlack,
      },
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.status.errorDark,
    },
  },
  dividerColor: {
    bgcolor: theme.palette.black.darkSeaBlack,
    width: "100%",
    margin: "16px 0px",
  },
  saveCancelBox: {
    marginTop: "20px",
    justifyContent: "flex-end",
  },
  inputFieldPhone: {
    "& .PhoneInputInput": {
      padding: "17px !important",
    },
    // padding: "20px !important",
  },
};

const EditClientProfile = ({
  clientId = undefined,
  showProfileImg = true,
  onCancel = undefined,
  onSubmit = undefined,
  submitButtonText = "Save",
  submitButtonLook = "black",
}) => {
  const appContext = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState({
    profileImageURL: "",
    name: "",
    location: "",
    companyName: "",
    jobTitle: "",
    industry: "",
    phone: "",
    companyAddress: "",
    postalCode: "",
    city: "",
    registrationNumber: "",
    vatNumber: "",
    invoicingEmail: "",
  });
  const [userData, setUserData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // Dialogue
  const [showDialogue, setShowDialogue] = useState(false);
  // Dialogue

  useEffect(() => {
    setLoading(true);
    const user = getUser("user");
    setUserData(user);
    async function fetchData() {
      try {
        const clientData = await getClientById(firebase, clientId);
        if (clientData) {
          setClient(clientData);
          setProfileImage(clientData?.profileImageURL || null);
        }
        setLoading(false);
      } catch (err) {
        console.log("error", err);
        setLoading(false);
      }
    }
    fetchData();
  }, [clientId]);

  const validationSchema = Yup.object().shape(
    userData?.isAnonymous
      ? {}
      : {
          profileImageURL: Yup.string(),
          name: Yup.string().required("First & Last name is required"),
          location: Yup.string().required("Country is required"),
          companyName: Yup.string().required("Organization is required"),
          jobTitle: Yup.string().nullable(),
          industry: Yup.string().nullable(),
          phone: Yup.string()
            .matches(/^\+?[0-9\s-]{7,15}$/, "Enter a valid phone number")
            .nullable()
            .required("Phone number is required"),
          companyAddress: Yup.string().required("Company  address is required"),
          postalCode: Yup.string().required("Postal Code is required"),
          city: Yup.string().required("City is required"),
          registrationNumber: Yup.string().required(
            "Company registration number (CRN) / Employer Identification number (EIN) is required"
          ),
          invoicingEmail: Yup.string().required("Invoicing email is required"),

          vatNumber: Yup.string().when("location", {
            is: (location) => EUCountriesWithCommonVATrule?.includes(location),
            then: Yup.string().required("Vat number is required"),
            otherwise: Yup.string(),
          }),
        }
  );

  const formInitialValues = {
    profileImageURL: client?.profileImageURL || "",
    name: client?.name || "",
    location: client?.location || "",
    companyName: client?.companyName || "",
    jobTitle: client?.jobTitle || "",
    industry: client?.industry || "",
    phone: client?.phone || "",
    hubspotId: client?.hubspotId || null,
    companyAddress: client?.companyAddress || "",
    postalCode: client?.postalCode || "",
    city: client?.city || "",
    registrationNumber: client?.registrationNumber || "",
    vatNumber: client?.vatNumber || "",
    invoicingEmail: client?.invoicingEmail || "",
  };

  if (!client?.enterPriseId) {
    formInitialValues.currency = client?.currency || defaultCurrency;
  }

  const formSubmitHandler = async (values, { setSubmitting }) => {
    if (userData?.isAnonymous) {
      setShowDialogue(true);
      return;
    }

    try {
      const status = await editClientProfile(firebase, clientId, values);
      if (status?.status === "Success") {
        let clientObj = { id: clientId, ...values };
        updateHubSpotContact(clientObj);
        enqueueSnackbar("Profile updated successfully!", {
          variant: "success",
        });

        if (onSubmit) {
          onSubmit(clientObj);
        } else {
          navigate(`/app/client/my-profile/${clientId}`);
          if (appContext?.userData) {
            appContext?.setUserData({ ...appContext?.userData, ...clientObj });
          }
        }
      } else {
        enqueueSnackbar("There was a problem updating your profile!", {
          variant: "error",
        });
      }
    } catch (e) {
      enqueueSnackbar("There was a problem updating your profile!", {
        variant: "error",
      });
      console.log("Error", e);
    } finally {
      setSubmitting(false); // Set isSubmitting to false after the submission
    }
  };

  const onProfileImageDropHandler = (acceptedFiles, setFieldValue) => {
    if (acceptedFiles) {
      uploadProfileImageClient(firebase, acceptedFiles[0], clientId)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          setFieldValue("profileImageURL", downloadURL);
          setProfileImage(downloadURL);
        })
        .catch((err) => console.log(err));
    } else {
      console.log("incorrect image");
    }
  };

  return (
    <Box sx={styles.root}>
      {!loading ? (
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            return (
              <Form id="clientForm" onKeyDown={preventEnterSubmit}>
                <Grid container>
                  {showProfileImg && (
                    <Grid
                      container
                      xs={12}
                      sm={4}
                      lg={3}
                      sx={styles.profilePhotoContainer}
                    >
                      <Grid item xs={3} sm={12} sx={styles.photoContainer}>
                        {profileImage ? (
                          <Avatar
                            src={values?.profileImageURL}
                            sx={styles.profileImage}
                          />
                        ) : (
                          <Box sx={styles?.defaultImage}>
                            <AddOutlinedIcon
                              sx={styles.profileIcon}
                              fontSize="large"
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sm={12}
                        sx={{ textAlign: { xs: "initial", sm: "inherit" } }}
                      >
                        <Box>
                          <Dropzone
                            accept="image/jpeg, image/jpg, image/png"
                            onDrop={(acceptedFiles) =>
                              onProfileImageDropHandler(
                                acceptedFiles,
                                setFieldValue
                              )
                            }
                            maxSize={5000000}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input
                                  {...getInputProps()}
                                  id="profileImageURL"
                                />
                                <UploadButton
                                  text="Add my profile image"
                                  onClick={() =>
                                    setFieldValue("profileImageURL", null)
                                  }
                                />
                              </div>
                            )}
                          </Dropzone>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    xs={12}
                    sm={showProfileImg ? 8 : 12}
                    lg={showProfileImg ? 9 : 12}
                    sx={{
                      borderBottom: {
                        xs: `1px solid ${theme.palette.black.coolGrey}`,
                      },
                      paddingBottom: { xs: "2vh", sm: "none" },
                    }}
                  >
                    <Grid item xs={12}>
                      <FieldTitle
                        title="Your Name"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"name"}
                          placeholder="First & Last Name"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldTitle
                        title="Organization"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"companyName"}
                          placeholder="Organization Name"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldTitle title="Job Title" variant="body2" />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"jobTitle"}
                          placeholder="Job Title"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldTitle
                        requiredFlag
                        title="Phone Number"
                        variant="body2"
                      />
                      <Box sx={styles.inputFieldPhone}>
                        {/* <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"phone"}
                          placeholder="Phone Number"
                          disabled={false}
                          type="text"
                          required
                        /> */}
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={values.phone}
                          onChange={(e) => setFieldValue("phone", e)}
                          // style={styles.inputFieldPhone}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldTitle title="Industry" variant="body2" />
                      <Box sx={styles.inputField}>
                        <AutoCompleteInput
                          options={indudstryList}
                          name="industry"
                          placeholder={"Industry"}
                          additionalStyle={styles.autocompletStyle}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FieldTitle
                        title="Country"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <AutoCompleteInput
                          options={countries}
                          name="location"
                          placeholder={"Country"}
                          additionalStyle={styles.autocompletStyle}
                        />
                      </Box>
                    </Grid>

                    {/* -----------------Invoicing Details---------------- */}

                    <Grid item xs={6}>
                      <FieldTitle
                        title="Company Address"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"companyAddress"}
                          placeholder="Company Address"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <FieldTitle
                        title="Postal Code"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"postalCode"}
                          placeholder="Postal Code"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <FieldTitle title="City" variant="body2" requiredFlag />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"city"}
                          placeholder="City"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <FieldTitle
                        title="Company registration number (CRN) / Employer Identification number (EIN)"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"registrationNumber"}
                          placeholder="Company registration number (CRN) / Employer Identification number (EIN)"
                          disabled={false}
                          type="text"
                          required
                        />
                      </Box>
                    </Grid>

                    {!client?.enterPriseId && (
                      <Grid item xs={6}>
                        <FieldTitle
                          title="Currency"
                          variant="body2"
                          requiredFlag
                        />
                        <Box sx={styles.inputField}>
                          <AutoCompleteInput
                            options={currencyList}
                            name="currency"
                            placeholder={"Currency"}
                            additionalStyle={styles.autocompletStyle}
                          />
                        </Box>
                      </Grid>
                    )}

                    {EUCountriesWithCommonVATrule?.includes(
                      values.location
                    ) && (
                      <Grid item xs={6}>
                        <FieldTitle
                          title="Vat Number"
                          variant="body2"
                          requiredFlag
                        />
                        <Box sx={styles.inputField}>
                          <TextFieldInput
                            setFieldValue={setFieldValue}
                            values={values}
                            name={"vatNumber"}
                            placeholder="Vat Number"
                            disabled={false}
                            type="text"
                            required
                          />
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={6}>
                      <FieldTitle
                        title="Invoicing Email"
                        variant="body2"
                        requiredFlag
                      />
                      <Box sx={styles.inputField}>
                        <TextFieldInput
                          setFieldValue={setFieldValue}
                          values={values}
                          name={"invoicingEmail"}
                          placeholder="Invoicing Email"
                          disabled={false}
                          type="email"
                          required
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ ...submitCancelBox, ...styles.saveCancelBox }}>
                    <AppButton
                      look="inverted"
                      label="Cancel"
                      noIcon
                      url={`/app/client/my-profile/${clientId}`}
                      onClick={onCancel}
                      internalLink={onCancel ? false : true}
                    />
                    <AppButton
                      type="submit"
                      form="clientForm"
                      look={submitButtonLook}
                      label={submitButtonText}
                      marginLeft
                      submittingState={isSubmitting}
                    />
                  </Box>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Box sx={{ marginTop: { xs: "30%", sm: "20%" } }}>
          <UniversalLoader />
        </Box>
      )}
      {showDialogue && (
        <Dialogue
          showDialogue={showDialogue}
          setShowDialogue={setShowDialogue}
          DialogueBody={GuestUserDialogueBody()}
          showCancelButton={true}
          cancelButtonText={"Cancel"}
          sumitButtonText={"Sign in"}
          onSubmit={() => navigate(guestUserSignInRoute)}
          submitButtonLook={"primary"}
          bottomDeviderLine={true}
        />
      )}
    </Box>
  );
};

EditClientProfile.propTypes = {
  clientId: PropTypes.string.isRequired,
  showProfileImg: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  submitButtonLook: PropTypes.string,
};

export default EditClientProfile;
