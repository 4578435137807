import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { getUser } from "../../../../services/auth";
import {
  ClientPage,
  FilterFieldsGroupedOrder,
  colorStatus,
  missionStatus,
  users,
} from "../../../../lib/constants";
import { useGetGroupOrderList } from "../../../../hooks/useGetGroupOrderList";
import FilterOrders from "./FilterOrders";
import GlobalTable from "../../admin/GlobalTable";
import { navigate } from "gatsby";
import StatusBadge from "../../../global/elements/StatusBadge";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { listLoader, listLoaderParent } from "../../../../lib/styleConstants";
import { getCurrentDate, getInitialFilters } from "../../../../lib/helpers";

const GroupOrderTable = ({ columns = {} }) => {
  const currentUser = getUser(users.user);

  const [
    filteredOrderList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllOrder,
    setDataLoadedTill,
    setLastLoadedOrder,
    pageChanged,
    setPageChanged,
  ] = useGetGroupOrderList();

  useEffect(() => {
    valueChangeHandler("InitialLoad", "InitialLoad", FilterFieldsGroupedOrder);
  }, []);

  const valueChangeHandler = (value, field, filterFields) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    let filterDataNew = filter;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filter).filter(
        (fld) => filterFields.indexOf(fld) == -1
      );
      changedField.forEach((chgFld) => {
        filterDataNew[chgFld] = "";
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      const filterObj = getInitialFilters(filterFields);
      let filterDataSaved = filterObj?.filters || {};
      if (filterDataSaved) {
        filterDataNew = filterDataSaved;
      }
    } else {
      filterDataNew[field] = value;
    }
    localStorage.setItem("filter", JSON.stringify(filterDataNew));
    let applyFilterOn = {};
    filterFields?.forEach((opt) => {
      if (filterDataNew[opt]) {
        applyFilterOn[opt] = filterDataNew[opt];
      }
    });
    setPreviousPage(0);
    setDataLoadedTill(0);
    setPagedata(pageDataNew);
    setAllOrder([]);
    setLastLoadedOrder(null);
    // setLoadHandler(!loadHandler);
    setFilter(applyFilterOn);
  };

  const goToPageFcn = (value) => {
    navigate(`/app/client/orderDetails/${value.id}`);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setLimit(value);
    setDataLoadedTill(0);
    setAllOrder([]);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setLastLoadedOrder(null);
    setPagedata(pageDataNew);
  };

  const valueConvertHandler = (type, value) => {
    switch (type) {
      case "status":
        return (
          <Typography variant="body1">
            {(value["status"] == missionStatus.unassigned ||
              value["status"] == missionStatus.confirmedMission) && (
              <StatusBadge status={colorStatus.red} name="Pending pilot" />
            )}
            {value["status"] == missionStatus.active && (
              <StatusBadge status={colorStatus.green} name="Active" />
            )}
            {(value["status"] == missionStatus.initialClientOrder ||
              value["status"] == missionStatus.initialProjectOrder) && (
              <StatusBadge status={colorStatus.red} name="Reviewing" />
            )}
            {value["status"] == missionStatus.draft && (
              <StatusBadge status={colorStatus.neutral} name="Draft" />
            )}
            {value["status"] == missionStatus.completed && (
              <StatusBadge status={colorStatus.green} name="Completed" />
            )}
            {value["status"] == missionStatus.cancelled && (
              <StatusBadge status={colorStatus.grey} name="Cancelled" />
            )}
            {value["status"] == missionStatus.rejected && (
              <StatusBadge status={colorStatus.grey} name="Rejected" />
            )}
            {value["status"] == missionStatus.archive && (
              <StatusBadge status={colorStatus.grey} name="Archive" />
            )}
          </Typography>
        );
      case "date":
        return (
          <Typography variant="body1">
            {value["startDate"]
              ? getCurrentDate(value["startDate"])
              : value["dateCreated"]
              ? getCurrentDate(value["dateCreated"])
              : "n/a"}
          </Typography>
        );
      default:
        return;
    }
  };

  return isLoading ? (
    <Box sx={{ marginTop: "30vh" }}>
      <UniversalLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ marginTop: "3em" }}>
        <FilterOrders
          valueChangeHandler={valueChangeHandler}
          role={currentUser.role}
          missionTypeData={ClientPage.GroupOrder}
        />
      </Box>
      <Box>
        <GlobalTable
          columns={columns}
          allData={filteredOrderList}
          valueConvertHandler={valueConvertHandler}
          goToPageFcn={goToPageFcn}
          loading={isLoading}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={pageData.currentPage}
          isLastPage={pageData.isLast}
          lastPage={pageData.lastPage}
          sortByHandler={() => {}}
        />
      </Box>
    </>
  );
};

GroupOrderTable.propTypes = {
  columns: PropTypes.object,
};

export default GroupOrderTable;
