import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import theme from "../../../theme";
import AppButton from "../../global/elements/AppButton";
import { handleCurrencyConvert } from "../../../lib/externalAPI";

const styles = {
  currencyConverterContainer: {
    display: "flex",
    width: "400px",
    flexDirection: "column",
    rowGap: "1em",
    height: "245px",
    boxShadow: `0px 0px 36px ${theme.palette.grey.lightWarmGrey}E6`,
    background: `${theme.palette.grey.white}`,
    p: 2,
  },
  inputsContainer: {
    width: "100%",
  },
  currencySelectors: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
};

const currencies = [
  {
    value: "EUR",
    label: "EUR - Euro",
    symbol: "€",
  },
  {
    value: "USD",
    label: "USD - US Dollar",
    symbol: "$",
  },
  {
    value: "SEK",
    label: "SEK - Swedish Krona",
    symbol: "kr",
  },
];

const CurrencyCalculator = () => {
  const [amount, setAmount] = useState("");
  const [fromCurrency, setFromCurrency] = useState("EUR");
  const [toCurrency, setToCurrency] = useState("USD");
  const [result, setResult] = useState("");

  const handleConvert = async (amount, fromCurrency, toCurrency) => {
    if (!amount) {
      setResult("");
      return;
    }
    const convertedAmount = await handleCurrencyConvert(
      amount,
      fromCurrency,
      toCurrency
    );
    setResult(convertedAmount);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setResult("");
  };

  const handleFromCurrencyChange = (e) => {
    setFromCurrency(e.target.value);
    setResult("");
  };

  const handleToCurrencyChange = (e) => {
    setToCurrency(e.target.value);
    setResult("");
  };

  const getSymbolForCurrency = (currencyCode) => {
    return currencies.find((curr) => curr.value === currencyCode)?.symbol || "";
  };

  return (
    <Box sx={styles.currencyConverterContainer}>
      <Typography variant="h6">Currency Converter</Typography>

      <Box sx={styles.inputsContainer}>
        <TextField
          fullWidth
          label="Amount"
          type="number"
          value={amount}
          onChange={handleAmountChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {getSymbolForCurrency(fromCurrency)}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "10px",
            },
            mb: 2,
          }}
        />

        <Box sx={styles.currencySelectors}>
          <TextField
            select
            label="From"
            value={fromCurrency}
            variant="outlined"
            onChange={handleFromCurrencyChange}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "10px",
              },
              width: "48%",
            }}
          >
            {currencies.map((currency) => (
              <MenuItem key={currency.value} value={currency.value}>
                {currency.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="To"
            value={toCurrency}
            onChange={handleToCurrencyChange}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "10px",
              },
              width: "48%",
            }}
          >
            {currencies.map((currency) => (
              <MenuItem key={currency.value} value={currency.value}>
                {currency.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box display="flex" gap="1rem" alignItems="center" mt="1rem">
          <AppButton
            label="Convert"
            onClick={() => handleConvert(amount, fromCurrency, toCurrency)}
            look="black"
          />
          <Typography>
            {amount &&
              result &&
              `${amount} ${fromCurrency} = ${result} ${toCurrency}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrencyCalculator;
