import React from "react";
import { projectStatus } from "../../../../lib/constants";
import ProjectTable from "./ProjectTable";

const InitialProject = () => {
  const status = projectStatus.initial;

  return (
    <ProjectTable
      status={status}
      pageTitle={"UnConfirmed"}
      tips="Here you find your submitted multi-location or recurrent orders. They are under review by GLOBHE. You will soon receive a quote for this order that you can either accept or decline. Once accepted, the order will be active."
    />
  );
};

export default InitialProject;
