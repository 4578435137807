import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import AppButton from "../../../../global/elements/AppButton";
import MissionContext from "./MissionContext";
import { pilotUndoRejectMission } from "../../../../../services/missions";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import theme from "../../../../../theme";

const styles = {
  inquiry: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "100%",
    textAlign: "left",
    "& p": {
      marginBottom: "1em",
      color: theme.palette.grey[600],
    },
    marginBottom: "40px",
  },
};

const UndoPilotRejection = () => {
  const data = useContext(MissionContext);
  const [undone, setUndone] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUndoRejectInquiry = async () => {
    setUndone(true);
    await pilotUndoRejectMission(
      firebase,
      data?.currentUser?.id,
      data?.mission
    );

    setUndone(false);
    data?.refreshMission();
    enqueueSnackbar("You have undone your mission rejection", {
      variant: "success",
    });
  };
  return (
    <>
      <Box sx={styles.inquiry}>
        <Typography variant="h4" sx={styles.actionTitle}>
          Mission inquiry rejected
        </Typography>
        <Typography variant="body1">
          You have rejected this mission inquiry.
        </Typography>
        <Box marginTop={4}>
          <AppButton
            label="Undo Rejection"
            look="purple"
            onClick={() => handleUndoRejectInquiry()}
            submittingState={undone}
          />
        </Box>
      </Box>
    </>
  );
};

export default UndoPilotRejection;
