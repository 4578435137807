import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import PropTypes from "prop-types";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import { orderBox } from "../../../../lib/styleConstants";
import {
  missionTypeConstant,
  missionStatus,
  contractWaiverLabels,
  orderCollectionData,
  activeMissionStatuses,
} from "../../../../lib/constants";
import TextWithTooltip from "../../../global/elements/TextWithTooltip";
import SwitchSelect from "../../../global/elements/SwitchSelect";

const styles = {
  marginTop: {
    marginTop: "20px",
  },
  pilotPriceBox: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  operatorPriceText: {
    marginBottom: "15px",
  },
};

const ObjAndDescOperator = ({
  values = {},
  setFieldValue = undefined,
  missionData = undefined,
  orderCollection = orderCollectionData.missions,
}) => {
  const checkPilotBidding = () => {
    if (
      missionData.status == missionStatus.confirmedMission ||
      missionData.status == missionStatus.unassigned
    ) {
      if (missionData.sentToPilots && missionData.sentToPilots.length > 0) {
        const declinedByPilots =
          missionData.declinedByPilots && missionData.declinedByPilots.length
            ? missionData.declinedByPilots
            : [];
        if (declinedByPilots.length > 0) {
          const invitedPilots = missionData.sentToPilots.filter(
            (pilot) => !declinedByPilots.includes(pilot)
          );

          return invitedPilots && invitedPilots.length > 0 ? false : true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Box sx={orderBox}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <TextStructures text="Operator related actions" number={7} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" mt={3}>
            Please rewrite the details provided by the client and explain them
            in detail to the operator. Write exactly what you want them to
            accomplish to satisfy the client&apos;s requirements. This
            description will be shown in the contract. The &apos;Other
            requirements&apos; are other details that you think are important,
            such as successful completion criteria for example. They will also
            be in the contract.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "10px" }}>
            Note that the operator cannot see the client&apos;s description,
            they can only see what you are writing to them here.
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            label="Detailed operator instructions"
            name="pilotInstructions"
            type="text"
            rows={3}
            multiline={true}
            required
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            values={values}
            label="Other requirements for the operator"
            name="otherRequirements"
            type="text"
            rows={3}
            multiline={true}
          />
        </Grid>
        {orderCollection == orderCollectionData.projects && (
          <Grid item xs={12} mt={2}>
            <TextFieldInput
              setFieldValue={setFieldValue}
              values={values}
              label="Drone Requirements for the operator"
              name="droneRequirements"
              type="text"
              rows={3}
              multiline={true}
            />
          </Grid>
        )}
        {orderCollection == orderCollectionData.missions && (
          <>
            {missionData.status !== missionStatus.completed && (
              <Grid item xs={6} mt={2}>
                <TextWithTooltip
                  text="Allow large files"
                  tooltipText="Select yes if you do not want the operator to Upload large file."
                />
                <SwitchSelect
                  name="uploadLargeFile"
                  handleChange={(e, value) =>
                    setFieldValue("uploadLargeFile", value ? true : false)
                  }
                  checked={values.uploadLargeFile}
                />
              </Grid>
            )}
            {missionData.missionType === missionTypeConstant.projectMission && (
              <Grid item xs={6} mt={2}>
                <TextWithTooltip
                  text="Allow Empty Data"
                  tooltipText="Select yes if client want to upload data on some other platform."
                />
                <SwitchSelect
                  checked={values.allowEmptyData}
                  handleChange={(e) => {
                    setFieldValue("allowEmptyData", e.target.checked);
                  }}
                  name="allowEmptyData"
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <TextStructures text="Payments and contracts" />
            </Grid>

            <Grid item xs={12} mt={2}>
              <TextWithTooltip
                text="Allow the operators to bid?"
                tooltipText="By selecting yes, you open this mission for bidding. This means that the invited pilots will choose their fee and you select the most appropriate one later. It is only active when no operators are invited or assigned."
              />
              <SwitchSelect
                name="allowPilotBidding"
                handleChange={(e, value) =>
                  setFieldValue("allowPilotBidding", value ? true : false)
                }
                checked={values.allowPilotBidding}
                disabled={!checkPilotBidding()}
              />
            </Grid>
            {values.allowPilotBidding === false && (
              <Grid item xs={12} mt={2}>
                <Typography sx={styles.operatorPriceText}>
                  Please choose an operator price. When operators are not
                  bidding, you should choose the price they will get.
                </Typography>
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Operator price"
                  name={"pilotPrice"}
                  type="number"
                  required
                />
              </Grid>
            )}

            {missionData.missionType !== missionTypeConstant.projectMission && (
              <Grid item xs={12} mt={2}>
                <TextWithTooltip
                  text="Upload Invoice"
                  tooltipText="Select yes to create an invoice for this mission. Client order missions are always invoicable, but in case there is an operator submission or a challenge mission, you can create an invoice for it. This can only be done before the mission is marked completed and before you approve the pilot package."
                />
                <SwitchSelect
                  name="allowInvoice"
                  handleChange={(e, value) =>
                    setFieldValue("allowInvoice", value ? true : false)
                  }
                  checked={values.allowInvoice}
                />
              </Grid>
            )}
            {missionData.status !== missionStatus.completed &&
              missionData.missionType !==
                missionTypeConstant.projectMission && (
                <Grid item xs={12} mt={2}>
                  <TextWithTooltip
                    text="Waive contract"
                    tooltipText="Select yes if you do not want the operator to sign the contract. Only client order missions require a contract, but sometimes you don't want the operator to sign one."
                  />
                  <SwitchSelect
                    name="contractAcceptedViaAdmin"
                    handleChange={(e, value) =>
                      setFieldValue(
                        "contractAcceptedViaAdmin",
                        value
                          ? contractWaiverLabels.accepted
                          : contractWaiverLabels.notAccepted
                      )
                    }
                    checked={
                      values.contractAcceptedViaAdmin ===
                      contractWaiverLabels.accepted
                    }
                    isDisabled={
                      activeMissionStatuses.includes(missionData.status)
                        ? true
                        : false
                    }
                  />
                </Grid>
              )}
          </>
        )}
      </Grid>
    </Box>
  );
};

ObjAndDescOperator.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  missionData: PropTypes.object,
  orderCollection: PropTypes.string,
};

ObjAndDescOperator.defaultProps = {
  values: {},
  missionData: undefined,
  orderCollection: orderCollectionData.missions,
};

export default React.memo(ObjAndDescOperator);
