import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import ArrowHeaderContainer from "../../../NewDataLibrary/components/ArrowHeaderContainer";
import MissionContext from "./MissionContext";
import { missionStatus, userRoles } from "../../../../../lib/constants";
import SmallStatusBanner from "../../../../global/elements/SmallStatusBanner";
import MissionStatusBar from "../../MissionStatusBar";

const styles = {
  statusBanner: {
    marginTop: "80px",
  },
  progress_bar_main: {
    border: "none",
    width: "100%",
  },
  statusContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "80px",
  },
};

const OrderTopNavComponent = () => {
  const data = useContext(MissionContext);
  const currentUser = data?.currentUser;
  const role = currentUser?.role;
  const isAdmin = role === userRoles.admin;
  const isClient = role === userRoles.client;
  // const isPilot = data?.currentUser?.role === userRoles.pilot;
  const mission = data?.mission;
  const status = mission?.status;
  const projectIds = mission?.projectIds;
  const missionName = mission?.missionName;

  const getRedirectURL = () => {
    // const missionUrl = window.localStorage.getItem("missionUrl");
    // if (missionUrl) {
    //   if (isAdmin) {
    //     return "/app/missions/client-order/initial-client-order";
    //   } else if (isPilot) {
    //     return "/app/missions/inquiries";
    //   } else if (isClient) {
    //     return "/app/client/orders/reviewing";
    //   }
    // }
    if (projectIds) {
      if (isClient) {
        return `/app/client/projects/${data?.mission?.projectIds}`;
      } else if (isAdmin) {
        return `/app/admin/projects/${data?.mission?.projectIds}`;
      } else {
        return `/app/pilots/project/${data?.mission?.projectIds}`;
      }
    } else {
      if (status === missionStatus.initialClientOrder) {
        return isClient
          ? "/app/client/orders/reviewing"
          : isAdmin
          ? "/app/missions/client-order/initial-client-order"
          : "/app/missions/inquiries";
      } else if (
        status === missionStatus.unassigned ||
        status === missionStatus.confirmedMission
        // status === missionStatus.active
      ) {
        return isClient
          ? "/app/client/orders/inProgress"
          : isAdmin
          ? "/app/missions/client-order/unassigned"
          : "/app/missions/inquiries";
      } else if (status === missionStatus.active) {
        return isClient
          ? "/app/client/orders/inProgress"
          : isAdmin
          ? "/app/missions/client-order/active"
          : "/app/missions/active";
      } else if (status === missionStatus.completed) {
        return isClient
          ? "/app/client/orders/completed"
          : isAdmin
          ? "/app/missions/client-order/completed"
          : "/app/missions/completed";
      } else if (status === missionStatus.archive) {
        return isClient
          ? "/app/client/orders/inProgress"
          : isAdmin
          ? "/app/missions/client-order/archive"
          : "/app/missions/inquiries";
      } else if (status === missionStatus.cancelled) {
        return isClient
          ? "/app/client/orders/cancelled"
          : isAdmin
          ? "/app/missions/client-order/cancelled"
          : "/app/missions/inquiries";
      } else if (status === missionStatus.rejected) {
        return isClient
          ? "/app/client/orders/rejected"
          : isAdmin
          ? "/app/missions/client-order/rejected"
          : "/app/missions/rejected";
      }
    }
  };

  return (
    <Box>
      <ArrowHeaderContainer header={missionName} redirectURL={getRedirectURL()}>
        <Grid container>
          {(status !== missionStatus.archive ||
            status !== missionStatus.cancelled) && (
            <>
              <Grid item xs={12} mt={4}>
                <Box sx={styles.progress_bar_main}>
                  <MissionStatusBar mission={data?.mission} />
                </Box>
              </Grid>
            </>
          )}
          {status === missionStatus.cancelled && (
            <Grid item xs={12} sx={styles.statusBanner}>
              <SmallStatusBanner
                title="This order is cancelled"
                content="Your data will not be captured and you will not be invoiced. Place a new order in case you want data from this region again."
              />
            </Grid>
          )}
          {status === missionStatus.rejected && (
            <Grid item xs={12} sx={styles.statusBanner}>
              <SmallStatusBanner
                title="This order has been rejected"
                content={
                  isClient
                    ? data?.reason
                    : isAdmin
                    ? data?.mission?.reason?.rejectedReason
                    : ""
                }
              />
            </Grid>
          )}
        </Grid>
      </ArrowHeaderContainer>
    </Box>
  );
};

export default OrderTopNavComponent;
