import React from "react";
import MissionPlanningItems from "./MissionPlanningItems";
import { missionPlanningType, userRoles } from "../../../../../lib/constants";
import AdminClientMissionPlanning from "./AdminClientMissionPlanning";
import { getUser } from "../../../../../services/auth";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const PostFlight = ({ setTabValue }) => {
  // return <MissionPlanningItems tabValue={missionPlanningType.} />;
  const currentUserRole = getUser("user").role;

  return (
    <Box ml={2}>
      {currentUserRole !== userRoles.pilot ? (
        <AdminClientMissionPlanning tabValue={missionPlanningType.postFlight} />
      ) : (
        <MissionPlanningItems
          tabValue={missionPlanningType.postFlight}
          setTabValue={setTabValue}
        />
      )}
    </Box>
  );
};
PostFlight.propTypes = {
  setTabValue: PropTypes.func.isRequired,
};
export default PostFlight;
