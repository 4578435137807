import React from "react";
import { useGetMissionList } from "src/hooks/useGetMissionList";
import { getUser } from "../../../services/auth";
import NoMissionsMessage from "./shared/NoMissionsMessage";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../theme";
import FilterMission from "./FilterMission";
import GlobalTable from "../admin/GlobalTable";
import {
  getClientOrderStatusLabelData,
  getMissionStatusLabelData,
  getPaymentStatusLabelData,
} from "../../../lib/helpers";
import {
  invoicePaymentStatus,
  missionStatusLabels,
  missionTypeConstant,
  colorStatus,
  statusAlertSeverity,
  missionStatus,
} from "../../../lib/constants";
import { navigate } from "gatsby";
import StatusBadge from "../../global/elements/StatusBadge";
import UniversalLoader from "../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { unauthorizedStatusAlert } from "../../../lib/styleConstants";

const statusStyle = {
  display: "flex",
  alignItems: "flex-start",
  gap: "0.5rem",
  flexDirection: "column",
};

const styles = {
  columns: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  filter: {
    marginTop: "10px",
  },
  missionContainer: {
    width: "100%",
    padding: "0px",
  },
  info_message: {
    fontSize: { xs: "12px", md: ".73864rem" },
    textAlign: "center",
    color: theme.palette.status.errorDark,
  },
  paymentStatusRow: {
    display: "flex",
    width: "90px",
    marginBottom: "2px",
    "& h6": {
      width: "auto !important",
    },
  },
  fontWeightStyle: {},
};

const noMissionTextMap = {
  [missionTypeConstant.clientOrder]: {
    [missionStatus.initialClientOrder]: {
      title: "No Initial Orders :(",
      desc: "Currently, there are no initial orders.",
    },
    [missionStatus.active]: {
      title: "No Active Orders :(",
      desc: "Currently, there are no active orders.",
    },
    [missionStatus.confirmedMission]: {
      title: "No Confirmed Orders :(",
      desc: "Currently, there are no confirmed orders.",
    },
    [missionStatus.completed]: {
      title: "No Completed Orders :(",
      desc: "Currently, there are no completed orders.",
    },
    [missionStatus.archive]: {
      title: "No Archived Orders :(",
      desc: "Currently, there are no archived orders.",
    },
    [missionStatus.cancelled]: {
      title: "No Completed Orders :(",
      desc: "Currently, there are no cancelled orders.",
    },
    [missionStatus.rejected]: {
      title: "No Completed Orders :(",
      desc: "Currently, there are no ejected orders.",
    },
  },
  [missionTypeConstant.challengeMission]: {
    title: "No Active Challenge Mission :(",
    desc: "Currently, there are no active challenge missions.",
  },
  [missionTypeConstant.guinnessWorldRecord]: {
    title: "No Guinness World Record Mission :(",
    desc: "Currently, there are no Guinness World Record missions.",
  },
};

const MissionPage = ({
  status = undefined,
  columns = undefined,
  missionType = undefined,
}) => {
  const currentUser = getUser("user");

  const getNoMissionText = () => {
    return (
      noMissionTextMap[missionType]?.[status] ||
      noMissionTextMap[missionType] || {
        title: "No Missions :(",
        desc: "Currently, there are no missions available.",
      }
    );
  };

  const {
    authorized,
    orders,
    isLoading,
    limit,
    limitHandler,
    sortByHandler,
    paginationBtnClick,
    isLastPage,
    valueChangeHandler,
    currentPage,
    lastPageNumber,
  } = useGetMissionList(status, missionType);

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "paymentStatus": {
        if (
          value.missionType !== missionTypeConstant.clientOrder &&
          value.allowInvoice
        ) {
          const statusLabel = getPaymentStatusLabelData(value, "pilot");
          return (
            <Box>
              <Box sx={styles.paymentStatusRow}>
                <StatusBadge
                  name={statusLabel.label}
                  status={statusLabel.statusColor}
                  small
                />
              </Box>
            </Box>
          );
        } else if (value.missionType === missionTypeConstant.clientOrder) {
          const statusLabel = getMissionStatusLabelData(value, currentUser);
          const paymentPilotStatusLabel = getPaymentStatusLabelData(
            value,
            "pilot"
          );
          const paymentClientStatusLabel = getPaymentStatusLabelData(
            value,
            "client"
          );
          const label =
            value["paymentStatusPilot"] == invoicePaymentStatus.require_edit
              ? paymentPilotStatusLabel.label
              : "Pilot " + value["paymentStatusPilot"];

          return (
            <Box>
              <Box sx={{ ...statusStyle, mb: "0.5rem" }}>
                <Box sx={styles.paymentStatusRow}>
                  <StatusBadge
                    name={label || "n/a"}
                    status={paymentPilotStatusLabel.statusColor}
                    small
                  />
                </Box>
                {value["paymentStatusPilot"] !== "paid" && (
                  <Box
                    sx={{ ...styles.info_message, ...styles.status_text }}
                    dangerouslySetInnerHTML={{
                      __html: statusLabel.info,
                    }}
                  />
                )}
              </Box>
              <Box>
                <Box sx={statusStyle}>
                  <Box sx={styles.paymentStatusRow}>
                    {value.subscriptionId ? (
                      <StatusBadge
                        name={"SUBSCRIPTION"}
                        status={
                          paymentClientStatusLabel.statusColor ===
                          colorStatus.green
                            ? colorStatus.green
                            : paymentClientStatusLabel.statusColor ===
                              colorStatus.red
                            ? colorStatus.red
                            : colorStatus.yellow
                        }
                      />
                    ) : (
                      <StatusBadge
                        name={"Client " + value["paymentStatusClient"] || "n/a"}
                        status={paymentClientStatusLabel.statusColor}
                        small
                      />
                    )}
                  </Box>
                  {value["paymentStatusClient"] !== "paid" && (
                    <Box
                      sx={{ ...styles.info_message, ...styles.status_text }}
                      dangerouslySetInnerHTML={{
                        __html: statusLabel.info,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          );
        } else {
          return (
            <Box sx={styles.paymentStatusRow}>
              <StatusBadge name="not applicable" status={colorStatus.neutral} />
            </Box>
          );
        }
      }
      case "status": {
        const statusLabel = getMissionStatusLabelData(value, currentUser);
        return (
          <Box sx={statusStyle}>
            <StatusBadge
              name={
                statusLabel.label === missionStatusLabels.correction
                  ? missionStatusLabels.inProgress
                  : statusLabel.label
              }
              status={statusLabel.statusColor}
              small
            />
            <Box
              dangerouslySetInnerHTML={{
                __html: statusLabel.info,
              }}
              sx={styles.info_message}
            />
          </Box>
        );
      }
      case "clientOrderStatus": {
        const statusLabel = getClientOrderStatusLabelData({
          missionData: value,
        });
        return (
          <Box>
            <Box sx={statusStyle}>
              <StatusBadge
                name={
                  statusLabel.label === missionStatusLabels.correction
                    ? missionStatusLabels.inProgress
                    : statusLabel.label
                }
                status={statusLabel.statusColor}
                small
              />
              <Box
                dangerouslySetInnerHTML={{
                  __html: statusLabel.info,
                }}
                sx={styles.info_message}
              />
            </Box>
          </Box>
        );
      }
      case "clientPaymentStatus": {
        if (
          value.missionType === missionTypeConstant.clientOrder ||
          value.allowInvoice
        ) {
          const statusLabel = getPaymentStatusLabelData(value, "pilot");
          return (
            <Box>
              <Box>
                <StatusBadge
                  name={statusLabel.label}
                  status={statusLabel.statusColor}
                  small
                />
              </Box>
            </Box>
          );
        } else {
          return <StatusBadge name="not applicable" status={undefined} />;
        }
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    navigate(`/app/missions/${value.id}`);
  };

  return (
    <section>
      {authorized == true ? (
        <Box sx={styles.missionContainer}>
          <Box sx={styles.filter}>
            <FilterMission
              valueChangeHandler={valueChangeHandler}
              role={currentUser.role}
              status={status}
              missionType={missionType}
            />
          </Box>
          {isLoading ? (
            <Box>
              <UniversalLoader />
            </Box>
          ) : orders?.length > 0 ? (
            <GlobalTable
              columns={columns}
              allData={orders}
              valueConvertHandler={valueConvertHandler}
              goToPageFcn={goToPageFcn}
              loading={isLoading}
              limitHandler={limitHandler}
              limit={limit}
              paginationBtnClick={paginationBtnClick}
              currentPage={currentPage}
              isLastPage={isLastPage}
              lastPage={lastPageNumber}
              sortByHandler={sortByHandler}
            />
          ) : (
            <NoMissionsMessage
              title={getNoMissionText().title}
              description={getNoMissionText().desc}
              actionButton={null}
            />
          )}
        </Box>
      ) : (
        authorized == false && (
          <Box sx={unauthorizedStatusAlert}>
            <StatusAlertContainer
              heading="Unauthorized access! Your admin type does not allow you to work with missions.
              Please contact Napoleon to change your access rights."
              severity={statusAlertSeverity.error}
              coloredBackground
            />
          </Box>
        )
      )}
    </section>
  );
};

MissionPage.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  missionType: PropTypes.any,
};

// MissionPage.defaultProps = {
//   missionType: undefined,
// };

export default MissionPage;
