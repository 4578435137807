/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import CommunicationBasic from "./CommunicationBasic";
import MissionContext from "../../app/missions/NewMissionDetails/components/MissionContext";
import { missionTypeConstant, userRoles } from "../../../lib/constants";
import { useStateIfMounted } from "use-state-if-mounted";
import globhePng from "../../../assets/logos/globhe_logo/Globhe_symbol_2022_Dark_sea_black.png";
import { Box, Grid, Typography } from "@mui/material";
import SideChatMenu from "./SideChatMenu";

const styles = {
  appBar: {
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "green",
  },
  noPilotBox: {
    minHeight: "400px",
    height: "400px",
    padding: "20px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const TwoWayCommunicationClient = () => {
  const missionContext = useContext(MissionContext);
  const [personsList, setPersonsList] = useStateIfMounted([]);
  const [messagingTo, setMessagingTo] = useStateIfMounted(0);

  useEffect(() => {
    // Setting a list of person to chat
    setPersonsList([
      {
        name: "Globhe admin",
        id: "Globhe admin",
        role: userRoles.admin,
        profilePicture: "",
      },
      {
        name: "Group chat",
        id: "Globhe Admin , Operator",
        role: userRoles.all,
        profilePicture: globhePng,
      },
    ]);
    //redirecting to the pilot chat if we recieve a pilot chat in notication.
    if (window.location.search === "?all") {
      setMessagingTo(1);
    } else {
      setMessagingTo(0);
    }
  }, []);

  const changePersonToChat = (chatTo) => {
    if (chatTo === userRoles.all) {
      setMessagingTo(1);
    } else {
      setMessagingTo(0);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={2.5}>
        <SideChatMenu
          personToChat={personsList}
          onPersonClickFcn={changePersonToChat}
          active={messagingTo === 0 ? userRoles.admin : userRoles.all}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={9.5}>
        {messagingTo === 0 ? (
          <CommunicationBasic
            mission={missionContext?.mission}
            talkingTo={userRoles.admin}
          />
        ) : messagingTo === 1 &&
          missionContext.mission.missionType ===
            missionTypeConstant.clientOrder ? (
          <CommunicationBasic
            mission={missionContext?.mission}
            talkingTo={userRoles.all}
          />
        ) : (
          <Box sx={styles.noPilotBox}>
            <Typography variant="h5">
              Group Chat is not currently available for Project Missions.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TwoWayCommunicationClient;
