import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Switcher from "../../../global/Switcher";
import theme from "../../../../theme";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import { orderBox } from "../../../../lib/styleConstants";
import { getUser } from "../../../../services/auth";
import { userRoles } from "../../../../lib/constants";
import Tips from "../../../global/elements/Tips";

const tipStyle = {
  marginTop: "20px",
};

const OrderHeaderSwitch = ({
  orderSwitch,
  setOrderSwitch,
  setOrderCollection,
}) => {
  const user = getUser("user").role;
  const isAdmin = user === userRoles.admin;
  const isClient = user === userRoles.client;

  //create header and switch component in here.

  /*   use the current switch component pass a new prop look and
based on that create a new look according to design.*/

  // useEffect(() => {
  //   changeAmbassadorValue(firebase);
  // }, []);

  return (
    <Box sx={orderBox}>
      <TextStructures
        text="I want to task a drone for"
        number={1}
        marginBottom
      />
      <Switcher
        tabActive={orderSwitch}
        setTabActive={setOrderSwitch}
        buttonText1={"Single location"}
        buttonText2={"Multiple locations"}
        subHeading1={`Gather data from a single site or area, 
          either one-time or on a recurring basis.`}
        subHeading2={`A convenient way to gather data from many areas 
          or sites in one order.`}
        activeBackGround={
          isClient
            ? theme.palette.primary.palePistachio
            : isAdmin
            ? theme.palette.adminBlue.paleBlue
            : theme.palette.secondary.lightPurple
        }
        inactiveBackground={theme.palette.grey.white}
        //activeBackGround={theme.palette.grey.lightWarmGrey}
        //inactiveBackground={theme.palette.black.coolGrey}
        activeColor={theme.palette.black.darkSeaBlack}
        inactiveColor={theme.palette.grey.warmGrey}
        setOrderCollection={setOrderCollection}
        look
        // border={`2px solid ${theme.palette.secondary.main}`}
        // disableSwitch={disableSwitch}
      />
      {/* <StatusAlertContainer severity="warning" heading="need help" /> */}
      <Box sx={tipStyle}>
        <Tips
          content="Wondering what solution to choose or what is the most suitable
              product for your business? Contact us, we would love to help!"
          noBackgroundColor
          supportButton
        />
      </Box>
    </Box>
  );
};

OrderHeaderSwitch.propTypes = {
  orderSwitch: PropTypes.string.isRequired,
  setOrderSwitch: PropTypes.func.isRequired,
  setOrderCollection: PropTypes.func.isRequired,
};

export default OrderHeaderSwitch;
