import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  IconButton,
  Tooltip as MUIToolTip,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
import theme from "src/theme";
import { Info } from "@material-ui/icons";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectStatusChart = ({ projectDetails }) => {
  const getPercentage = (value, total) => {
    if (!value || !total) return 0;
    return Number(((value / total) * 100).toFixed(0));
  };
  const totalMissions = projectDetails?.allMissionCount;
  const statusData = {
    unConfirmed: {
      count: projectDetails?.allUnConfirmedMissionCount || 0,
      percentage: getPercentage(
        projectDetails?.allUnConfirmedMissionCount,
        totalMissions
      ),
    },
    confirmed: {
      count: projectDetails?.allInitialMissionCount || 0,
      percentage: getPercentage(
        projectDetails?.allInitialMissionCount,
        totalMissions
      ),
    },
    active: {
      count: projectDetails?.allActiveMissionCount || 0,
      percentage: getPercentage(
        projectDetails?.allActiveMissionCount,
        totalMissions
      ),
    },
    notFeasibleMission: {
      count: projectDetails?.notFeasibleMissions || 0,
      percentage: getPercentage(
        projectDetails?.notFeasibleMissions || 0,
        totalMissions
      ),
    },
    completed: {
      count: projectDetails?.allCompletedMissionCount || 0,
      percentage: getPercentage(
        projectDetails?.allCompletedMissionCount,
        totalMissions
      ),
    },
    cancelled: {
      count: projectDetails?.archiveMissionCount || 0,
      percentage: getPercentage(
        projectDetails?.archiveMissionCount,
        totalMissions
      ),
    },
  };

  const chartData = {
    labels: [
      "UnConfirmed Orders",
      "Confirmed orders",
      "In Progress orders",
      "In Progress with Issues orders",
      "Completed orders",
      "Cancelled orders",
    ],
    datasets: [
      {
        data: [
          statusData.unConfirmed.count,
          statusData.confirmed.count,
          statusData.active.count,
          statusData.notFeasibleMission.count,
          statusData.completed.count,
          statusData.cancelled.count,
        ],
        backgroundColor: [
          theme.palette.chart.unConfirmed,
          theme.palette.chart.confirmed,
          theme.palette.chart.active,
          theme.palette.chart.notFeasible,
          theme.palette.chart.completed,
          theme.palette.chart.cancelled,
        ],
        borderWidth: 3,
        hoverOffset: 10,
      },
    ],
  };

  const chartOptions = {
    cutout: "75%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        position: "nearest",
        z: "1",
      },
    },
    maintainAspectRatio: false,

    hover: {
      mode: "nearest",
      intersect: true,
      animationDuration: 400,
    },
  };

  const statusItems = [
    {
      label: "Un-Confirmed orders",
      tooltipText: "Pending Globhe Review",
      color: theme.palette.chart.unConfirmed,
      ...statusData.unConfirmed,
    },
    {
      label: "Confirmed orders",
      tooltipText: "Awaiting data capture",
      color: theme.palette.chart.confirmed,
      ...statusData.confirmed,
    },
    {
      label: "In progress orders",
      tooltipText: "Data is being captured/processed",
      color: theme.palette.chart.active,
      ...statusData.active,
    },
    {
      label: "In progress with issues orders",
      tooltipText: "Marked as not feasible due to issues.",
      color: theme.palette.chart.notFeasible,
      ...statusData.notFeasibleMission,
    },
    {
      label: "Completed orders",
      tooltipText: "Data delivered to client",
      color: theme.palette.chart.completed,
      ...statusData.completed,
    },
    {
      label: "Cancelled orders",
      tooltipText: "Data capture canceled/rejected",
      color: theme.palette.chart.cancelled,
      ...statusData.cancelled,
    },
  ];

  return (
    <Paper sx={{ p: 3, borderRadius: 1 }} elevation={4}>
      <Grid container spacing={10}>
        <Grid item xs={12} md={4} zIndex={1}>
          <Box
            sx={{
              position: "relative",
              top: "5%",
              width: "230px",
              height: "320px",
            }}
          >
            <Doughnut data={chartData} options={chartOptions} />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: -1,
                pointerEvents: "none",
              }}
            >
              <Typography
                variant="h4"
                color={theme.palette.chart.centerText}
                ml={1.5}
              >
                {statusData.completed.percentage}%
              </Typography>
              <Typography color={theme.palette.chart.centerText} variant="h5">
                Completed
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              Project Status
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {projectDetails?.status || "In progress"} ({totalMissions} Orders)
              <Box component="span" sx={{ float: "right" }}>
                Deadline: {projectDetails?.deadline || "Not set"}
              </Box>
            </Typography>
          </Box>
          <Box>
            {statusItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      borderRadius: 0.5,
                      backgroundColor: item.color,
                      mr: 1,
                    }}
                  />
                  <Typography variant="body1">{item.label}</Typography>
                  <MUIToolTip title={item.tooltipText} placement="right" arrow>
                    <IconButton size="small" sx={{ ml: 0.5 }}>
                      <Info fontSize="small" />
                    </IconButton>
                  </MUIToolTip>
                </Box>
                <Typography variant="body1">
                  {item.count} ({item.percentage}%)
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

ProjectStatusChart.propTypes = {
  projectDetails: PropTypes.object.isRequired,
};

export default ProjectStatusChart;
