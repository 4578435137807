import React, { useState } from "react";
import Layout from "../../../Layout";
import ProfileProgressBar from "../../global/elements/ProfileProgressBar";
import DashboardCreateMissionSection from "../../global/elements/DashboardCreateMissionSection";
import MissionStats from "../../global/elements/MissionStats";
import SectionHeading from "../../global/elements/SectionHeading";
import TextAndMediaCard from "../../global/elements/TextAndMediaCard";
import { getUser } from "../../../services/auth";
import { Box, Grid, Typography } from "@mui/material";
import useGetDashboard from "../../../hooks/useGetDashboard";
import { boxShadowStyle } from "../../../lib/styleConstants";
import AppButton from "../../global/elements/AppButton";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Dropzone from "react-dropzone";
import {
  getCurrentDateTime,
  getCurrentDateTimeMillis,
  uploadFileToStorage,
} from "../../../lib/helpers";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import { updatePilotProfile } from "../../../services/pilots";
import { sampleOperatorCsvFile } from "../../../lib/constants";
import UniversalLoader from "src/components/global/elements/UniversalLoader";

const styles = {
  gridRoot: {
    padding: "10px",
  },
  progressBar: {
    display: "flex",
    width: "100%",
  },
  progress_bar_main: {
    // width: "100%",
    padding: "10px 15px",
    position: "relative",
    marginLeft: "20px",
  },
  floatRight: {
    float: "right",
  },
  marginRight: {
    marginLeft: "20px",
  },
  universalLoderContainer: {
    marginTop: "40vh",
  },
};

const Dashboard = () => {
  const currentUser = getUser("user");
  const { enqueueSnackbar } = useSnackbar();
  const [news, inspiration, pilotData, loading, reload, setReload] =
    useGetDashboard();
  const [percent, setPercent] = useState(0);

  const pilotCSVUploadHandler = async (acceptedFiles) => {
    if (acceptedFiles?.length === 1) {
      let timeStamp = getCurrentDateTimeMillis();
      let file = acceptedFiles[0];
      let path = `AssociatedPilotFiles/${currentUser?.userID}/${timeStamp}/${file.name}`;
      const fileUploadStatus = await uploadFileToStorage(
        firebase,
        file,
        path,
        setPercent
      );
      if (fileUploadStatus.status === "Success") {
        const data = {
          associatedPilotFile: fileUploadStatus?.fileURL,
          dateUpdated: getCurrentDateTime(),
        };
        updatePilotProfile(firebase, currentUser?.userID, data)
          .then(() => {
            enqueueSnackbar("File added successfully!", {
              variant: "success",
            });
            setReload(!reload);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      } else {
        enqueueSnackbar("There were error in uploading the files!", {
          variant: "error",
        });
      }

      console.log("fileUploadStatus", fileUploadStatus);
    } else if (acceptedFiles?.length > 1) {
      enqueueSnackbar("Please upload one document at a time!", {
        variant: "warning",
      });
    }
  };
  const pageTitle = `Hi ${pilotData?.name ?? "Operator"}, welcome ${
    currentUser.role !== "pilot" ? "back" : ""
  }!`;

  return (
    <Layout
      pageTitle={!loading && pilotData ? pageTitle : ""}
      hasSideMenu
      loading={loading}
    >
      {!loading && pilotData ? (
        <Grid container spacing={2} sx={styles.gridRoot}>
          <Grid item xs={12}>
            <Box sx={styles.progressBar}>
              <ProfileProgressBar userData={pilotData || {}} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ minHeight: "14rem" }}>
            <MissionStats
              unassignedMission={pilotData?.unassignedMission || 0}
              activeMission={pilotData?.activeMission || 0}
              completedMission={pilotData?.completedMission || 0}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ minHeight: "14rem" }}>
            <DashboardCreateMissionSection />
          </Grid>
          <Grid xs={12} mt={2}>
            <Box sx={{ ...boxShadowStyle, ...styles.progress_bar_main }}>
              <Typography variant="h6" mb={2}>
                Upload CSV for associated drone operators.
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1">
                    Please upload a CSV file to show how many certified drone
                    operators are associated with you or your organisation.
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    CSV file must contain name and email of the drone operators.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {`Download the "Sample CSV" file for the reference.`}
                  </Typography>
                </li>
              </ul>

              <Box sx={{ width: "100%" }}>
                <AppButton
                  label="Sample CSV"
                  look="pilotLink"
                  customIcon={<CloudDownloadOutlinedIcon />}
                  addtionalStyle={{ padding: "0 60px 0 0", maxWidth: "50px" }}
                  targetNewWidow
                  externalLink
                  url={sampleOperatorCsvFile}
                />
                {/* <AppButton
                label={
                  pilotData?.associatedPilotFile ? "Replace file" : "Upload CSV"
                }
                look="purple"
                addtionalStyle={{ ...styles.floatRight, ...styles.marginRight }}
              /> */}
                <Dropzone
                  accept=".csv"
                  onDrop={(acceptedFiles) =>
                    pilotCSVUploadHandler(acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ ...styles.floatRight }}>
                      <input {...getInputProps()} style={{ display: "none" }} />
                      <AppButton
                        label={
                          pilotData?.associatedPilotFile
                            ? "Replace file"
                            : "Upload CSV"
                        }
                        look="purple"
                        addtionalStyle={{
                          ...styles.marginRight,
                        }}
                      />
                    </div>
                  )}
                </Dropzone>
                {pilotData?.associatedPilotFile && (
                  <AppButton
                    label="Download uploaded file"
                    look="pilotLink"
                    customIcon={<CloudDownloadOutlinedIcon />}
                    addtionalStyle={styles.floatRight}
                    targetNewWidow
                    externalLink
                    url={pilotData?.associatedPilotFile}
                  />
                )}
              </Box>
            </Box>
          </Grid>

          {/* <Achivements /> */}

          <Grid item xs={12}>
            {news?.length > 0 && <SectionHeading heading="News" />}
          </Grid>
          <Grid item xs={12}>
            <TextAndMediaCard data={news} type="text" />
          </Grid>
          <Grid item xs={12}>
            {inspiration?.length > 0 && (
              <SectionHeading heading="Inspirations" />
            )}
            <TextAndMediaCard data={inspiration} />
          </Grid>
        </Grid>
      ) : (
        <Box sx={styles.universalLoderContainer}>
          <UniversalLoader />
        </Box>
      )}
    </Layout>
  );
};

export default Dashboard;
