import pdfMake from "pdfmake/build/pdfmake";
import { globheLogo } from "./globheContractConstants";
import vfs from "../../../assets/fonts/roboto/vfs_fonts";

pdfMake.vfs = vfs;

pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

export const generateContract = async (
  contractHeader,
  missionProjectDetails,
  contractMissionDetails,
  signedByGlobheDate,
  pilotData
) => {
  const contractDefinition = {
    defaultStyle: {
      font: "Roboto",
    },
    content: [
      {
        image: globheLogo,
        width: 100,
        margin: [0, 0, 0, 10],
      },
      {
        text: "Crowddroning Operator Agreement",
        fontSize: 18,
        bold: true,
        alignment: "left",
        margin: [0, 0, 0, 15],
      },
      {
        columns: [
          {
            width: "50%",
            stack: [
              {
                text: `Order Name: ${contractMissionDetails.name || "N/A"}`,
                fontSize: 10,
                bold: true,
              },
              {
                text: `Order ID: ${contractMissionDetails.id || "N/A"}`,
                fontSize: 10,
              },
            ],
            margin: [0, 0, 0, 15],
          },
          {
            width: "50%",
            stack: [
              {
                text: "Contract Number",
                fontSize: 10,
              },
              {
                text: contractMissionDetails.number,
                bold: true,
                fontSize: 10,
              },
            ],
            alignment: "right",
            margin: [0, 0, 0, 15],
          },
        ],
      },
      { text: contractHeader, fontSize: 10, bold: true },
      {
        text: "Pilot Details",
        fontSize: 10,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      {
        table: {
          headerRow: ["Detail", "Value"],
          body: [
            ["Name", pilotData?.name || "N/A"],
            ["Email", pilotData?.email || "N/A"],
            ["Address", pilotData?.address || "N/A"],
            ["City", pilotData?.city || "N/A"],
            ["Registration Number", pilotData?.registrationNo || "N/A"],
            ["Billing Address", pilotData?.billingAddress || "N/A"],
            ["Phone", pilotData?.phone || "N/A"],
            ["Postal Code", pilotData?.postalCode || "N/A"],
            ["Company", pilotData?.company || "N/A"],
            ["Drone Licence", pilotData?.permissionToFly ? "yes" : "No"],
            ["Insurance", pilotData?.insurance ? "yes" : "No"],
          ],
          widths: ["*", "*"],
        },
        layout: "lightHorizontalLines",
        fontSize: 9,
        margin: [0, 5, 0, 5],
      },
      {
        text: `Order Details`,
        fontSize: 10,
        bold: true,
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          body: [
            ...Object.entries(missionProjectDetails)
              .map(([key, value]) => {
                if (key === "Operators Guidelines" && value) {
                  return [
                    "Operator Guidelines",
                    {
                      text: "Operator Guidelines.pdf",
                      fontSize: 9,
                      color: "blue",
                      link: value,
                      decoration: "underline",
                    },
                  ];
                } else if (key === "Operator Instructions" && value) {
                  return [
                    "Operator Instructions",
                    {
                      text: value,
                      fontSize: 9,
                    },
                  ];
                } else if (key === "Assigned Sites" && Array.isArray(value)) {
                  return [
                    "Assigned Sites",
                    {
                      stack: value
                        .map((site) => [
                          {
                            text: site.missionName,
                            color: "blue",
                            link: `https://globhe.com/app/missions/${site.missionId}`,
                            decoration: "underline",
                          },
                        ])
                        .flat(),
                      fontSize: 9,
                    },
                  ];
                } else if (key === "UnSigned Sites" && Array.isArray(value)) {
                  return [
                    "UnSigned Sites",
                    {
                      stack: value
                        .map((site) => [
                          {
                            text: site.missionName,
                            color: "blue",
                            link: `https://globhe.com/app/missions/${site.missionId}`,
                            decoration: "underline",
                          },
                        ])
                        .flat(),
                      fontSize: 9,
                    },
                  ];
                } else {
                  return [key, value];
                }
              })
              .filter((entry) => entry[1]),
          ],
          widths: ["*", "*"],
        },
        layout: "lightHorizontalLines",
        fontSize: 9,
        margin: [0, 5, 0, 5],
      },
      {
        text: [
          "By signing this agreement, I confirm that I have read and agree to all the ",
          {
            text: "terms & conditions",
            fontSize: 10,
            color: "blue",
            link: "https://www.globhe.com/operator-terms-and-conditions",
            decoration: "underline",
          },
          ".",
        ],
        fontSize: 10,
        margin: [0, 10, 0, 10],
      },
      {
        columns: [
          {
            width: "50%",
            stack: [
              { text: "Signed by Globhe", fontSize: 10, bold: true },
              {
                text: `Date: ${new Date(
                  signedByGlobheDate.split("T")[0]
                ).toLocaleDateString()}`,
                fontSize: 10,
              },
            ],
            margin: [0, 30, 0, 5],
          },
          {
            width: "50%",
            stack: [
              { text: pilotData?.name, fontSize: 10, bold: true },
              { text: "Signed by Drone Operator", fontSize: 10, bold: true },
              {
                text: `Date: ${new Date().toLocaleDateString()}`,
                fontSize: 10,
              },
            ],
            alignment: "right",
            margin: [0, 30, 0, 5],
          },
        ],
      },
    ],
  };

  const pdfDocGenerator = pdfMake.createPdf(contractDefinition);

  return new Promise((resolve, reject) => {
    pdfDocGenerator.getBlob((blob) => {
      resolve(blob);
    });
  });
};

const getSiteNames = (sites) => {
  return sites.map((site) => ({
    missionName: site.missionName,
    missionId: site.id,
  }));
};

export function createDetailsObject(
  details,
  contractPrice,
  signedMissions,
  unSignedMissions
) {
  const detailsObject = {
    Scope: details?.pilotInstructions || "As specified in the details",
    Location:
      details?.locationLabel?.split(",")[
        details?.locationLabel?.split(",").length - 1
      ] || "As specified in the details",
    Deadline: details?.deadline || "As specified in the details",
    Delivery:
      details?.mapTypes?.length > 0
        ? `${details?.mapTypes?.join(", ")}`
        : `All files must be uploaded to the crowddroning platform before the deadline as specified in the details.`,
    "Operators Guidelines": details?.guidelinesPdfUrl ?? null,
    "Operator Instructions": details?.pilotInstructions ?? null,
    "Other Requirements":
      details?.otherRequirements || "No qualification requirements added",
    "Payment Amount": contractPrice
      ? `${contractPrice} USD`
      : "No payment amount has been specified",
  };

  if (signedMissions?.length > 0) {
    detailsObject["Assigned Sites"] = getSiteNames(signedMissions);
  }

  if (unSignedMissions?.length > 0) {
    detailsObject["UnSigned Sites"] = getSiteNames(unSignedMissions);
  }
  return detailsObject;
}
