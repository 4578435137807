import { Redirect, Router } from "@reach/router";
import { navigate } from "gatsby";
import firebase from "src/firebase";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import rootSaga from "../redux/sagas";
import {
  store,
  persistor,
  sagaMiddleware,
} from "../redux/store/configure-store";
import { PersistGate } from "redux-persist/integration/react";
import { useCookies } from "react-cookie";
import EditPilot from "src/components/app/admin/EditPilot";
import PilotTable from "src/components/app/admin/PilotTable";
import SinglePilot from "src/components/app/admin/SinglePilot";
import AdminStart from "../components/app/admin/AdminStart";
import EditProfile from "../components/app/my-profile/EditProfile";
import { checkMissionIdUrl, userRoles, users } from "../lib/constants";
import { getPilot } from "../services/pilots";
import PilotDashboard from "../components/app/admin/PilotDashboard";
import ApiKeysPage from "../components/app/admin/pages/apiKeys/ApiKeysPage";
import SingleApiKey from "../components/app/admin/pages/apiKeys/SingleApiKey";
import SingleMission from "../components/app/missions/SingleMission";
import DailyReportsPage from "../components/app/dailyReports/DailyReportsPage";
import SingleDailyReport from "../components/app/dailyReports/SingleDailyReport";
import Dashboard from "../components/app/Dashboard/Dashboard";
import MyProfile from "../components/app/my-profile/index";
import NewsPage from "../components/app/News/NewsPage";
import SingleNewsPage from "../components/app/News/SingleNewsPage";
import InspirationPage from "../components/app/Inspirations/InspirationPage";
import SingleInspirationPage from "../components/app/Inspirations/SingleInspirationPage";
import EditCreateNews from "../components/app/News/EditCreateNews";
import EditCreateInspiration from "../components/app/Inspirations/EditCreateInspiration";
import UpdateNews from "../components/app/News/UpdateNews";
import UpdateInspiration from "../components/app/Inspirations/UpdateInspiration";
import InvoicePageAdmin from "../components/app/Invoices/InvoicePageAdmin";
import BankPage from "../components/app/BankDetails/BankPage";
import ContractPage from "../components/app/Contracts/ContractPage";
import SingleContract from "../components/app/Contracts/SingleContract";
import AdminContractPage from "../components/app/Contracts/AdminContractPage";
import AdminSingleContract from "../components/app/Contracts/AdminSingleContract";
import SingleInvoiceAdmin from "../components/app/Invoices/SingleInvoiceAdmin";
import InitialProject from "../components/app/projects/Tables/InitialProject";
import ActiveProject from "../components/app/projects/Tables/ActiveProject";
import CompletedProject from "../components/app/projects/Tables/CompletedProject";
import ArchiveProject from "../components/app/projects/Tables/ArchiveProject";
// import ClientProfile from "../components/app/Client/Profile/ClientProfile";
import { getUser } from "../services/auth";
// import MyPlans from "../components/app/Client/Plans/MyPlansPage";
import SalesAssistant from "../components/app/admin/pages/SalesAssistant";
import AppStatistics from "../components/app/admin/pages/AppStatistics";
import InvoicePagePilot from "../components/app/Invoices/InvoicePagePilot";
import ClientsAccount from "../components/app/Client/Overview/Overview";
import ProfileEditContainer from "../components/app/Client/Profile/ProfileEditContainer";
import {
  setPersistence,
  browserLocalPersistence,
  getAuth,
} from "firebase/auth";
import FeedbackPage from "../components/app/feedback/FeedbackPage";
import SingleFeedback from "../components/app/feedback/SingleFeedback";
import OperatorListIndex from "../components/app/pilot/DataLibrary/OperatorListIndex";
import CountryProfile from "../components/app/admin/pages/CountryProfile";
import Tools from "../components/app/tools/Tools";
// import SubscriptionPlanWrapper from "../components/app/Client/SubscriptionPlan/SubscriptionPlanWrapper";
import SellYourData from "../components/app/flyForGlobhe/SellYourData";
import PurchasedDataPage from "../components/app/DataLibrary/purchasedItems";
import WishList from "../components/app/DataLibrary/Cart";
import PaymentWrapper from "../components/app/Client/PaymentPage/PaymentWrapper";
import DownloadPurchasedData from "../components/app/DataLibrary/DownloadPurchasedData";
import { getSagaInstance, setSagaInstance } from "../lib/helpers";
import CollaboratorOrder from "../components/app/Client/Collaborator/CollaboratorOrder";
import CollaborationList from "../components/app/Client/Collaborator/CollaborationList";
import {
  AdminActiveClientOrder,
  AdminInitialClientOrder,
  AdminMissionArchive,
  AdminGwrMission,
  AdminChallengeMission,
  AdminTestMission,
  AdminRejectedClientOrder,
  AdminCancelledClientOrder,
  AdminArchivedClientOrder,
  AdminCompletedClientOrder,
  OperatorSubmissionCompleted,
  OperatorSubmissionActive,
  OperatorMissionsActive,
  OperatorMissionsCompleted,
  OperatorMissionsMissed,
  OperatorMissionsRejected,
  OperatorMissionsInquiries,
  AdminUnassignedClientOrder,
  ClientOrderReviewing,
  ClientOrderActive,
  ClientOrderCompleted,
  ClientOrderCancelled,
  ClientOrderRejected,
  ClientGroupOrders,
  ClientOrderConfirmed,
} from "../components/app/missions/AllMissionsAndOrders";
import AdminProjectLayout from "../components/app/projects/projectDetail/AdminProjectLayout";
import ClientProjectLayout from "../components/app/projects/projectDetail/ClientProjectLayout";
import PilotProjectLayout from "../components/app/projects/projectDetail/PilotProjectLayout";
import EditProject from "../components/app/projects/CreateEditProject/EditProject";
import ProjectSites from "../components/app/projects/Tables/ProjectSites";
import PilotProjectTableLayout from "../components/app/projects/Tables/PilotSectionTables/PilotProjectTableLayout";
import ShowDataLibraryLayout from "../components/app/Client/DataLibrary/ShowDataLibraryLayout";
import DraftProject from "../components/app/projects/Tables/DraftProject";
import SinglePageClientInfo from "../components/app/Profiles/Client/SinglePageClientInfo";
import EditSingleLocationOrder from "../components/app/CreateOrder/EditSingleLocationOrder";
import EditMultiLocationOrder from "../components/app/CreateOrder/EditMultiLocationOrder";
import CreateOrder from "../components/app/CreateOrder/CreateOrder";
import PilotSingleInvoice from "../components/app/Invoices/PilotSingleInvoice";
import ErrorBoundaryParent from "../errorHandling/ErrorBoundryParent";
import SingleOrderDataLibraryLayout from "../components/app/NewDataLibrary/SingleOrderDataLibraryLayout";
import ClientSingleMissionPage from "../components/app/missions/NewMissionDetails/ClientSingleMissionPage";
import EnterPriseTeamLayout from "../components/app/EnterPrises/EnterPriseTeamLayout";
import TeamMemberLayout from "../components/app/EnterPrises/TeamMemberLayout";
import ContactSupport from "../components/app/Client/Support/ContactSupport";
import EditTeamProfileLayout from "../components/app/EnterPrises/EditTeamProfileLayout";
import { fetchEnterPrise } from "../services/enterprise";
import AppContext from "../contexts/AppContext";
import EnterpriseClientLayout from "../components/app/EnterPrises/EnterpriseClientLayout";
import EnterpriseClientAdminLayout from "../components/app/EnterPrises/AdminLayouts/EnterpriseClientAdminLayout";
import AdminEnterpriseLayout from "../components/app/EnterPrises/AdminLayouts/AdminEnterpriseLayout";
import PilotBenefitsPage from "../components/app/pilot/MyBenefits/PilotBenefitsPage";
import DraftProjectClient from "../components/app/projects/Tables/DraftProjectClient";
import AddClients from "../components/app/admin/pages/AddClients/AddClients";
import DocumentsAndGuides from "../components/app/admin/pages/DocumentsAndGuides";

const App = () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [userRole, setUserRole] = useState();
  const currentUser = getUser("user");
  const auth = getAuth(firebase);
  const [userData, setUserData] = useState({});
  const [enterPrise, setEnterPrise] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["addEnterPrise"]);

  let userProfile = getUser(users.userProfile);

  useEffect(() => {
    // setUserData(JSON.parse(localStorage.getItem("userProfile")));
    if (userProfile) {
      setUserData(userProfile);
    }

    if (userProfile.role == userRoles.client) {
      // Fetch enterprise through api
      if (userProfile.enterPriseId) {
        fetchClientEnterPrise(userProfile.enterPriseId);
        removeCookie("addEnterPrise", { path: "/" });
      } else {
        setCookie("addEnterPrise", userProfile?.id, { path: "/" });
      }
    }
  }, []);

  const fetchClientEnterPrise = async (enterPriseId) => {
    try {
      console.log("fetchClientEnterPrise is called");
      let enterprise = await fetchEnterPrise(enterPriseId);
      if (enterprise) {
        setEnterPrise(enterprise);
      }
    } catch (e) {
      console.log("Error in fetching the enterprise");
    }
  };

  useEffect(() => {
    // to be uncommented for quote data migration only once
    if (!firebase) {
      return;
    }
    // quoteDataMigration();
    let saga = getSagaInstance();
    if (saga) {
      saga.cancel();
    }
    saga = sagaMiddleware.run(rootSaga);
    setSagaInstance(saga);
    // saga.cancel();
    if (
      checkMissionIdUrl.missionUrl +
        "" +
        /[^/]*$/.exec(window.location.href)[0] ===
      window.location.pathname
    ) {
      localStorage.setItem("missionUrl", window.location.pathname);
    }
    firebase.auth().onAuthStateChanged(function (user) {
      if (currentUser && currentUser.role == userRoles.client) {
        setLoadingUser(false);
        return;
      }
      if (user) {
        setPersistence(auth, browserLocalPersistence);
        // User is signed in.
        if (currentUser && currentUser.role == userRoles.client) {
          setLoadingUser(false);
          return;
        }
        getPilot(firebase, user.uid)
          .then((user) => {
            if (user && !user.deleted) {
              setUserRole(user.role);
            }
          })
          .catch((e) => {
            console.log(e);
            navigate("/");
          })
          .finally(() => {
            setLoadingUser(false);
          });
      } else {
        if (!JSON.parse(localStorage.getItem("user"))?.userID) {
          navigate("/");
        }
      }
    });
  }, []);

  if (loadingUser) {
    return null;
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundaryParent>
            <AppContext.Provider
              value={{ enterPrise, setEnterPrise, userData, setUserData }}
            >
              <Router>
                <Redirect
                  path="/app"
                  from="/app"
                  to={"/app/missions/inquiries"}
                  noThrow
                />
                <Redirect
                  path="/app/missions"
                  from="/app/missions"
                  to={"/app/missions/inquiries"}
                  noThrow
                />
                <Redirect
                  path="/app/pilots"
                  from="/app/pilots"
                  to={"/app/pilots/table"}
                  noThrow
                />
                <Redirect
                  path="/app/admin"
                  from="/app/admin"
                  to={"/app/admin/admin-table"}
                  noThrow
                />
                {/* <MissionCancelled path="/app/missions/cancelled" /> */}
                {/* <SubscriptionPage path="/app/client/subscription-page" /> */}

                {/* --------Clients'page ------------------------------------------------------------------------- 
              --------------------------------------------------------------------------------------------------*/}
                {/* Client's Overview */}
                <ClientsAccount path="/app/client/my-account" />

                {/* Client's my profile */}
                <SinglePageClientInfo path="/app/client/my-profile/:clientId" />
                <ProfileEditContainer path="/app/client/setting/profile/edit" />

                {/* ---Clients'orders ---*/}
                <ClientOrderReviewing path="/app/client/orders/reviewing" />
                <ClientOrderConfirmed path="/app/client/orders/confirm" />
                <ClientOrderActive path="/app/client/orders/inProgress" />
                <ClientOrderCompleted path="/app/client/orders/completed" />
                <ClientOrderCancelled path="/app/client/orders/cancelled" />
                <ClientOrderRejected path="/app/client/orders/rejected" />

                {/* --Client's projects */}
                <DraftProject path="/app/client/projects/draft-project" />
                <InitialProject path="/app/client/projects/initial-project" />
                <ActiveProject path="/app/client/projects/active" />
                <CompletedProject path="/app/client/projects/completed" />

                {/* ---Clients'edit project ---*/}
                <EditProject path="/app/client/project/edit/:projectId" />

                {/* ---Clients'view project ---*/}
                <ClientProjectLayout path="/app/client/projects/:projectId" />

                {/* --Client's shared data */}
                <CollaborationList path="/app/client/shared-data" />

                {/* --Client's plans */}
                {/* <MyPlans path="/app/client/plans/my-plans" /> */}
                {/* <SubscriptionPlanWrapper path="/app/client/subscription" /> */}
                <PaymentWrapper path="/app/client/subscription/payment" />

                {/* --Client's data library */}

                <ShowDataLibraryLayout path="/app/client/data-library" />
                <PurchasedDataPage path="/app/client/data-library/purchased" />
                <WishList path="/app/client/data-library/wishlist" />
                <DownloadPurchasedData path="/app/client/datalibrary/download/:missionId" />

                {/* --Client's grouped orders --- */}
                <ClientGroupOrders path="/app/client/group-orders" />

                {/* collaborator's order */}
                <CollaboratorOrder path="/app/collaborator/order/:missionID" />

                {/*------Client's support--------- */}
                <ContactSupport path="/app/support" />

                {/* --------Admin's page ------------------------------------------------------------------------- 
              --------------------------------------------------------------------------------------------------*/}
                {/* Admin's dashboard ---*/}
                <InvoicePageAdmin path="/app/admin/dashboard/invoices" />
                <SingleInvoiceAdmin path="/app/admin/dashboard/invoices/:invoiceId" />
                <AdminContractPage path="/app/admin/dashboard/contracts" />
                <AdminSingleContract path="/app/admin/dashboard/contracts/:id" />

                {/* Admin's pages ---*/}
                <AdminStart path="/app/admin/admin-table" />
                <ApiKeysPage path="/app/admin/api-keys" />
                <SingleApiKey path="/app/admin/api-keys/:apiKeyId" />
                <DailyReportsPage path="/app/admin/daily-reports" />
                <SingleDailyReport path="/app/admin/daily-reports/:reportId" />
                <FeedbackPage path="/app/admin/feedbacks" />
                <SingleFeedback path="/app/admin/feedbacks/:feedbackId" />
                {/* ----Client Information including Profile, All Orders and Projects in ADMIN PANEL----- */}
                <SinglePageClientInfo path="/app/admin/manage-client/:clientId" />
                {/* admin's news and inspirations */}
                <NewsPage path="/app/admin/news" />
                <SingleNewsPage path="/app/admin/news/:newsId" />
                <EditCreateNews path="/app/admin/news/add" />
                <UpdateNews path="/app/admin/news/edit/:newsId" />
                <InspirationPage path="/app/admin/inspirations" />
                <SingleInspirationPage path="/app/admin/inspirations/:inspirationId" />
                <EditCreateInspiration path="/app/admin/inspirations/add" />
                <UpdateInspiration path="/app/admin/inspirations/edit/:inspirationId" />

                {/* Admin's drone operators table dashboard ---*/}
                <PilotTable path="/app/pilots/table" />
                <PilotDashboard path="/app/pilots/dashboard" />
                <SinglePilot path="/app/pilots/:id" />
                <EditPilot path="/app/pilots/:id/edit" />

                {/* Admin's missions ---*/}
                <AdminInitialClientOrder path="/app/missions/client-order/initial-client-order" />
                <AdminUnassignedClientOrder path="/app/missions/client-order/unassigned" />
                <AdminActiveClientOrder path="/app/missions/client-order/active" />
                <AdminCompletedClientOrder path="/app/missions/client-order/completed" />
                <AdminArchivedClientOrder path="/app/missions/client-order/archive" />
                <AdminCancelledClientOrder path="/app/missions/client-order/cancelled" />
                <AdminRejectedClientOrder path="/app/missions/client-order/rejected" />

                {/* Admin's projects ---*/}
                <DraftProject path="/app/admin/projects/draft-project" />
                <DraftProjectClient path="/app/admin/projects/draft-client-project" />
                <InitialProject path="/app/admin/projects/initial-project" />
                <ActiveProject path="/app/admin/projects/active" />
                <CompletedProject path="/app/admin/projects/completed" />
                <ArchiveProject path="/app/admin/projects/archive" />
                <ProjectSites path="/app/admin/projects/sites-list" />
                <EditProject path="/app/admin/project/edit/:projectId" />
                <AdminProjectLayout path="/app/admin/projects/:projectId" />

                {/* ---Other missions admins---*/}
                <AdminTestMission path="/app/missions/test-missions" />
                <AdminChallengeMission path="/app/missions/challenge-missions" />
                <AdminGwrMission path="/app/missions/gwr-missions" />
                <AdminMissionArchive path="/app/missions/archive" />

                {/* ---Other tools and resources---*/}
                <Tools path="/app/admin/tools" />
                <SalesAssistant path="/app/admin/tools/sales" />
                <CountryProfile path="/app/admin/tools/country-profile" />
                <AppStatistics path="/app/admin/tools/statistics" />
                <AddClients path="/app/admin/tools/addClients" />
                <DocumentsAndGuides path="/app/admin/tools/documents-and-guides" />

                {/* --------Operator's page ------------------------------------------------------------------------- 
              --------------------------------------------------------------------------------------------------*/}
                {/* dahsboard */}
                <Dashboard path="/app/dashboard/info" />

                {/* ---Operators'missions---*/}
                <OperatorMissionsInquiries path="/app/missions/inquiries" />
                <OperatorMissionsActive path="/app/missions/active" />
                <OperatorMissionsCompleted path="/app/missions/completed" />
                <OperatorMissionsMissed path="/app/missions/missed" />
                <OperatorMissionsRejected path="/app/missions/rejected" />

                {/* ---Operators'projects---*/}
                <PilotProjectLayout path="/app/pilots/project/:projectId" />
                <PilotProjectTableLayout path="/app/pilots/all/projects" />

                {/* operator's data library */}
                <ShowDataLibraryLayout path="/app/pilot/data-library" />
                <OperatorListIndex path="/app/pilot/mylisted-data" />
                <SellYourData path="/app/missions/mission-globhe" />

                {/* operator's my resources page */}
                <BankPage path="/app/my-profile/bank-details" />
                <InvoicePagePilot path="/app/my-profile/invoices" />
                <PilotSingleInvoice path="/app/my-profile/invoices/:invoiceId" />
                <ContractPage path="/app/my-profile/contracts" />
                <SingleContract path="/app/my-profile/contracts/:id" />

                {/* operator's my benefits page */}
                <PilotBenefitsPage path="/app/pilots/my-benefits" />

                {/* --------BOTH ADMINS AND OPERATORS ------------------------------------------------------------------------- 
              --------------------------------------------------------------------------------------------------*/}
                {/* my profile for both admins and pilots*/}
                <MyProfile path="/app/my-profile" />
                <EditProfile path="/app/my-profile/edit" />

                {/* admins & pilots single missions*/}
                <SingleMission path="/app/missions/:missionID" />

                {/* ---Operators'submissions admins---*/}
                <OperatorSubmissionActive path="/app/missions/operator-submission/active" />
                <OperatorSubmissionCompleted path="/app/missions/operator-submission/completed" />
                {/* check this */}

                {/* -----Common pages for clients operators and admins */}
                {/* ------- Create edit order pages */}
                <CreateOrder path="/app/order/create-order" />
                <EditSingleLocationOrder path="/app/single-order/edit/:orderId" />
                <EditMultiLocationOrder path="/app/multi-order/edit/:orderId" />

                {/* --------- data library ----------- */}
                <SingleOrderDataLibraryLayout path="/app/client/data-library/:missionId" />
                <SingleOrderDataLibraryLayout path="/app/pilot/data-library/:missionId" />

                {/* ---------mission details testing-------- */}
                <ClientSingleMissionPage path="/app/client/orderDetails/:missionId" />

                {/* Client EnterPrise Page */}
                <EnterPriseTeamLayout path="/app/client/team" />
                <TeamMemberLayout path="/app/client/team/member/:memberId" />
                <EditTeamProfileLayout path="/app/client/team/member/edit/:memberId" />
                <EnterpriseClientLayout path="/app/client/enterprise" />

                {/* */}
                <EnterpriseClientAdminLayout path="/app/admin/client-enterprise" />
                <AdminEnterpriseLayout path="/app/admin/client-enterprise/enterprise/:enterpriseId" />
              </Router>
            </AppContext.Provider>
          </ErrorBoundaryParent>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
