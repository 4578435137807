import React, { useState, useEffect } from "react";
import firebase from "src/firebase";
import { Box, Typography, Grid, Divider } from "@mui/material";
import Layout from "../../../../../Layout";
import AppButton from "../../../../global/elements/AppButton";
import theme from "../../../../../theme";
import { useSnackbar } from "notistack";
import Tips from "../../../../global/elements/Tips";
import { getUser } from "../../../../../services/auth";
import { userRoles } from "../../../../../lib/constants";
import { Formik, Form } from "formik";
import TextFieldInput from "../../../../global/FormInputComponents/TextFieldInput";
import * as Yup from "yup";
import {
  adminCreateClient,
  getAdminCreatedClients,
} from "../../../../../services/client";
import SingleClientContainer from "./SingleClientContainer";

const styles = {
  exportPilots: {
    padding: { xs: "10px 20px" },
    marginBottom: "30px",
    background: theme.palette.grey.white,
    marginTop: "10px",
    width: "100%",
  },
  adminTitle: { paddingBottom: "10px" },
  grid: {
    width: "40vw",
  },
  title: {
    marginBottom: "10px",
  },
  noDataText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const AddClients = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [clientData, setClientData] = useState(null);
  const userRole = getUser("user").role;
  const isAdmin = userRole === userRoles.admin;
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAdminCreatedClients(firebase);
      setClientData(data);
    };

    fetchData();
  }, [reload]);

  const initialData = {
    clientName: "",
    companyName: "",
    phone: "",
  };
  const validationSchema = Yup.object().shape({
    clientName: Yup.string().required("Full name is required"),
    companyName: Yup.string().required("Company name is required"),
    phone: Yup.number()
      .matches(/^\+?[0-9\s-]{7,15}$/, "Enter a valid phone number")
      .nullable()
      .required("Phone number is required"),
  });

  const saveClientDetails = async (values, setSubmitting) => {
    adminCreateClient(firebase, values)
      .then(() => {
        enqueueSnackbar("Your new client profile has been saved", {
          variant: "success",
        });
        refreshPage();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error in saving Profile", {
          variant: "error",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const refreshPage = () => {
    setReload(!reload);
  };

  return (
    <Layout
      backButtonPageName={"Add clients"}
      hasSideMenu
      addBottomPadding
      backButton
    >
      {isAdmin ? (
        <>
          <Tips content="Here you can add client info for those who are not registered in the platform. Then you can easily create an order from your login for this client." />
          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
            {clientData && clientData?.length === 0 ? (
              <Grid item xs={12} md={6} sx={styles.noDataText}>
                <Typography variant="h5">
                  No client data is saved yet
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Existing clients outside the platform
                </Typography>
                <Divider />
                {clientData?.map((client, index) => (
                  <Box key={index}>
                    <SingleClientContainer
                      refreshPage={refreshPage}
                      client={client}
                      index={index}
                    />
                  </Box>
                ))}
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" sx={styles.title}>
                Insert a new client
              </Typography>
              <Formik
                initialValues={initialData}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  return new Promise(() => {
                    saveClientDetails(values, setSubmitting);
                  });
                }}
              >
                {({ values, setFieldValue, isSubmitting }) => {
                  return (
                    <Form id="newClientAddedByAdmin">
                      <Grid container spacing={2} justifyContent={"flex-end"}>
                        <Grid item xs={12}>
                          <TextFieldInput
                            name="clientName"
                            label="Full Name"
                            type="text"
                            setFieldValue={setFieldValue}
                            required={true}
                            values={values}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldInput
                            name="companyName"
                            label="Company Name"
                            type="text"
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldInput
                            name="phone"
                            label="Phone Number"
                            type="tel"
                            setFieldValue={setFieldValue}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AppButton
                            type="submit"
                            form="newClientAddedByAdmin"
                            look="blue"
                            label="Submit"
                            submittingState={isSubmitting}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography
          variant="h4"
          sx={{ marginTop: "20vh", textAlign: "center" }}
        >
          This page is not available at the moment
        </Typography>
      )}
    </Layout>
  );
};

export default AddClients;
