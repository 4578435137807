import React from "react";
import { useGetProjectList } from "src/hooks/useGetProjectList";
import { getUser } from "../../../../services/auth";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import GlobalTable from "../../admin/GlobalTable";
import { getProjectStatusLabelData } from "../../../../lib/helpers";
import { missionStatusLabels, userRoles } from "../../../../lib/constants";
import { navigate } from "gatsby";
import StatusBadge from "../../../global/elements/StatusBadge";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import { listLoader, listLoaderParent } from "../../../../lib/styleConstants";
import FilterProject from "../FilterProject";

const styles = {
  columns: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  filter: {
    marginTop: "10px",
  },
  nameFilter: {
    paddingTop: "15px",
    width: { xs: "100%", md: "250px" },
    margin: { xs: 0, sm: 0 },
  },
  typeFilter: {
    marginRight: { xs: 0, md: "50px" },
    width: { xs: "100% !important", sm: "100% !important" },
  },
  titleHolder: {
    paddingTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  countBadge: {
    marginLeft: "10px",
    float: "left",
    borderRadius: "50%",
    color: theme.palette.grey.white,
    textAlign: "center",
    minWidth: "23px",
    padding: "5px 10px",
    fontWeight: "500",
    marginTop: "2px",
    backgroundColor: theme.palette.primary.main,
  },
  badgeActive: {
    backgroundColor: theme.palette.primary.main,
  },
  badgeUnassigned: {
    backgroundColor: theme.palette.primary.main,
  },
  titleContainer: {
    position: "relative",
    maxWidth: "100%",
    padding: { lg: "0 20px", md: "0 20px", sm: "0 20px", xs: "0 15px" },
  },
  projectContainer: {
    width: "100%",
    padding: "0px",
  },
  contentWrapper: {
    width: "100%",
    display: "flex",
    padding: "30px 0",
  },
  info_message: {
    fontSize: { xs: "12px", md: ".73864rem" },
    textAlign: "center",
    color: theme.palette.status.errorDark,
  },
  status_mark: {
    float: "left",
    width: "8px",
    height: "8px",
    margin: "5px",
    borderRadius: "50%",
  },
  paymentStatusRow: {
    display: "flex",
    width: "90px",
    marginBottom: "2px",
    "& h6": {
      width: "auto !important",
    },
  },
  badgeButton: {
    height: "30px",
    width: "206px",
    textAlign: "center",
    borderRadius: "10px",
    paddingTop: "5px",
    fontWeight: 500,
  },
  unAuthorized: {
    width: "100%",
    marginTop: "10rem",
  },
  fontWeightStyle: {
    fontWeight: { xs: 200 },
  },
};

const ProjectPage = ({ status, columns, enterpriseId }) => {
  const getStatus = status;
  const currentUser = getUser("user");

  const {
    projects,
    isLoading,
    limit,
    limitHandler,
    sortByHandler,
    paginationBtnClick,
    isLastPage,
    valueChangeHandler,
    currentPage,
    lastPageNumber,
  } = useGetProjectList(
    getStatus,
    currentUser.role,
    currentUser.userID,
    enterpriseId
  );

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "status": {
        const statusLabel = getProjectStatusLabelData(value, currentUser);
        return (
          <Box>
            <StatusBadge
              name={
                statusLabel.label === missionStatusLabels.correction
                  ? missionStatusLabels.inProgress
                  : statusLabel.label
              }
              status={statusLabel.statusColor}
            />
            <Box
              dangerouslySetInnerHTML={{
                __html: statusLabel.info,
              }}
              sx={styles.info_message}
            />
          </Box>
        );
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    if (currentUser?.role == userRoles.client) {
      navigate(`/app/client/projects/${value.id}`);
    } else {
      navigate(`/app/admin/projects/${value.id}`);
    }
  };

  return (
    <Box sx={styles.projectContainer}>
      <Box sx={styles.filter}>
        <FilterProject
          valueChangeHandler={valueChangeHandler}
          projectTypeData={getStatus}
          role={currentUser?.role}
        />
      </Box>

      {isLoading ? (
        <Box sx={{ marginTop: "30vh" }}>
          <UniversalLoader />
        </Box>
      ) : (
        <GlobalTable
          columns={columns}
          allData={projects}
          valueConvertHandler={valueConvertHandler}
          goToPageFcn={goToPageFcn}
          loading={isLoading}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPage}
          isLastPage={isLastPage}
          lastPage={lastPageNumber}
          sortByHandler={sortByHandler}
        />
      )}
    </Box>
  );
};

ProjectPage.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  enterpriseId: PropTypes.string,
};
ProjectPage.defaulProps = {
  enterpriseId: null,
};

export default ProjectPage;
