import { Box, Stepper, Step, StepLabel } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../theme";
import { getUser } from "../../../services/auth";
import {
  missionStatus,
  packageStatuses,
  PilotPackageStatus,
  splitActiveMissionStatuses,
  unConfirmedMissionStatuses,
  userRoles,
} from "../../../lib/constants";
import {
  QontoConnector,
  QontoStepIcon,
} from "src/components/global/elements/MissionStepper";

const styles = {
  root: {
    width: "100%",
  },
};

const missionStatusSteps = [
  "Unconfirmed Order",
  "Confirmed Order",
  "Active Order",
  "Data Quality Check",
  "Completed",
];

const getStatusStep = (mission) => {
  const { status, pilotPackageStatus } = mission;
  if (status === missionStatus.initialClientOrder) return 0;
  if (unConfirmedMissionStatuses.includes(status)) return 1;
  if (
    status === missionStatus.active &&
    pilotPackageStatus === PilotPackageStatus.pendingReview
  ) {
    return 3;
  }
  if (splitActiveMissionStatuses.includes(status)) return 2;
  if (packageStatuses.includes(status)) return 3;
  if (status === missionStatus.completed) return 4;
  return 0;
};

const MissionStatusBar = ({ mission }) => {
  const userRole = getUser("user").role;

  return (
    <Box sx={styles.root}>
      <Stepper
        alternativeLabel
        activeStep={getStatusStep(mission)}
        connector={<QontoConnector userType={userRole} />}
      >
        {missionStatusSteps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                StepIconProps={{
                  color:
                    userRole === userRoles.admin
                      ? theme.palette.adminBlue.main
                      : userRole === userRoles.pilot
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main,
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Box>
  );
};

MissionStatusBar.propTypes = {
  mission: PropTypes.array.isRequired,
};

export default MissionStatusBar;
