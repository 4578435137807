import { Box } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import firebase from "src/firebase";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  missionStatus,
  paymentStatus,
  jpgFileInfo,
  orderLocationType,
  formTypeConstant,
  missionTypeConstant,
  userRoles,
  missionFeasibility,
  notificationType,
  otherRequirementsString,
} from "../../../lib/constants";
import {
  getISODate,
  mapFormValuesToMission,
  updateMission,
  updateMissionPlanning,
} from "../../../services/missions";
import theme from "../../../theme";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AppButton from "../../global/elements/AppButton";
import FormErrors from "../../global/forms/FormErrors";
import { getUser } from "../../../services/auth";
import OrderDetails from "../CreateOrder/Components/OrderDetails";
import ChooseProducts from "../CreateOrder/Components/ChooseProducts";
import SpecifyLocationSingleOrder from "../CreateOrder/Components/SpecifyLocationSingleOrder";
import AdvanceOptions from "../CreateOrder/Components/AdvanceOptions";
import ObjAndDesc from "../CreateOrder/Components/ObjAndDesc";
import ObjAndDescOperator from "../CreateOrder/Components/ObjAndDescOperator";
import ClientDetail from "../CreateOrder/Components/ClientDetail";
import MissionContext from "./NewMissionDetails/components/MissionContext";
import { createNotification } from "../../../services/notification";
import ChooseSensors from "../CreateOrder/Components/ChooseSensors";
import { useSnackbar } from "notistack";

const styles = {
  root: {
    flex: "1 1 auto",
    backgroundColor: theme.palette.grey.white,
  },
  buttonRow: {
    display: "flex",
    justifyContent: { xs: "space-between", md: "flex-end" },
    width: "100%",
    flexWrap: "wrap",
  },
  topRow: {
    display: "flex",
    justifyContent: { xs: "space-between", md: "flex-end" },
    width: "100%",
  },
  buttonConainer: {
    display: "flex",
    flexFlow: { xs: "column", sm: "row" },
    justifyContent: "space-between",
  },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
};

const URL =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const DroneMapRequestSchema = Yup.object().shape({
  missionType: Yup.string().required("Mission type is required"),
  clientName: Yup.string().when(["missionType"], {
    is: (missionType) =>
      missionType === missionTypeConstant.clientOrder ||
      missionType === missionTypeConstant.projectMission,
    then: Yup.string().required("Client name is required"),
  }),
  clientEmail: Yup.string().when(["missionType"], {
    is: (missionType) =>
      missionType === missionTypeConstant.clientOrder ||
      missionType === missionTypeConstant.projectMission,
    then: Yup.string().email().required("Email name is required"),
  }),
  clientCompanyName: Yup.string().when(["missionType"], {
    is: (missionType) =>
      missionType === missionTypeConstant.clientOrder ||
      missionType === missionTypeConstant.projectMission,
    then: Yup.string().required("Company name is required"),
  }),
  clientPhone: Yup.number().positive(),
  missionName: Yup.string().required("Mission name is required"),
  mapTypes: Yup.array()
    .nullable()
    .required(
      "Map types are required because they will be automatically added to the contract"
    ),
  pilotPrice: Yup.number()
    .nullable()
    .when(["allowPilotBidding", "missionType"], {
      is: (allowPilotBidding, missionType) =>
        missionType !== missionTypeConstant.operatorSubmission &&
        !allowPilotBidding,
      then: Yup.number()
        .positive()
        .typeError("Only numbers are allowed")
        .required(
          "Pilot price is required when bidding is off because it will be automatically added to the contract"
        ),
    }),
  packageSize: Yup.number()
    .nullable()
    .positive()
    .typeError("Only numbers are allowed"),
  deadline: Yup.date().nullable(),
  availableUntil: Yup.number().nullable(),
  deliveryDeadline: Yup.date().nullable(),
  subscription: Yup.string().nullable(),
  searchMissionName: Yup.array(),
  paymentStatusClient: Yup.string().nullable(),
  allowInvoice: Yup.boolean(),
  uploadLargeFile: Yup.boolean(),
  paymentStatusPilot: Yup.string().nullable(),
  contractAcceptedViaAdmin: Yup.string(),
  pilotInstructions: Yup.string()
    .nullable()
    .required(
      "Pilot instructions are required because they will be automatically added to the contract"
    ),
  otherRequirements: Yup.string().nullable(),
  missionOwner: Yup.string().nullable().required("Mission owner is required"),
  missionOwnerId: Yup.string().nullable(),
  missionOwnerName: Yup.string().required("Mission type is required"),
  dataLibMissionTitle: Yup.string()
    .nullable()
    .when("notShowInDataLibrary", {
      is: false,
      then: Yup.string().required(
        "Mission title for the data library is required"
      ),
    }),
  dataLibDescription: Yup.string()
    .nullable()
    .when("notShowInDataLibrary", {
      is: false,
      then: Yup.string().required(
        "Description for the data library is required"
      ),
    }),
  dataLibSdgGoals: Yup.array()
    .nullable()
    .when("notShowInDataLibrary", {
      is: false,
      then: Yup.array()
        .min(1, "A minimum of one SDG goal is required ")
        .required("SDG goals are required"),
    }),
  dataLibCategories: Yup.array()
    .nullable()
    .when("notShowInDataLibrary", {
      is: false,
      then: Yup.array()
        .min(1, "A minimum of one category is required ")
        .required("Data library categories are required"),
    }),
  redirectUrl: Yup.string().nullable().matches(URL, "Enter correct url!"),
  //advanced options
  flightHeight: Yup.number().nullable(),
  frontOverlap: Yup.number().nullable(),
  sideOverlap: Yup.number().nullable(),
  spatialResolution: Yup.number().nullable(),
  flightSpecificationPdfUrl: Yup.string().nullable(),
  droneType: Yup.string().nullable(),
  sensors: Yup.string().nullable(),
  crs: Yup.string().nullable(),
  verticalAccuracy: Yup.number().nullable(),
  gcp: Yup.bool().nullable(),
  processingSoftware: Yup.string().nullable(),
  //point of contact values
  pocPhone: Yup.string().nullable(),
  pocName: Yup.string().nullable(),
});

const completeDroneMapRequestSchema = Yup.object().shape({
  redirectUrl: Yup.string().matches(URL, "Enter correct url!").nullable(),
});

const ClientValidationSchema = Yup.object().shape({
  missionName: Yup.string()
    .trim()
    .max(50, "Must be maximum 50 characters long")
    .required("Order or site name is required"),
  location: Yup.string().nullable().required("Country is required"),
  mapTypes: Yup.array(),
  startDate: Yup.date()
    .nullable()
    .when("recurrence", {
      is: true,
      then: Yup.date().nullable().required("Start date is required"),
    }),
  intervalEndOn: Yup.bool(),
  deadline: Yup.date()
    .nullable()
    .when(["recurrence", "startDate"], (recurrence, startDate) => {
      if (startDate && !recurrence) {
        return Yup.date()
          .required("End date is required")
          .min(
            startDate,
            "The deadline should be later than the starting date"
          );
      }
    }),
  pocName: Yup.string().nullable(),
  description: Yup.string().trim(),
  guidelinesPdfUrl: Yup.string().when("description", {
    is: (description) => !description,
    then: Yup.string().required(
      "Please fill the description or upload guideline pdf"
    ),
    otherwise: Yup.string(),
  }),
  pocPhone: Yup.string().nullable(),
  recurrence: Yup.bool(),
});

const isDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || !obj1 || !obj2) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2).filter((key) => key !== "enterPriseId");

  if (keys1.length !== keys2.length) return false;

  return keys1.every(
    (key) => keys2.includes(key) && isDeepEqual(obj1[key], obj2[key])
  );
};

const MissionDetailsEdit = ({
  missionData,
  submitMissionHandler,
  onEditingFinished,
}) => {
  const missionContext = useContext(MissionContext);
  const { missionPlanning } = missionContext;
  const [open, setOpen] = useState(false);
  const [mapSaved, setMapSaved] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getUser("user");

  const missionUpdatedAction = (dataToSend) => {
    const pilotMissionUpdatedMail = firebase
      .functions()
      .httpsCallable("pilotMissionUpdatedMail");
    pilotMissionUpdatedMail(dataToSend);

    const adminMissionUpdatedMail = firebase
      .functions()
      .httpsCallable("adminMissionUpdatedMail");
    adminMissionUpdatedMail(dataToSend);
  };

  const onSubmitMission = (values, { setSubmitting }) => {
    if (!values.locationMap && !values.areaSize && !values.kmlUrl) {
      setErrorDisplay(true);
      setSubmitting(false);
      return;
    }

    if (values.mapTypes) {
      values.mapTypes = values.mapTypes.filter(
        (mapType) => mapType !== jpgFileInfo?.label
      );
    }

    if (values.clientCompanyName) {
      values.companyName = values.clientCompanyName;
    }

    if (values.missionPrice) {
      values.pilotPrice = values.missionPrice;
    }

    let updates = mapFormValuesToMission(values);

    if (!missionData?.biddingEndDate && values.biddingEndDate) {
      if (new Date() < new Date(values.biddingEndDate)) {
        updates = {
          ...updates,
          biddingEndDate: getISODate(values.biddingEndDate),
        };
      } else {
        enqueueSnackbar(
          "You cannot update the bidding end date to an earlier date than the current one.",
          { variant: "error" }
        );
        setSubmitting(false);
        return;
      }
    } else if (missionData?.biddingEndDate && values.biddingEndDate) {
      if (
        new Date(values.biddingEndDate) >
          new Date(missionData.biddingEndDate) &&
        [
          missionStatus.pilotInvitedForBidding,
          missionStatus.biddingEnded,
          missionStatus.biddingOngoing,
        ].includes(missionData.status)
      ) {
        updates = {
          ...updates,
          status: missionStatus.biddingOngoing,
          biddingEndDate: getISODate(values.biddingEndDate),
        };
      } else if (
        new Date(values.biddingEndDate) > new Date(missionData.startDate)
      ) {
        updates = {
          ...updates,
          biddingEndDate: getISODate(values.biddingEndDate),
        };
      } else {
        updates = {
          ...updates,
          biddingEndDate: getISODate(missionData.biddingEndDate),
        };
      }
    }

    // Update the mission
    updateMission(firebase, missionData.id, updates)
      .then(() => {
        if (
          missionPlanning?.data &&
          missionPlanning?.data?.missionFeasible === missionFeasibility.no
        ) {
          updateMissionPlanning(missionData?.id, missionPlanning?.data?.id, {
            missionFeasible: missionFeasibility.review,
          });

          if (missionData?.missionType === missionTypeConstant.clientOrder) {
            const dataToSend = {
              missionName: missionData?.missionName,
              missionId: missionData?.id,
              operatorName: missionData?.assignedPilotName,
              clientName: missionData?.clientName,
              pilotId: missionData?.assignedPilot,
            };
            missionUpdatedAction(dataToSend);
          }
          //Pilot notification
          createNotification(
            firebase,
            missionData?.assignedPilot,
            "Order has been updated as per your request.",
            "Order has been updated as per your request.",
            `/app/missions/${missionData.id}`
          );

          if (currentUser.role === userRoles.admin) {
            createNotification(
              firebase,
              missionData?.clientId,
              "Order has been updated by the globhe.",
              "Order has been updated by the globhe.",
              `/app/client/orderDetails/${missionData.id}`
            );
          } else {
            createNotification(
              firebase,
              notificationType.adminNotification,
              "Order has been updated by the client.",
              "Order has been updated by the client.",
              `/app/missions/${missionData.id}`
            );
          }
        }
        submitMissionHandler(true);
      })
      .catch((err) => {
        submitMissionHandler(false, err.message);
        enqueueSnackbar(`Error updating mission: ${err.message}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setSubmitting(false);
        onEditingFinished();
      });
  };

  const initialValues = useMemo(
    () => ({
      missionName: missionData?.missionName || "",
      description: missionData?.description || "",
      missionType: missionData?.missionType || "",
      startDate: missionData?.startDate,
      searchMissionName: missionData?.searchMissionName || [],
      pilotPrice: missionData?.pilotPrice || 0,
      missionPrice: missionData?.pilotPrice,
      packageSize: missionData?.packageSize,
      orderPrice: missionData?.orderPrice,
      mapTypes: [jpgFileInfo.label, ...(missionData.mapTypes || [])],
      biddingEndDate: missionData?.biddingEndDate || null,
      droneSensors: missionData?.droneSensors || [],
      rtkRequire: missionData?.rtkRequire || false,
      clientId: missionData?.clientId || "",
      clientCompanyName: missionData?.companyName || "",
      clientName: missionData?.clientName || "",
      availableUntil: missionData?.availableUntil || null,
      deadline: missionData?.deadline
        ? DateTime.fromISO(missionData.deadline)
        : null,
      deliveryDeadline: missionData?.deliveryDeadline
        ? DateTime.fromISO(missionData?.deliveryDeadline)
        : missionData?.deadline
        ? DateTime.fromISO(missionData?.deadline)
        : null,
      subscriptionEnd: missionData?.subscriptionEnd
        ? DateTime.fromISO(missionData?.subscriptionEnd)
        : null,
      clientEmail: missionData?.clientEmail || "",
      clientPhone: missionData?.clientPhone || "",
      locationLabel: missionData?.locationLabel || "",
      subscription: missionData?.subscription || "",
      droneRequirements: missionData?.droneRequirements,
      paymentStatusPilot: missionData?.paymentStatusPilot,
      paymentStatusClient: missionData?.paymentStatusClient,
      contractAcceptedViaAdmin: missionData.contractAcceptedViaAdmin || false,
      pilotInstructions: missionData.pilotInstructions || "",
      otherRequirements:
        missionData.otherRequirements || otherRequirementsString,
      allowInvoice: missionData.allowInvoice || false,
      uploadLargeFile: missionData.uploadLargeFile || false,
      guidelinesPdfUrl: missionData.guidelinesPdfUrl || "",
      kmlFile: missionData.kmlFile || "",
      missionOwnerId: missionData.missionOwnerId || "",
      missionOwnerName: missionData.missionOwnerName || "",
      missionOwner: missionData.missionOwner || "",
      notShowInDataLibrary: missionData.notShowInDataLibrary || false,
      freeToDownload: missionData.freeToDownload || false,
      allowPilotBidding: !missionData.allowPilotBidding,
      dataLibMissionTitle: missionData.dataLibrary?.missionTitle || "",
      dataLibDescription: missionData.dataLibrary?.description || "",
      dataLibSdgGoals: missionData.dataLibrary?.sdgGoals || [],
      dataLibCategories: missionData.dataLibrary?.categories || [],
      redirectUrl: missionData.dataLibrary?.redirectUrl || "",
      orderType: missionData.orderType || null,
      subscriptionId: missionData.subscriptionId || null,
      status: missionData.status || null,
      allowEmptyData: missionData.allowEmptyData || false,
      breakInvoice: missionData.breakInvoice || false,
      preDeliverypaymentStatusClient:
        missionData.preDeliverypaymentStatusClient || paymentStatus.unpaid,
      //advanced options values
      flightHeight: missionData?.advancedOption?.flightHeight || null,
      frontOverlap: missionData?.advancedOption?.frontOverlap || null,
      sideOverlap: missionData?.advancedOption?.sideOverlap || null,
      spatialResolution: missionData?.advancedOption?.spatialResolution || null,
      droneType: missionData?.advancedOption?.droneType || null,
      flightSpecificationPdfUrl: missionData?.flightSpecificationPdfUrl || null,
      sensors: missionData?.advancedOption?.sensors || null,
      crs: missionData?.advancedOption?.crs || null,
      gcp: missionData?.advancedOption?.gcp || null,
      processingSoftware:
        missionData?.advancedOption?.processingSoftware || null,
      verticalAccuracy: missionData?.advancedOption?.verticalAccuracy || null,
      //point of contact values
      pocPhone: missionData?.pointOfContact?.pocPhone || null,
      pocName: missionData?.pointOfContact?.pocName || null,
      location: missionData?.location || null,
      recurrence: missionData?.recurrence ? true : false,
      locationMap: missionData?.locationMap || null,
      areaSize: missionData?.areaSize || 0,
    }),
    [missionData]
  );

  const handleOpen = () => {
    setOpen(true);
    setMapSaved(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const getValidationSchema = () => {
    if (currentUser?.role === userRoles.client) {
      return ClientValidationSchema;
    } else if (missionData.status === missionStatus.completed) {
      return completeDroneMapRequestSchema;
    } else {
      return DroneMapRequestSchema;
    }
  };

  return (
    <Box sx={styles.root}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema()}
          onSubmit={onSubmitMission}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            touched,
            errors,
            submitCount,
            setFieldTouched,
            isSubmitting,
            handleSubmit,
          }) => {
            const isFormDirty = !isDeepEqual(initialValues, values);

            const handleSaveClick = () => {
              if (isFormDirty) {
                handleSubmit();
              } else {
                enqueueSnackbar("Please change some values to enable saving.", {
                  variant: "info",
                });
              }
            };

            return (
              <Form id="missionEditForm">
                {currentUser?.role === userRoles.admin &&
                  (missionData?.missionType ===
                    missionTypeConstant.clientOrder ||
                    missionData?.missionType ===
                      missionTypeConstant.projectMission) && (
                    <ClientDetail
                      values={values}
                      setFieldValue={setFieldValue}
                      formType={formTypeConstant.edit}
                      userType={currentUser}
                    />
                  )}

                <OrderDetails
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  formType={formTypeConstant.edit}
                  touched={touched}
                  currentUser={currentUser}
                  orderCollection="missions"
                  orderStatus={missionData?.status}
                  orderSwitch={orderLocationType.singleLocationOrder}
                  serialNo={currentUser?.role === userRoles.admin ? "2" : "1"}
                  setFieldTouched={setFieldTouched}
                />

                <ChooseSensors
                  numberCount={3}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  hideJpg
                />

                <ChooseProducts
                  mapTypes={values.mapTypes}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  touched={touched}
                  numberCount={
                    currentUser?.role === userRoles.admin ? "3" : "2"
                  }
                />

                <SpecifyLocationSingleOrder
                  orderData={missionData}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  setOpen={setOpen}
                  handleOpen={handleOpen}
                  mapSaved={mapSaved}
                  setMapSaved={setMapSaved}
                  touched={touched}
                  errorDisplay={errorDisplay}
                  setErrorDisplay={setErrorDisplay}
                  formType={formTypeConstant.edit}
                  serialNo={currentUser?.role === userRoles.admin ? "4" : "3"}
                />

                <AdvanceOptions
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  serialNo={currentUser?.role === userRoles.admin ? "5" : "4"}
                />

                <ObjAndDesc
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  serialNo={currentUser?.role === userRoles.admin ? "6" : "5"}
                />

                {currentUser?.role === userRoles.admin && (
                  <ObjAndDescOperator
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    missionData={missionData}
                  />
                )}

                <br />

                <FormErrors
                  className={styles.formErrorsAlert}
                  show={submitCount > 0}
                  errors={Object.values(errors)}
                />

                <Box sx={styles.buttonConainer}>
                  <Box sx={styles.topRow}>
                    <Box sx={styles.buttonRow}>
                      <AppButton
                        onClick={onEditingFinished}
                        label="Cancel"
                        look="inverted"
                        marginRight
                        noIcon
                        isDisabled={isSubmitting}
                      />

                      <AppButton
                        type="button"
                        label="Save"
                        look="black"
                        onClick={handleSaveClick}
                        isDisabled={isSubmitting}
                      />
                    </Box>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </LocalizationProvider>
    </Box>
  );
};

MissionDetailsEdit.propTypes = {
  missionData: PropTypes.object.isRequired,
  submitMissionHandler: PropTypes.func.isRequired,
  onEditingFinished: PropTypes.func.isRequired,
};

export default React.memo(MissionDetailsEdit);
