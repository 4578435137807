import React from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { userRoles } from "../../../../lib/constants";
import MissionMap from "../../../global/elements/missionsMap";
import { useStateIfMounted } from "use-state-if-mounted";
import AppButton from "../../../global/elements/AppButton";
import { downloadUploadedFile } from "../../../../services/project";
import Dialogue from "../../../global/Dialogue";
import theme from "../../../../theme";
import ProjectStatusChart from "./ProjectStatusChart";
const styles = {
  countContainer: {
    marginTop: "20px",
  },
  boxLayout: {
    padding: "10px",
  },
  fullHeight: { height: "82%" },
};

const ProjectStatusAndMap = ({
  role = undefined,
  projectDetails = {},
  missionData = [],
}) => {
  const [status, setStatus] = useStateIfMounted("Waiting to start download...");
  const [showDownload, setShowDownload] = useStateIfMounted(false);

  const fileDownload = async () => {
    const urlsData = await downloadUploadedFile(projectDetails.id);
    setStatus("Downloading...");
    setShowDownload(true);
    try {
      const downloadPromises = urlsData.map((urlDt, index) =>
        downloadFile(urlDt, index)
      );
      await Promise.all(downloadPromises);
      setStatus("All files downloaded successfully");
    } catch (error) {
      setStatus(`Error downloading files: ${error.message}`);
    }
  };
  const downloadFile = async (urlDt, index) => {
    const response = await fetch(urlDt.fileUrl);
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download =
      urlDt.fileName ||
      `downloaded_file_${index + 1}.${urlDt.uploadedFileType}`; // Change the file extension as needed
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(urlBlob);
  };

  return (
    <Box>
      <Grid container sx={styles.countContainer} spacing={2}>
        <Grid item xs={12} lg={6}>
          <ProjectStatusChart projectDetails={projectDetails} />
        </Grid>
        {role === userRoles.admin ? (
          <Grid container item xs={12} lg={6}>
            <Grid item xs={12} sx={styles.fullHeight}>
              <MissionMap missionData={missionData} />
            </Grid>
            <Grid item xs={12}>
              <AppButton
                label={"Download the file uploaded by the client"}
                fullWidth
                onClick={() => fileDownload()}
                submittingState={showDownload}
                loaderColor={theme.palette.grey.white}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} lg={6}>
            <MissionMap missionData={missionData} />
          </Grid>
        )}

        {/* <Grid item xs={12}>
            <AppButton label={"Download uploaded file"} />
          </Grid> */}
      </Grid>
      <Dialogue
        showDialogue={showDownload}
        setShowDialogue={setShowDownload}
        DialogueHeadText={status}
        sumitButtonText={"Ok"}
        onSubmit={() => {
          setShowDownload(false);
          setStatus("Waiting to start download...");
        }}
        submitButtonLook={"primary"}
        bottomDeviderLine={true}
      />
    </Box>
  );
};

ProjectStatusAndMap.propTypes = {
  role: PropTypes.string.isRequired,
  projectDetails: PropTypes.object,
  missionData: PropTypes.array,
};

export default ProjectStatusAndMap;
