import mapboxgl from "!mapbox-gl";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Box, Tooltip } from "@mui/material";
import theme from "../../../theme";
import {
  missionStatus,
  userRoles,
  PilotPackageStatus,
  activeMissionStatuses,
  unConfirmedMissionStatuses,
} from "../../../lib/constants";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { getUser } from "../../../services/auth";
mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2xvYmhlIiwiYSI6ImNrdXptNjlqdDM1dGwzMHM3bWt6ZHVkd20ifQ.IlPFrxSzNDeDB3BQUusayw";

const styles = {
  mapContainer: {
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  marker: {
    border: `1px solid ${theme.palette.black.paleSilver}`,
    color: theme.palette.black.darkSeaBlack,
    padding: "10px",
    textAlign: "center",
    textDecoration: "none",
    fontSize: "14px",
    borderRadius: "50%",
    cursor: "pointer",
    width: "42px",
    height: "42px",
  },
};

const getColor = (missionData) => {
  // not started
  if (missionData) {
    if (
      missionData?.status === missionStatus.unassigned ||
      missionData?.status === missionStatus.confirmedMission ||
      missionData?.status === missionStatus.initialClientOrder ||
      missionData?.status === missionStatus.initialProjectOrder ||
      missionData?.status === missionStatus.draft ||
      unConfirmedMissionStatuses.includes(missionData?.status)
    ) {
      return theme.palette.grey.lightWarmGrey;
    } else if (
      activeMissionStatuses.includes(missionData?.status) &&
      missionData?.pilotPackageStatus === PilotPackageStatus.pendingReview
    ) {
      return theme.palette.status.warningLight;
    } else if (missionData?.status === missionStatus.completed) {
      return theme.palette.primary.pistachio;
    } else if (missionData?.status === missionStatus.missionNotFeasible) {
      return theme.palette.status.warningDark;
    } else {
      return theme.palette.status.warningDark;
    }
  }
  return theme.palette.status.warningDark;
};
const Marker = ({
  children = undefined,
  feature = undefined,
  // onClick,
  // map,
}) => {
  const _onClick = () => {
    // const zoom = map.getZoom();
    // const target = {
    //   center: feature.geometry.coordinates,
    //   zoom: zoom > 0 ? zoom + 2.5 : 2.5,
    // };

    // map.flyTo({
    //   ...target,
    //   duration: 2000,
    //   essential: true,
    // });
    if (feature?.missionData?.id) {
      let role = getUser("user").role;
      if (role === userRoles.admin) {
        navigate(`/app/missions/${feature?.missionData?.id}`);
      } else if (role === userRoles.pilot) {
        navigate(`/app/missions/${feature?.missionData?.id}`);
      } else {
        navigate(`/app/client/orderDetails/${feature?.missionData?.id}`);
      }
    }
  };

  return (
    <Tooltip title={feature?.missionData?.missionName || ""}>
      <button
        onClick={_onClick}
        style={{
          ...styles.marker,
          backgroundColor: getColor(feature.missionData),
        }}
      >
        {children}
      </button>
    </Tooltip>
  );
};

const MapBox = ({ geoJson = {} }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    try {
      if (geoJson && geoJson.features) {
        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/dark-v10",
          center: [30.2176, 12.8628],
          zoom: 0,
        });

        // Render custom marker components
        geoJson.features.forEach((feature, i) => {
          // Create a React ref
          const ref = React.createRef();
          // Create a new DOM node and save it to the React ref
          ref.current = document.createElement("div");
          // Render a Marker Component on our new DOM node
          ReactDOM.render(
            <Marker onClick={markerClicked} feature={feature} map={map}>
              <Box>{i + 1}</Box>
            </Marker>,
            ref.current
          );

          // Create a Mapbox Marker at our new DOM node
          new mapboxgl.Marker(ref.current)
            .setLngLat(feature.geometry.coordinates)
            .addTo(map);
        });

        map.on("load", function () {
          map.resize();
        });
        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), "top-right");

        // Clean up on unmount
        return () => map.remove();
      }
    } catch (e) {
      console.log(e);
    }
  }, [geoJson, geoJson.features]);

  const markerClicked = (title) => {
    window.alert(title);
  };
  return <div style={styles.mapContainer} ref={mapContainerRef} />;
};

MapBox.propTypes = {
  geoJson: PropTypes.object,
};
// MapBox.defaultProps = {
//   geoJson: {},
// };

Marker.propTypes = {
  // onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  feature: PropTypes.object.isRequired,
  // map: mapboxgl.map,
};
// Marker.defaultProps = {
//   onClick: undefined,
//   map: undefined,
// };

export default MapBox;
