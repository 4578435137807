import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  ClientFilterFieldsProject,
  FilterFieldsProject,
  userRoles,
} from "../../../lib/constants";
import { Box, TextField, Autocomplete } from "@mui/material";
import countries from "src/lib/countries";
import AutoCompleteFilter from "../../global/elements/AutoCompleteFilter";
import debounce from "lodash.debounce";

const styles = {
  field: {
    marginBottom: "15px",
    width: "100%",
  },
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0",
  },
  filterContainer: {
    padding: "5px 0",
    width: "100%",
  },
  TextField: {
    height: "20px",
  },
};

const FilterProject = ({
  valueChangeHandler = undefined,
  role = undefined,
}) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setChangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    Id: "",
    "Project Name": "",
    Country: "",
    "Client Name": "",
  });
  const [filterOptions, setFilterOptions] = useState([]);

  const getFilterOption = () => {
    return role === userRoles.client
      ? ClientFilterFieldsProject
      : FilterFieldsProject;
  };

  useEffect(() => {
    let options = getFilterOption();
    setFilterOptions(options);
  }, []);

  const onChangeFilterField = (e, newValue) => {
    setFilterField(newValue);
    setChangedFilterMonitor(!changedFilterMonitor);

    const removedFilters = filterField.filter(
      (filter) => !newValue?.includes(filter)
    );

    if (newValue?.length === 0) {
      removedFilters.forEach((filter) => {
        valueChangeHandler("", filter);
      });
      setFilterData({});
    }
  };

  const debounceHandler = useCallback(
    debounce((value, field) => {
      valueChangeHandler(value, field);
    }, 500),
    [valueChangeHandler, filterField]
  );

  const handleFilterChange = (value, field) => {
    const newData = { ...filterData, [field]: value };
    setFilterData(newData);

    debounceHandler(value, field);
  };

  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText="Select the fields to filter the projects with"
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Project Name") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Project Name"]}
              onChange={(e) =>
                handleFilterChange(e.target.value, "Project Name")
              }
              label="Project Name"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Id"]}
              onChange={(e) => handleFilterChange(e.target.value, "Id")}
              label="Id"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Country") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={countries}
              value={filterData["Country"]}
              onChange={(e, value) => handleFilterChange(value, "Country")}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Country" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Client Name") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Client Name"]}
              onChange={(e) =>
                handleFilterChange(e.target.value, "Client Name")
              }
              label="Client Name"
              sx={styles.field}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

FilterProject.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
  role: PropTypes.string,
};

export default FilterProject;
