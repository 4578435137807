import firebase from "src/firebase";
import { useCallback, useEffect, useRef } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getProjectsList } from "src/services/project";
import { paginationAction } from "src/lib/constants";
import { getUser } from "src/services/auth";

export const useGetProjectList = (status, role, id, enterpriseId) => {
  const user = getUser("user");
  const initialSortBy = { sortBy: "createdAt", sortValue: "desc" };

  const [projects, setProjects] = useStateIfMounted([]);
  const [allProjects, setAllProjects] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useStateIfMounted(true);
  const [limit, setLimit] = useStateIfMounted(10);
  const [filter, setFilter] = useStateIfMounted({});
  const [sortBy, setSortBy] = useStateIfMounted(initialSortBy);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [isLastPage, setIsLastPage] = useStateIfMounted(false);
  const [dataloadedTill, setDataLoadedTill] = useStateIfMounted(0);
  const [authorized, setAuthorized] = useStateIfMounted(true);

  const lastLoadedProjectRef = useRef(null);
  const isFetchingRef = useRef(false); // Prevent duplicate fetches

  const resetDataFcn = useCallback(() => {
    setCurrentPage(1);
    setIsLastPage(false);
    setDataLoadedTill(0);
    setAllProjects([]);
    setLastPageNumber(0);
    lastLoadedProjectRef.current = null;
  }, [
    setAllProjects,
    setCurrentPage,
    setDataLoadedTill,
    setIsLastPage,
    setLastPageNumber,
  ]);

  const limitHandler = useCallback(
    (value) => {
      setLimit(value);
      resetDataFcn();
    },
    [resetDataFcn, setLimit]
  );

  const sortByHandler = useCallback(
    (sort) => {
      resetDataFcn();
      setSortBy((prevSortBy) =>
        sort === prevSortBy.sortBy
          ? {
              sortBy: sort,
              sortValue: prevSortBy.sortValue === "asc" ? "desc" : "asc",
            }
          : { sortBy: sort, sortValue: "asc" }
      );
    },
    [resetDataFcn, setSortBy]
  );

  const paginationBtnClick = useCallback(
    (type) => {
      setCurrentPage((prevPage) => {
        return type === paginationAction.next ? prevPage + 1 : prevPage - 1;
      });
    },
    [setCurrentPage]
  );

  const valueChangeHandler = useCallback(
    (value, field) => {
      resetDataFcn();
      setFilter((prevFilter) => ({ ...prevFilter, [field]: value }));
    },
    [resetDataFcn, setFilter]
  );

  const fetchProjects = useCallback(async () => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;
    setIsLoading(true);

    try {
      if (user?.isAnonymous) {
        setCurrentPage(1);
        setIsLastPage(true);
        setProjects([]);
      } else if (dataloadedTill >= currentPage) {
        const prevPageProjectData = allProjects.slice(
          (currentPage - 1) * limit,
          currentPage * limit
        );
        setProjects(prevPageProjectData);
      } else {
        const activeFilter = Object.entries(filter).reduce(
          (acc, [key, value]) => {
            if (value) {
              acc[key] = value; // Only add non-empty fields
            }
            return acc;
          },
          {}
        );

        const data = await getProjectsList(
          firebase,
          status,
          role,
          id,
          lastLoadedProjectRef.current,
          limit,
          activeFilter,
          sortBy,
          enterpriseId
        );

        const { projects: newProjects, isLast, lastLoaded } = data;

        setProjects(newProjects);
        setAllProjects((prevProjects) => {
          const updatedProjects = newProjects.filter(
            (newProject) =>
              !prevProjects.some((project) => project.id === newProject.id)
          );
          return [...prevProjects, ...updatedProjects];
        });

        if (isLast) setLastPageNumber(currentPage);
        setIsLastPage(isLast);
        lastLoadedProjectRef.current = lastLoaded;
        setDataLoadedTill(currentPage);
        setAuthorized(true);
      }
    } catch (err) {
      console.log("Error", err.message);
      setAuthorized(true);
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
    }
  }, [
    user?.isAnonymous,
    dataloadedTill,
    currentPage,
    allProjects,
    limit,
    status,
    filter,
    sortBy,
    role,
    id,
    enterpriseId,
  ]);

  useEffect(() => {
    if (!firebase) return;
    fetchProjects();
  }, [fetchProjects, limit, filter, sortBy, currentPage]);

  return {
    authorized,
    projects,
    isLoading,
    limit,
    limitHandler,
    sortByHandler,
    paginationBtnClick,
    isLastPage,
    valueChangeHandler,
    currentPage,
    lastPageNumber,
  };
};
