import { Box, Divider } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import theme from "../../../../theme";
import useGetClientAccountDetails from "../../../../hooks/useGetClientAccountDetails";
import firebase from "src/firebase";
import { editClientProfile } from "../../../../services/client";
import OverviewHeader from "../../../global/elements/OverviewHeader";
import SubscriptionOrderDataContainer from "./Components/SubscriptionOrderDataContainer";
import BottomCards from "./Components/BottomCards";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import Layout from "../../../../Layout";
import { getUser } from "../../../../services/auth";
import {
  contactSupportUrl,
  enterPriseUserRole,
  users,
} from "../../../../lib/constants";
import AcceptTermsAndCondition from "./Components/AcceptTermsAndCondition";
import AcceptTermsBanner from "./Components/AcceptTermsBanner";
import DashboardContext from "./Components/DashboardContext";
import { useCookies } from "react-cookie";
import AppContext from "../../../../contexts/AppContext";
import AddTeamMemberBanner from "./Components/AddTeamMemberBanner";
import AddEnterpriseBanner from "./Components/AddEnterpriseBanner";
import CompleteFeedback from "./Components/CompleteFeedback";

const styles = {
  successBanner: { marginTop: "3%", marginBottom: "3%" },
  dividerStyle: {
    marginTop: "25px",
    background: theme.palette.grey.coolGrey,
  },
  universalLoderContainer: {
    marginTop: "20%",
  },
};

const Overview = () => {
  const [
    clientInfo,
    number,
    isLoading,
    dataCollectedInfo,
    subPlanData,
    triggerReload,
    setTriggerReload,
    askFormTerms,
    setAskForTerms,
  ] = useGetClientAccountDetails();

  const appContext = useContext(AppContext);
  const userData = appContext.userData;

  if (clientInfo) {
    appContext?.setUserData(clientInfo);
  }
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [feedbackPopupInitialState, setFeedbackPopupInitialState] =
    useState(false);
  const [dataML, setDataML] = useState([]);
  const [dataSL, setDataSL] = useState([]);
  const [cookies] = useCookies(["enterpriseAccount", "addEnterPrise"]);

  //this is commented since we do not need to have a popup as soon as we sign as this is conflicting with userGuiding
  //to delete also in useGetClientAccountDetails.js

  // useEffect(() => {
  //   setShowTermsPopup(askFormTerms);
  // }, [askFormTerms]);

  const user = getUser(users.user);
  const skipFcn = async () => {
    try {
      const updates = {
        tourFlag: true,
      };
      await editClientProfile(firebase, clientInfo.id, updates);
      setTriggerReload(!triggerReload);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const acceptTerms = (data) => {
    setAskForTerms(false);
    setShowTermsPopup(false);
    setTriggerReload(!triggerReload);
    if (data) {
      appContext?.setUserData(data);
    }
  };

  useEffect(() => {
    if (userData.id) {
      checkFeedBackStatus(userData.id, false);
    }
  }, []);

  const checkFeedBackStatus = async (clientId, skipCheck) => {
    const checkUnRatedMission = firebase
      .functions()
      .httpsCallable("checkUnRatedMission");

    const feedbackStatus = await checkUnRatedMission({ clientId, skipCheck });
    const ordersSL = feedbackStatus?.data?.singleLocationOrders || [];
    const ordersML = feedbackStatus?.data?.multiLocationOrders || [];

    if (
      !feedbackStatus.data.clientSkippedFeedback &&
      (ordersSL?.length > 0 || ordersML?.length > 0)
    ) {
      setFeedbackPopupInitialState(true);
      setDataSL(ordersSL?.map((dt) => dt));
      setDataML(ordersML?.map((dt) => dt));
    }

    return;
  };

  return (
    <Layout hasSideMenu addBottomPadding>
      {!isLoading && clientInfo ? (
        <Box>
          <DashboardContext.Provider value={{ disableButton: askFormTerms }}>
            <OverviewHeader
              headingTextOne={`Welcome${user.isAnonymous ? "" : ","}`}
              headingTextTwo={
                user.isAnonymous
                  ? ""
                  : clientInfo?.name?.split(" ")[0] || "User"
              }
              subText="Need assistance or have questions about your order? We're here to help!"
              actionText="Contact us! We’re happy to help."
              actionLink={contactSupportUrl}
            />

            {!showTermsPopup & askFormTerms ? (
              <Box sx={styles.successBanner}>
                <AcceptTermsBanner onClick={() => setShowTermsPopup(true)} />
              </Box>
            ) : (
              <></>
            )}

            {userData?.roleType == enterPriseUserRole.admin &&
              appContext?.enterPrise &&
              appContext.enterPrise?.id == cookies?.enterpriseAccount && (
                <Box sx={styles.successBanner}>
                  <AddTeamMemberBanner />
                </Box>
              )}

            {!askFormTerms &&
              cookies?.addEnterPrise &&
              cookies?.addEnterPrise == userData?.id && (
                <Box sx={styles.successBanner}>
                  <AddEnterpriseBanner />
                </Box>
              )}

            <SubscriptionOrderDataContainer
              subData={subPlanData}
              number={number}
              collectedData={dataCollectedInfo}
              clientInfo={clientInfo}
              skipFcn={skipFcn}
            />

            <Divider variant="large" style={styles.dividerStyle} />

            {/********************** AFTER HORIZONTAL DIVIDER LINE *************************************/}
            <BottomCards />
            <Divider variant="large" style={styles.dividerStyle} />
            <CompleteFeedback
              feedbackPopupInitialState={feedbackPopupInitialState}
              checkFeedBackStatus={checkFeedBackStatus}
              dataML={dataML}
              dataSL={dataSL}
              setFeedbackPopupInitialState={setFeedbackPopupInitialState}
            />
            {showTermsPopup && (
              <AcceptTermsAndCondition
                openPopup={showTermsPopup}
                setOpenPopup={setShowTermsPopup}
                onSubmit={(data) => acceptTerms(data)}
                clientId={clientInfo?.id}
              />
            )}
          </DashboardContext.Provider>
        </Box>
      ) : (
        <Box sx={styles.universalLoderContainer}>
          <UniversalLoader />
        </Box>
      )}
    </Layout>
  );
};

export default Overview;
