import React from "react";
import MissionPlanningItems from "./MissionPlanningItems";
import { missionPlanningType, userRoles } from "../../../../../lib/constants";
import { getUser } from "../../../../../services/auth";
import AdminClientMissionPlanning from "./AdminClientMissionPlanning";
import { Box } from "@mui/material";

const PreFlight = () => {
  const currentUserRole = getUser("user").role;
  return (
    <Box ml={2}>
      {currentUserRole !== userRoles.pilot ? (
        <AdminClientMissionPlanning tabValue={missionPlanningType.preFlight} />
      ) : (
        <MissionPlanningItems tabValue={missionPlanningType.preFlight} />
      )}
    </Box>
  );
};

export default PreFlight;
