import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import theme from "../../../theme";
import VisibilityIcon from "@mui/icons-material/Visibility";

const styles = {
  flexContainer: {
    display: "flex",
  },
  marginAdjust: { marginRight: "10px" },
  widthAdjust: { width: "fit-content" },
  alignText: { textAlign: "right" },
  margin: { margin: "0px 5px", cursor: "pointer" },
  linkStyle: {
    textDecoration: "none",
    color: theme.palette.black.darkSeaBlack,
  },
};

const UploadFileInfoContainer = ({
  fileName = undefined,
  fileUrl = undefined,
  fileId = undefined,
  deleteFile = undefined,
  showDownload = false,
  showDelete = false,
  showVisibility = false,
}) => (
  <>
    <Grid item xs={8} sx={styles.flexContainer}>
      <InsertDriveFileOutlinedIcon sx={styles.marginAdjust} fontSize="medium" />
      <Typography variant="body1" sx={styles.widthAdjust}>
        {fileName}
      </Typography>
    </Grid>
    <Grid item xs={4} sx={styles.alignText}>
      {showDownload && (
        <Link to={fileUrl} target={"_blank"} style={styles.linkStyle}>
          <FileDownloadOutlinedIcon sx={styles.margin} fontSize="medium" />
        </Link>
      )}
      {showVisibility && (
        <Link to={fileUrl} target="_blank" style={styles.linkStyle}>
          <VisibilityIcon sx={styles.margin} fontSize="medium" />
        </Link>
      )}
      {showDelete && (
        <DeleteForeverOutlinedIcon
          sx={styles.margin}
          onClick={() => deleteFile?.(fileId)}
          fontSize="medium"
        />
      )}
    </Grid>
  </>
);

UploadFileInfoContainer.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  deleteFile: PropTypes.func,
  showVisibility: PropTypes.bool,
  showDelete: PropTypes.bool,
  showDownload: PropTypes.bool,
};

// UploadFileInfoContainer.defaultProps = {
//   deleteFile: undefined,
// };

export default UploadFileInfoContainer;
