const currencyApiKey = process.env.GATSBY_CURRENCY_CONVERT_API_KEY;

export const handleCurrencyConvert = async (
  amount,
  fromCurrency,
  toCurrency
) => {
  try {
    const result = await fetch(
      `https://v6.exchangerate-api.com/v6/${currencyApiKey}/pair/${fromCurrency}/${toCurrency}`
    );
    const data = await result.json();
    const convertedAmount = amount * data?.conversion_rate;
    return convertedAmount.toFixed(2);
  } catch (error) {
    console.error("Error converting currency:", error);
  }
};
