import { Box } from "@mui/material";
import React, { useContext } from "react";
import MissionPilotsList from "../../MissionPilotsList";
import MissionContext from "./MissionContext";
import {
  columnForAssignedPilot,
  columnForProjectMissionAssignedPilot,
  missionListSource,
  missionTypeConstant,
} from "../../../../../lib/constants";
import { deleteProjectContract } from "../../../../../services/contracts";
import { arrayRemove } from "firebase/firestore";
import firebase from "src/firebase";
import { removePilotFromMissionV2 } from "../../../../../services/missions";
import {
  getPilotPackages,
  updatePilotPackage,
} from "../../../../../services/pilot-packages";
import { getCurrentDateTime } from "../../../../../lib/helpers";
import TextStructures from "../../../../global/elements/TypographyElements/TextStructures";
import InvitedPilotsList from "../../InvitedPilotsList";

const AssignedPilotTable = () => {
  const data = useContext(MissionContext);

  const removePilot = async (pilot) => {
    let mission = data.mission;

    await removePilotFromMissionV2(firebase, mission, pilot);

    if (data?.mission?.missionType == missionTypeConstant.projectMission) {
      const missionCountInProjectForPilot = await firebase
        .firestore()
        .collection("missions")
        .where("projectIds", "==", data?.mission?.projectIds)
        .where("assignedPilot", "==", pilot.id)
        .get()
        .then((snapshot) => snapshot.docs);

      if (!(missionCountInProjectForPilot.length > 1)) {
        const projectRef = firebase
          .firestore()
          .collection("projects")
          .doc(data?.mission?.projectIds);
        await projectRef.update({
          associatedDroneOperatorsIds: arrayRemove(pilot.id),
          lastUpdated: currentTime,
        });
      }
      await deleteProjectContract({
        missionId: data?.mission?.id,
        pilotId: pilot.id,
        projectId: data?.mission?.projectIds,
      });
    }
    let pilotPackagesID = await (
      await getPilotPackages(firebase, data?.mission?.id)
    ).filter((res) => {
      return pilot.id == res.pilot;
    });
    const currentTime = getCurrentDateTime();
    const updatePilot = {
      dateUpdated: currentTime,
      isDeleted: true,
    };
    updatePilotPackage(
      firebase,
      data?.mission?.id,
      pilotPackagesID[0]?.id,
      updatePilot
    );
    data?.refreshMission();
    data?.refreshPilotPackages();
  };

  return (
    <Box>
      <TextStructures
        text="Assigned drone operator"
        customVariant="h3"
        marginBottom
      />
      <InvitedPilotsList
        missionData={data?.mission}
        unassignPilotHandler={(pilot) => removePilot(pilot)}
        columnForPilot={
          data?.mission?.missionType == missionTypeConstant.projectMission
            ? columnForProjectMissionAssignedPilot
            : columnForAssignedPilot
        }
        source={missionListSource.assign}
        refreshPage={data?.refreshMission}
        submitMissionHandler={() => data?.refreshMission()}
        onEditingFinished={() => {
          data?.setPageMode(data?.PageModes.DEFAULT);
        }}
      />
    </Box>
  );
};

export default AssignedPilotTable;
