import { Box } from "@mui/material";
import React, { useContext } from "react";
import MissionContext from "./MissionContext";
import firebase from "src/firebase";
import { uninvitePilotToMission } from "../../../../../services/missions";
import {
  columnForInvitedPilot,
  missionListSource,
} from "../../../../../lib/constants";
import TextStructures from "../../../../global/elements/TypographyElements/TextStructures";
import InvitedPilotsList from "../../InvitedPilotsList";

const InvitedPilotsTable = () => {
  const data = useContext(MissionContext);

  const unInvitePilot = async (id) => {
    await uninvitePilotToMission(firebase, id, data?.mission);
    data?.refreshMission();
  };
  return (
    <Box>
      <TextStructures
        text="Invited drone operators"
        customVariant="h3"
        marginBottom
      />
      <InvitedPilotsList
        missionData={data?.mission}
        uninvitePilotHandler={(id) => unInvitePilot(id)}
        columnForPilot={columnForInvitedPilot}
        source={missionListSource.invite}
      />
    </Box>
  );
};

export default InvitedPilotsTable;
