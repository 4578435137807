import PropTypes from "prop-types";
import React from "react";
import { useGetClientOrdersList } from "src/hooks/useGetClientOrdersList";
import { ClientPage, users } from "../../../../lib/constants";
import { navigate } from "gatsby";
import { Box, Typography } from "@mui/material";
import FilterOrders from "./FilterOrders";
import { getUser } from "../../../../services/auth";
import GlobalTable from "../../admin/GlobalTable";
import StatusBadge from "../../../global/elements/StatusBadge";
import { changeMillisToDate } from "../../../../lib/helpers";
import {
  PilotPackageStatus,
  colorStatus,
  statusMap,
} from "../../../../lib/constants";
import UniversalLoader from "src/components/global/elements/UniversalLoader";

const { DateTime } = require("luxon");

const OrdersTable = ({
  status = undefined,
  columns = undefined,
  enterpriseId = null,
}) => {
  const getStatus = status;
  const currentUser = getUser(users.user);

  const {
    orders,
    isLoading,
    limit,
    limitHandler,
    sortByHandler,
    paginationBtnClick,
    isLastPage,
    valueChangeHandler,
    currentPage,
    lastPageNumber,
  } = useGetClientOrdersList(
    getStatus,
    null, // pass substatus here
    enterpriseId
  );

  const valueConvertHandler = (type, value) => {
    const statusData = statusMap[value["status"]];
    switch (type) {
      case "date":
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value["dateCreated"]).toISODate()}
          </Typography>
        );
      case "seconds":
        return (
          <Typography variant="body1">
            {value["dateCompleted"]
              ? changeMillisToDate(value["dateCompleted"])
              : "N/A"}
          </Typography>
        );
      case "array":
        return (
          <Typography variant="body1">
            {value["equipment"] && value["equipment"].length > 0
              ? value["equipment"].toString()
              : "n/a"}
          </Typography>
        );
      case "quote":
        return (
          <Typography variant="body1">
            {value["subscriptionId"] && (
              <StatusBadge status={colorStatus.yellow} name="N/A" />
            )}
            {(!value["subscriptionId"] && value["quoteStatus"] == undefined) ||
              (value["quoteStatus"] == "NOT RECEIVED" && (
                <StatusBadge status={colorStatus.red} name="Not received" />
              ))}
            {!value["subscriptionId"] && value["quoteStatus"] == "Received" && (
              <StatusBadge
                status={colorStatus.green}
                name={value["quoteStatus"]}
              />
            )}
          </Typography>
        );
      case "status":
        return (
          <Typography variant="body1">
            {statusData && (
              <StatusBadge status={statusData.color} name={statusData.name} />
            )}
          </Typography>
        );
      case "pilotMissionStatus":
        return (
          <Typography variant="body1">
            {value["pilotPackageStatus"] == PilotPackageStatus.rejected && (
              <StatusBadge status={colorStatus.yellow} name="Rejected" />
            )}
            {value["pilotPackageStatus"] ==
              PilotPackageStatus.pendingReview && (
              <StatusBadge status={colorStatus.red} name="To review" />
            )}
            {value["pilotPackageStatus"] == PilotPackageStatus.approved && (
              <StatusBadge status={colorStatus.green} name="Approved" />
            )}
            {(!value["pilotPackageStatus"] ||
              value["pilotPackageStatus"] == PilotPackageStatus.open) && (
              <StatusBadge name="Not completed" />
            )}
          </Typography>
        );
      case "reject":
        var reason = value?.reason?.rejectedReason;
        return (
          <Typography variant="body1">
            {reason && reason.length > 0
              ? reason.map((val, i) => <li key={i}>{val}</li>)
              : ""}
          </Typography>
        );
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    navigate(`/app/client/orderDetails/${value.id}`);
  };

  return (
    <Box>
      <Box sx={{ marginTop: "3em" }}>
        <FilterOrders
          valueChangeHandler={valueChangeHandler}
          role={currentUser.role}
          missionTypeData={ClientPage.Orders}
          status={status}
        />
      </Box>

      {isLoading ? (
        <Box sx={{ marginTop: "30vh" }}>
          <UniversalLoader />
        </Box>
      ) : (
        <GlobalTable
          columns={columns}
          allData={orders}
          valueConvertHandler={valueConvertHandler}
          goToPageFcn={goToPageFcn}
          limitHandler={limitHandler}
          limit={limit}
          paginationBtnClick={paginationBtnClick}
          currentPage={currentPage}
          isLastPage={isLastPage}
          lastPage={lastPageNumber}
          sortByHandler={sortByHandler}
        />
      )}
    </Box>
  );
};

OrdersTable.propTypes = {
  status: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  enterpriseId: PropTypes.string,
};

export default OrdersTable;
