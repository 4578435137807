import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment } from "react";
import BackNav from "../../../global/backButton.js";
import PropTypes from "prop-types";
import AppButton from "../../../global/elements/AppButton";
import ProgressBar from "../../../global/ProgressBar";
import theme from "../../../../theme";
import Switcher from "../../../global/Switcher";
import {
  projectStatus,
  userRoles,
  missionStatus,
} from "../../../../lib/constants";
import { getPercentage } from "../../../../lib/helpers";
import AdminNextAction from "../../missions/NewMissionDetails/components/AdminNextAction.jsx";
import ClientNextActions from "../../missions/NewMissionDetails/components/ClientNextActions.jsx";
import PilotNextActions from "../../missions/NewMissionDetails/components/PilotNextActions.jsx";

const styles = {
  normalText: {
    fontWeight: "200 !important",
  },
  marginTop: {
    marginTop: "30px",
  },
  projectIdContainer: {
    display: "flex",
  },
  textMarginLeft: {
    marginLeft: "2px",
  },
  switchContainer: {
    margin: { xs: "30px 0" },
    float: { sm: "right" },
  },
  statusBanner: {
    margin: { xs: "15px 0" },
    float: { sm: "right" },
  },
  progressBarContainer: {
    dislay: "grid",
    marginTop: { lg: "10%" },
    position: { sm: "absolute" },
    width: "-webkit-fill-available",
    bottom: { sm: "20px" },
  },
  relativePosition: { position: "relative" },
};

const ProjectHeaders = ({
  projectId = null,
  projectName = null,
  completedMissionCount = 0,
  allMissionCount = 0,
  sectionActive = 0,
  setSectionActive = undefined,
  createFlag = false,
  editFlag = false,
  backNavPath = null,
  projectMissions = undefined,
  role,
  projectDetails = undefined,
  lastQuoteStatus = undefined,
}) => {
  const calculateProgress = () => {
    let totalCnt = projectMissions?.filter(
      (data) =>
        data.status ===
        (missionStatus.deleted ||
          missionStatus.archive ||
          missionStatus.rejected ||
          missionStatus.cancelled)
    );
    let validMissions = allMissionCount - totalCnt?.length;
    return getPercentage(completedMissionCount, validMissions).toFixed(1);
  };
  const isClient = role === userRoles.client;
  const isAdmin = role === userRoles.admin;
  const isPilot = role === userRoles.pilot;

  const createBackRouteForProject = () => {
    if (isPilot) {
      return "/app/pilots/all/projects";
    } else if (backNavPath) {
      return backNavPath;
    } else if (projectDetails?.status === projectStatus.completed) {
      if (isAdmin) {
        return "/app/admin/projects/completed";
      } else if (isClient) {
        return "/app/client/projects/completed";
      }
    } else if (projectDetails?.status === projectStatus.active) {
      if (isAdmin) {
        return "/app/admin/projects/active";
      } else if (isClient) {
        return "/app/client/projects/active";
      }
    } else if (projectDetails?.status === projectStatus.archived) {
      if (isAdmin) {
        return "/app/admin/projects/archive";
      } else if (isClient) {
        return "/app/client/projects/initial-project";
      }
    } else {
      if (isAdmin) {
        return "/app/admin/projects/initial-project";
      } else if (isClient) {
        return "/app/client/projects/initial-project";
      }
    }
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12} sm={6}>
        <BackNav routeTo={createBackRouteForProject()} />
        {!createFlag && (
          <>
            <Box sx={{ ...styles.projectIdContainer, ...styles.marginTop }}>
              <Typography variant="body2">PROJECT ID:</Typography>
              <Typography variant="body2" sx={styles.textMarginLeft}>
                {projectId || "project_id"}
              </Typography>
            </Box>
            <Typography variant="h4">
              {projectName || "Project Name"}
            </Typography>
          </>
        )}
        {createFlag && (
          <Typography variant="h5" sx={styles.marginTop}>
            {"Create new project"}
          </Typography>
        )}
        {!editFlag &&
          !createFlag &&
          role != userRoles.pilot &&
          projectDetails?.status != projectStatus.deleted && (
            <AppButton
              look="black"
              label="Edit"
              internalLink
              url={
                role == userRoles.client
                  ? `/app/client/project/edit/${projectId}`
                  : `/app/admin/project/edit/${projectId}`
              }
              addtionalStyle={styles.marginTop}
            />
          )}
      </Grid>

      {!editFlag && !createFlag && (
        <Grid item xs={12} sm={6} sx={styles.relativePosition}>
          {isAdmin && (
            <Box sx={styles.switchContainer}>
              <Switcher
                tabActive={sectionActive}
                setTabActive={setSectionActive}
                buttonText1="Admin dashboard"
                buttonText2="Client dashboard"
                activeBackGround={theme.palette.black.darkSeaBlack}
                inactiveBackground={theme.palette.grey.white}
                activeColor={theme.palette.grey.white}
                inactiveColor={theme.palette.black.darkSeaBlack}
                border={`2px solid ${theme.palette.black.darkSeaBlack}`}
              />
            </Box>
          )}
          <Box sx={styles.progressBarContainer}>
            {projectDetails?.status != projectStatus.draft &&
              projectDetails?.status != projectStatus.deleted &&
              allMissionCount > 0 && (
                <ProgressBar
                  progress={calculateProgress()}
                  textBelow={"PROJECT COMPLETED"}
                  showTextBelow={true}
                />
              )}
          </Box>
        </Grid>
      )}
      {isAdmin && !createFlag && !editFlag && (
        <Grid item xs={12}>
          <AdminNextAction
            projectDetails={projectDetails}
            bigText={true}
            lastQuoteStatus={lastQuoteStatus}
          />
        </Grid>
      )}
      {isClient && (
        <Grid item xs={12}>
          <ClientNextActions
            projectDetails={projectDetails}
            lastQuoteStatus={lastQuoteStatus}
          />
        </Grid>
      )}
      {isPilot && (
        <Grid item xs={12}>
          <PilotNextActions projectDetails={projectDetails} bigText={true} />
        </Grid>
      )}
    </Grid>
  );
};

ProjectHeaders.propTypes = {
  role: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  completedMissionCount: PropTypes.number,
  allMissionCount: PropTypes.number,
  sectionActive: PropTypes.number,
  setSectionActive: PropTypes.func,
  createFlag: PropTypes.bool,
  editFlag: PropTypes.bool,
  backNavPath: PropTypes.string,
  projectMissions: PropTypes.object,
  projectDetails: PropTypes.object,
  lastQuoteStatus: PropTypes.string,
};

// ProjectHeaders.defaultProps = {
//   projectId: null,
//   projectName: null,
//   completedMissionCount: 0,
//   additionalStyles: {},
//   allMissionCount: 0,
//   sectionActive: 0,
//   setSectionActive: undefined,
//   createFlag: false,
//   editFlag: false,
//   backNavPath: null,
//   projectDetails?.status: "draft",
//   projectMissions: undefined,
// };

export default ProjectHeaders;
