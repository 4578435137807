import firebase from "src/firebase";
import { useCallback, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { getPilotPackages } from "../services/pilot-packages";

export const useGetPilotPackages = (missionID) => {
  const [packages, setPackages] = useStateIfMounted();
  const [error, setError] = useStateIfMounted();
  const [loading, setLoading] = useStateIfMounted(false);
  const [refresh, setRefresh] = useStateIfMounted(true);
  const refreshPackages = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    if (!firebase || !refresh) {
      return;
    }

    if (refresh && loading) {
      setRefresh(false);
      return;
    }

    setRefresh(false);
    setLoading(true);

    getPilotPackages(firebase, missionID)
      .then((data) => {
        const pilotPackages = data.sort(function (a, b) {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        });
        setPackages(pilotPackages);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(e);
      });
  }, [missionID, loading, refresh, refreshPackages]);

  useEffect(() => {
    if (!packages) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [packages]);

  return { packages, loading, refreshPackages, error };
};
