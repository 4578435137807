import React, { useEffect } from "react";
import { useGetCollaboratedOrderList } from "../../../../hooks/useGetCollaboratedOrderList";
import { getUser } from "../../../../services/auth";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FilterOrders from "../Orders/FilterOrders";
import {
  ClientPage,
  FilterCollaborationsOrderList,
} from "../../../../lib/constants";
import { navigate } from "gatsby";
import UniversalLoader from "../../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../../global/elements/StatusAlertContainer";
import GlobalTable from "../../admin/GlobalTable";
import StatusBadge from "../../../global/elements/StatusBadge";
import {
  missionStatus,
  PilotPackageStatus,
  colorStatus,
} from "../../../../lib/constants";
import { changeMillisToDate, getInitialFilters } from "../../../../lib/helpers";
import GuestStatusAlert from "../../AnonymousUser/GuestStatusAlert";
const { DateTime } = require("luxon");

const styles = {
  columns: {
    float: "left",
    clear: "none",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 15px",
    marginBottom: "1rem",
  },
  filter: {
    marginTop: "10px",
  },
  missionContainer: {
    width: "100%",
    padding: "0px",
  },
  unAuthorized: {
    width: "100%",
    marginTop: "10rem",
  },
  loaderDiv: {
    paddingTop: "18%",
  },
};

const CollabListPage = ({ columns }) => {
  const currentUser = getUser("user");
  const [
    authorized,
    filteredMissionList,
    isLoading,
    limit,
    setLimit,
    filter,
    setFilter,
    loadHandler,
    setLoadHandler,
    pageData,
    setPagedata,
    setPreviousPage,
    setAllMission,
    pageChanged,
    setPageChanged,
    setDataLoadedTill,
    setLastLoadedMission,
    sortBy,
    setSortBy,
    setFilterorSortTriggered,
  ] = useGetCollaboratedOrderList();

  useEffect(() => {
    valueChangeHandler(
      "InitialLoad",
      "InitialLoad",
      FilterCollaborationsOrderList
    );
  }, []);

  const limitHandler = (value) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    setLimit(value);
    setDataLoadedTill(0);
    setPreviousPage(0);
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setLastLoadedMission(null);
    setPagedata(pageDataNew);
    setFilterorSortTriggered(true);
  };

  const paginationBtnClick = (type) => {
    let pageDataNew = pageData;
    if (type == "next") {
      if (!(pageDataNew.lastPage == pageDataNew["currentPage"])) {
        pageDataNew["currentPage"] = pageDataNew["currentPage"] + 1;
      }
    } else {
      pageDataNew["currentPage"] = pageDataNew["currentPage"] - 1;
    }
    setPageChanged(!pageChanged);
    setPagedata(pageDataNew);
  };

  const sortByHandler = (sort) => {
    setLoadHandler(!loadHandler);
    setAllMission([]);
    setDataLoadedTill(0);
    setLastLoadedMission(null);
    setPreviousPage(0);
    if (sort === sortBy[0]) {
      const order = sortBy[1] === "asc" ? "desc" : "asc";
      setSortBy([sort, order]);
      setFilterorSortTriggered(true);
    } else {
      setSortBy([sort, "asc"]);
      setFilterorSortTriggered(true);
    }
  };

  const valueChangeHandler = (value, field, filterFields) => {
    let pageDataNew = {
      currentPage: 1,
      isLast: false,
      lastPage: null,
    };
    let filterDataNew = filter;
    if ((value == "") & (field == "")) {
      let changedField = Object.keys(filter).filter(
        (fld) => filterFields.indexOf(fld) == -1
      );
      changedField.forEach((chgFld) => {
        filterDataNew[chgFld] = "";
      });
    } else if ((value == "InitialLoad") & (field == "InitialLoad")) {
      const filterObj = getInitialFilters(filterFields);
      let filterDataSaved = filterObj?.filters || {};
      if (filterDataSaved) {
        filterDataNew = filterDataSaved;
      }
    } else {
      filterDataNew[field] = value;
    }
    localStorage.setItem("filter", JSON.stringify(filterDataNew));
    let applyFilterOn = {};
    filterFields.forEach((opt) => {
      if (filterDataNew[opt]) {
        applyFilterOn[opt] = filterDataNew[opt];
      }
    });
    setPreviousPage(0);
    setPagedata(pageDataNew);
    setAllMission([]);
    setDataLoadedTill(0);
    setLastLoadedMission(null);
    setFilter(applyFilterOn);
    // setLoadHandler(!loadHandler);
  };

  const goToPageFcn = (value) => {
    navigate(`/app/collaborator/order/${value.id}`);
  };

  const valueConvertHandler = (type, value) => {
    switch (type) {
      case "date":
        return (
          <Typography variant="body1">
            {DateTime.fromISO(value["dateCreated"]).toISODate()}
          </Typography>
        );
      case "seconds":
        return (
          <Typography variant="body1">
            {value["dateCompleted"]
              ? changeMillisToDate(value["dateCompleted"])
              : "N/A"}
          </Typography>
        );
      case "array":
        return (
          <Typography variant="body1">
            {value["equipment"] && value["equipment"].length > 0
              ? value["equipment"].toString()
              : "n/a"}
          </Typography>
        );
      case "quote":
        return (
          <Typography variant="body1">
            {value["subscriptionId"] && (
              <StatusBadge status={colorStatus.yellow} name="N/A" />
            )}
            {(!value["subscriptionId"] && value["quoteStatus"] == undefined) ||
              (value["quoteStatus"] == "NOT RECEIVED" && (
                <StatusBadge status={colorStatus.yellow} name="NOT RECEIVED" />
              ))}
            {!value["subscriptionId"] && value["quoteStatus"] == "RECEIVED" && (
              <StatusBadge
                status={colorStatus.green}
                name={value["quoteStatus"]}
              />
            )}
          </Typography>
        );
      case "status":
        return (
          <Typography variant="body1">
            {(value["status"] == missionStatus.unassigned ||
              value["status"] == missionStatus.confirmedMission) && (
              <StatusBadge status={colorStatus.yellow} name="Pending pilot" />
            )}
            {value["status"] == missionStatus.active && (
              <StatusBadge status={colorStatus.green} name="Active" />
            )}
            {value["status"] == missionStatus.initialClientOrder && (
              <StatusBadge status={colorStatus.yellow} name="Reviewing" />
            )}
            {value["status"] == missionStatus.completed && (
              <StatusBadge status={colorStatus.green} name="Completed" />
            )}
            {value["status"] == missionStatus.cancelled && (
              <StatusBadge status={colorStatus.grey} name="Cancelled" />
            )}
            {value["status"] == missionStatus.rejected && (
              <StatusBadge status={colorStatus.grey} name="Rejected" />
            )}
            {value["status"] == missionStatus.archive && (
              <StatusBadge status={colorStatus.grey} name="Archive" />
            )}
          </Typography>
        );
      case "pilotMissionStatus":
        return (
          <Typography variant="body1">
            {value["pilotPackageStatus"] == PilotPackageStatus.rejected && (
              <StatusBadge status={colorStatus.red} name="Rejected" />
            )}
            {value["pilotPackageStatus"] ==
              PilotPackageStatus.pendingReview && (
              <StatusBadge status={colorStatus.red} name="To review" />
            )}
            {value["pilotPackageStatus"] == PilotPackageStatus.approved && (
              <StatusBadge status={colorStatus.green} name="Approved" />
            )}
            {(!value["pilotPackageStatus"] ||
              value["pilotPackageStatus"] == PilotPackageStatus.open) && (
              <StatusBadge name="NOT COMPLETED" />
            )}
          </Typography>
        );
      case "reject":
        var reason = value?.reason?.rejectedReason;
        return (
          <Typography variant="body1">
            {reason && reason.length > 0
              ? reason.map((val, i) => <li key={i}>{val}</li>)
              : ""}
          </Typography>
        );
      default:
        return;
    }
  };

  return (
    <section>
      {currentUser?.isAnonymous && <GuestStatusAlert />}

      {authorized == true ? (
        <Box sx={styles.missionContainer}>
          <Box sx={styles.filter}>
            <FilterOrders
              valueChangeHandler={valueChangeHandler}
              role={currentUser.role}
              missionTypeData={ClientPage.Collaboration}
            />
          </Box>
          <GlobalTable
            columns={columns}
            allData={filteredMissionList}
            valueConvertHandler={valueConvertHandler}
            goToPageFcn={goToPageFcn}
            loading={isLoading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={pageData.currentPage}
            isLastPage={pageData.isLast}
            lastPage={pageData.lastPage}
            sortByHandler={sortByHandler}
          />
        </Box>
      ) : authorized == false ? (
        <Box sx={styles.unAuthorized}>
          <StatusAlertContainer
            heading="UNAUTHORIZED ACCESS"
            status="danger"
            info="Your admin type does not allow you to work with missions.
            Please contact Napoleon to change your access rights."
          />
        </Box>
      ) : (
        <Box sx={styles.loaderDiv}>
          <UniversalLoader />
        </Box>
      )}
    </section>
  );
};

CollabListPage.propTypes = {
  columns: PropTypes.array.isRequired,
};
export default CollabListPage;
