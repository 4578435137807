import { AppBar, Box } from "@mui/material";
import React, { useState, useContext } from "react";
import {
  StyledTab,
  StyledTabs,
  appBar,
  getTabBgColor,
} from "../../../lib/styleConstants";
import TabPanel from "../../global/elements/TabPanel";
import PreFlight from "./NewMissionDetails/components/PreFlight";
import PostFlight from "./NewMissionDetails/components/PostFlight";
import {
  missionFeasibility,
  missionPlanningType,
  userRoles,
} from "../../../lib/constants";
import PropTypes from "prop-types";
import { getUser } from "../../../services/auth";
import MissionContext from "./NewMissionDetails/components/MissionContext";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const styles = {
  mainBox: {
    overflowY: "hidden",
    marginLeft: "10px",
    "& .MuiTabs-root": {
      border: "none",
    },
    "& .Mui-selected": {
      borderBottom: `4px solid ${getTabBgColor()}`,
      background: "none",
    },
  },
};

const MissionPlanning = ({ activeTab = "preFlight" }) => {
  const [tabValue, setTabValue] = useState(
    activeTab === missionPlanningType.postFlight ? 1 : 0
  );
  const currentUserRole = getUser("user").role;
  const handleTabChange = (e, newTabValue) => {
    setTabValue(newTabValue);
  };
  const data = useContext(MissionContext);
  return (
    <Box sx={styles.mainBox}>
      <AppBar position="static" sx={appBar} elevation={0}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab
            key="preflight"
            disabled={
              currentUserRole !== userRoles.pilot &&
              activeTab !== missionPlanningType.preFlight
            }
            label="Pre flight"
            {...a11yProps(0)}
          />
          <StyledTab
            key="postflight"
            disabled={
              (currentUserRole !== userRoles.pilot &&
                activeTab !== missionPlanningType.postFlight) ||
              (currentUserRole === userRoles.pilot &&
                (data?.missionPlanning?.data?.missionFeasible ===
                  missionFeasibility.no ||
                  data?.missionPlanning?.data?.currentFlightDate === null))
            }
            label="Post flight"
            {...a11yProps(1)}
          />
        </StyledTabs>
        <Box mt={4}>
          <TabPanel value={tabValue} index={0} key="preflight">
            <PreFlight />
          </TabPanel>
          <TabPanel value={tabValue} index={1} key="postflight">
            <PostFlight setTabValue={setTabValue} />
          </TabPanel>
        </Box>
      </AppBar>
    </Box>
  );
};

MissionPlanning.propTypes = {
  activeTab: PropTypes.string,
};

// MissionPlanning.defaultProps = {
//   activeTab: "preFlight",
// };

export default MissionPlanning;
