import React, { useContext } from "react";
import MissionContext from "./MissionContext";
import {
  missionStatus,
  missionTypeConstant,
} from "../../../../../lib/constants";
import { Grid } from "@mui/material";
import AppButton from "../../../../global/elements/AppButton";

const styles = {
  inviteMore: {
    textAlign: { xs: "center", md: "right" },
    marginTop: { xs: "30px", md: 0 },
  },
  inviteAllBtn: { textAlign: { xs: "center", md: "left" } },
};

const PilotSelectionPrompt = () => {
  const missionDetails = useContext(MissionContext);
  return (
    !(
      missionDetails?.mission.status == missionStatus.cancelled ||
      missionDetails?.mission.status == missionStatus.rejected
    ) && (
      <Grid container mt={3}>
        {!missionDetails?.mission?.assignedPilot && (
          <>
            <Grid item xs={12} md={12} sx={styles.inviteMore}>
              {missionDetails?.mission.missionType !=
                missionTypeConstant.projectMission && (
                <>
                  <AppButton
                    onClick={() => {
                      missionDetails?.setPageMode(
                        missionDetails?.PageModes.ASSIGN_PILOTS
                      );
                    }}
                    look={
                      missionDetails?.checkInviteAssignDisablity() ||
                      missionDetails?.mission.pilotInstructions === ""
                        ? "blackDisabled"
                        : "inverted"
                    }
                    label="Assign a single operator"
                    isDisabled={
                      missionDetails?.checkInviteAssignDisablity() ||
                      missionDetails?.mission.pilotInstructions === ""
                    }
                    marginRight
                  />
                  <AppButton
                    onClick={missionDetails?.openPopup}
                    look={
                      missionDetails?.checkInviteAssignDisablity() ||
                      missionDetails?.mission.pilotInstructions === ""
                        ? "blackDisabled"
                        : "blue"
                    }
                    label="Invite more drone operators"
                    isDisabled={
                      missionDetails?.checkInviteAssignDisablity() ||
                      missionDetails?.mission.pilotInstructions === ""
                    }
                  />
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default PilotSelectionPrompt;
