import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import MissionContext from "./MissionContext";
import theme from "../../../../../theme";
import { userRoles } from "../../../../../lib/constants";
import PropTypes from "prop-types";

const styles = {
  appBtn: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
  },
  boxStyles: { padding: "20px" },
  gridContainer: {
    justifyContent: "flex-end",
  },

  otherPilotsText: {
    color: theme.palette.status.errorDark,
    padding: "20px 20px 0",
    textAlign: "end",
  },
  gridMobileMargin: {
    marginTop: { xs: "20px", md: 0 },
  },
};

const UserNextActionsBanner = ({
  actionText = "Your next actions",
  actionSubText = "",
  actionSubText2 = undefined,
  background = undefined,
  redWarning = false,
  redWarningText = undefined,
  button1 = undefined,
  button2 = undefined,
  bigText = false,
}) => {
  const actionData = useContext(MissionContext);

  const isClient = actionData?.currentUser?.role == userRoles.client;

  return (
    <Box
      sx={{
        background: background
          ? background
          : isClient
          ? theme.palette.primary.palePistachio
          : theme.palette.adminBlue.veryPaleBlue,
      }}
    >
      {redWarning && (
        <Typography variant="h5" sx={styles.otherPilotsText}>
          {redWarningText}
        </Typography>
      )}
      <Grid container sx={styles.boxStyles}>
        <Grid item xs={12} md={bigText ? 8 : 6}>
          <Typography variant="h2">{actionText}</Typography>
          {actionSubText2 && (
            <Typography variant="h5">{actionSubText2}</Typography>
          )}

          {actionSubText && (
            <Typography variant="subtitle1">{actionSubText}</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={bigText ? 4 : 6}
          sx={{ ...styles.appBtn, ...styles.gridMobileMargin }}
        >
          <Grid container columnSpacing={1} sx={styles.gridContainer}>
            {button2 && (
              <Grid item xs={12} md={4} sx={styles.gridMobileMargin}>
                {button2}
              </Grid>
            )}
            {button1 && (
              <Grid item xs={12} md={4} sx={styles.gridMobileMargin}>
                {button1}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

UserNextActionsBanner.propTypes = {
  actionText: PropTypes.string,
  actionSubText: PropTypes.string,
  actionSubText2: PropTypes.node,
  background: PropTypes.string,
  redWarning: PropTypes.bool,
  redWarningText: PropTypes.string,
  button1: PropTypes.element,
  button2: PropTypes.element,
  bigText: PropTypes.bool,
};

// UserNextActionsBanner.defaultProps = {
//   actionText: "Your next actions",
//   actionSubText: "",
//   actionSubText2: "",
//   background: undefined,
//   redWarning: false,
//   redWarningText: undefined,
//   button1: undefined,
//   button2: undefined,
//   bigText: false,
// };

export default UserNextActionsBanner;
