import firebase from "src/firebase";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { isBrowser } from "src/services/auth";
import { getProjectListForPilot } from "../services/project";

const useGetProjectListForPilot = (pilotId) => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [project, setProject] = useStateIfMounted([]);
  const [projectAll, setProjectAll] = useStateIfMounted([]);
  const [limit, setLimit] = useStateIfMounted(10);
  const [currentPage, setCurrentPage] = useStateIfMounted(1);
  const [isLastPage, setIsLastPage] = useStateIfMounted(false);
  const [lastLoadedPageNumber, setLastLoadedPageNumber] =
    useStateIfMounted(null);
  const [lastPageNumber, setLastPageNumber] = useStateIfMounted(null);
  const [lastLoaded, setLastLoaded] = useStateIfMounted(null);
  const [triggerReload, setTriggerReload] = useStateIfMounted(false);
  const [filter, setFilter] = useStateIfMounted({
    projectNameSearch: "",
    projectId: "",
    status: "",
  });
  const [sort, setSort] = useStateIfMounted({
    sortBy: "createdAt",
    sortValue: "desc",
  });

  useEffect(() => {
    if (!firebase || !isBrowser()) return;
    setLoading(true);
    try {
      if (lastLoadedPageNumber >= currentPage) {
        let loadedDataInMemory = projectAll.slice(
          limit * (currentPage - 1),
          currentPage * limit
        );
        setProject(loadedDataInMemory);
        setLoading(false);
      } else {
        getProjectListForPilot(
          firebase,
          pilotId,
          sort,
          filter,
          lastLoaded,
          limit
        ).then((snapshot) => {
          if (snapshot.status === "Success") {
            setProject(snapshot.projects);
            setProjectAll([...projectAll, ...snapshot.projects]);
            if (snapshot.isLast) {
              setIsLastPage(true);
              setLastPageNumber(currentPage);
            } else {
              setIsLastPage(false);
              setLastPageNumber(null);
            }
            setLastLoaded(snapshot.lastLoaded);
            setLastLoadedPageNumber(currentPage);
          }
          setLoading(false);
        });
      }
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  }, [triggerReload]);

  return [
    project,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    setIsLastPage,
    lastPageNumber,
    setLastPageNumber,
    filter,
    setFilter,
    sort,
    setSort,
    setLastLoaded,
    setLastLoadedPageNumber,
    setProjectAll,
    loading,
    triggerReload,
    setTriggerReload,
  ];
};
export default useGetProjectListForPilot;
