import React, { useState } from "react";
import {
  deliveryDataType,
  missionMapTypes,
  packageTypeConstant,
} from "../../../../../lib/constants";
import PropTypes from "prop-types";
import {
  getAllDeliveryFilesFromFileDataType,
  getDeliveryFilesByAccessToken,
} from "src/services/delivery-files";
import { Writer } from "@transcend-io/conflux";
import { ReadableStream as ReadableStreamPonyfill } from "web-streams-polyfill/ponyfill";
import streamSaver from "streamsaver";
import { GetApp } from "@mui/icons-material";
import DownloadSnackbar from "./DownloadSnackbar";
import {
  calculateProcessedPercentage,
  removeDuplicateName,
} from "src/lib/helpers";
import { getDeliveryPackages } from "src/services/delivery-packages";
import firebase from "src/firebase";
import AppButton from "src/components/global/elements/AppButton";

const transformProcessedFiles = (processedFilesArray) => {
  const processedFilesMap = {};

  processedFilesArray.forEach((files) => {
    files.forEach((file) => {
      const fileType = file.fileDataType;

      if (!processedFilesMap[fileType]) {
        processedFilesMap[fileType] = [];
      }

      processedFilesMap[fileType].push(file);
    });
  });

  return Object.keys(processedFilesMap).map((fileType) => ({
    fileDataType: fileType,
    files: processedFilesMap[fileType],
  }));
};

const getMapTypeValue = (item) => {
  const mapType = missionMapTypes.find((mapType) => mapType.label === item);
  return mapType?.value;
};

const baseFileType = deliveryDataType.baseFiles;
const base = "base";
const processed = "processed";

const fetchFilesDataFromDB = async (
  missionId,
  packageId,
  fileType,
  fileDataType,
  accessToken
) => {
  let reqObj = {
    missionId: missionId,
    fileDataType: fileDataType,
  };

  let files = [];
  try {
    if (accessToken) {
      const data = await getDeliveryFilesByAccessToken({
        accessToken,
        fileDataType: fileType === base ? baseFileType : fileDataType,
      });
      files = removeDuplicateName(data);
    } else {
      reqObj.deliveryPackageID = packageId;
      reqObj.packageType = packageTypeConstant.deliveryPackage;
      const data = await getAllDeliveryFilesFromFileDataType(reqObj);
      files = removeDuplicateName(data.files);
    }
    return files;
  } catch (e) {
    console.error(`Error in fetching ${fileType}Files data from db`);
    return [];
  }
};

const DownloadProjectMission = ({ mission, projectMissions, project }) => {
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());

  const fetchFilesForMission = async (mission) => {
    const packages = await getDeliveryPackages(firebase, mission?.id);
    const pilotPackages = packages.sort(function (a, b) {
      return new Date(a.dateCreated) - new Date(b.dateCreated);
    });

    const mapTypes = mission?.mapTypes.map((type) => getMapTypeValue(type));
    const accessToken = mission?.accessToken;

    const fetchBaseFilesDataFromDB = async () => {
      return await fetchFilesDataFromDB(
        mission?.id,
        pilotPackages[0]?.id,
        base,
        baseFileType,
        accessToken
      );
    };

    const fetchProcessedFilesDataFromDB = async (fileDataType) => {
      return await fetchFilesDataFromDB(
        mission?.id,
        pilotPackages[0]?.id,
        processed,
        fileDataType,
        accessToken
      );
    };

    const fetchAllFilesFromDB = async () => {
      try {
        const processedFilesPromises = mapTypes?.map((type) =>
          fetchProcessedFilesDataFromDB(type)
        );
        const rawProcessedFiles = await Promise.all(processedFilesPromises);
        const processedFiles = transformProcessedFiles(rawProcessedFiles);
        const baseFiles = await fetchBaseFilesDataFromDB();
        const allFiles = {
          baseFiles,
          processedFiles,
        };
        return allFiles;
      } catch (e) {
        console.error("Error fetching files from DB", e);
        return null;
      }
    };

    return await fetchAllFilesFromDB();
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    setDownloadMessage("Preparing files for download...");
    try {
      let allFilesArray = [];
      let zipFilename = "";

      if (projectMissions) {
        // Download all missions in the project
        zipFilename = `${project?.projectName}_${project?.projectId}.zip`;
        setFileName(zipFilename);
        for (const mission of projectMissions) {
          const allFiles = await fetchFilesForMission(mission);

          const { baseFiles, processedFiles } = allFiles;

          const missionFilesArray = [
            ...baseFiles.map((file) => ({
              ...file,
              folder: `missions/ML${mission?.missionName}_${mission?.id}/baseFiles`,
            })),
            ...processedFiles.flatMap((typeFiles) =>
              typeFiles.files.map((file) => ({
                ...file,
                folder: `missions/ML${mission?.missionName}_${mission?.id}/processedFiles/${typeFiles.fileDataType}`,
              }))
            ),
          ];

          allFilesArray = [...allFilesArray, ...missionFilesArray];
        }
      } else if (mission) {
        // Download single mission
        zipFilename = `${mission?.missionName}_${mission?.id}.zip`;
        setFileName(zipFilename);
        const allFiles = await fetchFilesForMission(mission);
        const { baseFiles, processedFiles } = allFiles;

        allFilesArray = [
          ...baseFiles.map((file) => ({ ...file, folder: "baseFiles" })),
          ...processedFiles.flatMap((typeFiles) =>
            typeFiles.files.map((file) => ({
              ...file,
              folder: `processedFiles/${typeFiles.fileDataType}`,
            }))
          ),
        ];
      }

      const deliveryFiles = allFilesArray.values();
      let prevPercentage = 0;
      const aC = new AbortController();
      setAbortController(aC);
      let dataProcessed = 0;

      const ModernReadableStream = window.WritableStream
        ? window.ReadableStream
        : ReadableStreamPonyfill;

      new ModernReadableStream({
        async pull(ctrl) {
          const { done, value } = deliveryFiles.next();
          if (done) {
            ctrl.close();
            return;
          }
          const url = value.url;
          const folderPath = value.folder;
          const fileName = value.fileName;
          try {
            setIsDownloading(true);
            setDownloadMessage(
              `Downloading file ${dataProcessed + 1}/${allFilesArray.length}`
            );

            const { body } = await fetch(url, {
              method: "GET",
              mode: "cors",
              signal: aC.signal,
            });

            dataProcessed++;
            let dataProcessedPercentage = calculateProcessedPercentage(
              allFilesArray.length,
              dataProcessed
            );

            if (dataProcessedPercentage !== 0) {
              if (dataProcessedPercentage > prevPercentage) {
                setProgress(dataProcessedPercentage);
              }
            }

            if (dataProcessedPercentage === 100) {
              setDownloadMessage(`Downloading completed.`);
            }

            prevPercentage = dataProcessedPercentage;

            await ctrl.enqueue({
              name: `/${folderPath}/${fileName}`,
              stream: () => body,
            });
          } catch (e) {
            if (aC.signal.aborted) {
              setDownloadMessage("Download cancelled");
              setIsDownloading(false);
            }
            console.error("ERROR", e);
            ctrl.close();
          }
        },
      })
        .pipeThrough(new Writer())
        .pipeTo(streamSaver.createWriteStream(zipFilename))
        .then(() => {
          if (progress !== 0) {
            setProgress(100);
            setDownloadMessage("Download completed!");
          }
        })
        .catch((error) => {
          if (!aC.signal.aborted) {
            setDownloadMessage("Download failed");
            setIsDownloading(false);
          }
          console.error("Download error:", error);
        });
    } catch (e) {
      setDownloadMessage("Download failed");
      setIsDownloading(false);
      console.error("ERROR", e);
    }
  };

  const closeDownloadPopup = () => {
    setIsDownloading(false);
    setFileName("");
    setProgress(0);
    setDownloadMessage("");
  };

  const abortDownloads = () => {
    abortController.abort();
    setIsDownloading(false);
    setProgress(0);
  };

  return (
    <>
      {!project?.projectId ? (
        <GetApp onClick={handleDownload} fontSize="medium" />
      ) : (
        <AppButton
          onClick={handleDownload}
          customIcon={<GetApp fontSize="medium" />}
          label="Download Project"
        />
      )}
      <DownloadSnackbar
        isDownloadingBatch={isDownloading}
        message={downloadMessage}
        progress={parseInt(progress)}
        abortDownloads={abortDownloads}
        fileName={fileName}
        closeDownloadPopup={closeDownloadPopup}
      />
    </>
  );
};

DownloadProjectMission.propTypes = {
  mission: PropTypes.object,
  projectMissions: PropTypes.object,
  project: PropTypes.object,
};
DownloadProjectMission.DefaultProps = {
  mission: undefined,
  projectMissions: undefined,
  project: undefined,
};

export default DownloadProjectMission;
