import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import Dialogue from "../../global/Dialogue";
import AppButton from "../../global/elements/AppButton";
import Summary from "./Components/Summary";
import ThankYouComp from "./Components/ThankyouComp";
import Clockloader from "../../global/elements/Clockloader";
import { getUser } from "../../../services/auth";
import { userRoles } from "../../../lib/constants";
import Tips from "../../../components/global/elements/Tips";

const styles = {
  headerBox: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "50px",
    paddingLeft: "50px",
  },
  headingCls: {
    marginTop: "15%",
  },
  headerBackArrowCls: {
    cursor: "pointer",
  },
  quotationContainerCls: {
    padding: "55px",
  },
};

const OrderSummary = ({
  showDialogue = false,
  summary = {},
  orderPlaced = false,
  loading = false,
  order = {},
  setShowDialogue = undefined,
  orderSubmitHandler = undefined,
  orderCollection = undefined,
  orderSwitch = undefined,
}) => {
  const currentUser = getUser("user");
  const isAdmin = currentUser?.role == userRoles.admin ? true : false;

  const handleBeforeUnload = (e) => {
    e.preventDefault();
  };
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (orderPlaced) {
      document.getElementById("header")?.scrollIntoView();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [orderPlaced]);

  useEffect(() => {
    if (loading) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading]);

  const SummaryDialogueBody = () => {
    return (
      <>
        <Grid container sx={styles.quotationContainerCls}>
          {orderPlaced && (
            <Grid item xs={12} md={6}>
              <ThankYouComp order={order} orderCollection={orderCollection} />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Summary
              orderPlaced={orderPlaced}
              summary={summary}
              orderSwitch={orderSwitch}
              orderCollection={orderCollection}
            />
            <FooterButtonComp />
          </Grid>

          {loading && (
            <Clockloader
              text={`Your order is being placed.
          Do not hit back or refresh until your order is complete `}
            />
          )}
        </Grid>
      </>
    );
  };

  const FooterButtonComp = () => {
    return (
      <Box sx={{ ...styles.orderNameBox }}>
        {orderPlaced ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppButton
                look="green"
                label={isAdmin ? "Back" : "Go to Dashboard"}
                addtionalStyle={{ width: "100%" }}
                url={
                  isAdmin
                    ? "/app/admin/projects/draft-project"
                    : "/app/client/my-account"
                }
                internalLink
              ></AppButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tips
                content="What happens next?"
                subContent="After placing your order, you'll promptly receive a quote. Your order will only become active once you accept this quote, at which point our drone operators will begin capturing your data. Therefore, while you can place your order now, it won't take effect until you've formally accepted the quote."
              />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={3}>
              <AppButton
                look="inverted"
                label="Edit"
                noIcon
                small
                onClick={() => setShowDialogue(false)}
              ></AppButton>
            </Grid>
            <Grid item xs={12} md={9}>
              <AppButton
                look={isAdmin ? "black" : "green"}
                label="Place Order"
                addtionalStyle={{ width: "100%" }}
                onClick={() => {
                  orderSubmitHandler(summary);
                }}
              ></AppButton>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  const SummaryHeader = () => {
    return (
      <>
        <Box sx={styles.headerBox} id="header">
          {!orderPlaced && (
            <span
              style={styles.headerBackArrowCls}
              onClick={() => setShowDialogue(false)}
            >
              {"<"}
            </span>
          )}

          {orderPlaced ? "Thank you!" : "Summary"}
        </Box>
      </>
    );
  };

  return (
    <Dialogue
      fullScreen={true}
      showDialogue={showDialogue}
      setShowDialogue={setShowDialogue}
      DialogueHeadText={<SummaryHeader />}
      dividerLine={true}
      DialogueBody={SummaryDialogueBody()}
      showCancelButton={true}
      cancelButtonText={"Cancel"}
      sumitButtonText={"Yes"}
      showSubmitLoader={isSubmitting}
      setShowSubmitLoader={setSubmitting}
      onSubmit={() => {}}
      submitButtonLook={"black"}
      tableType
    />
  );
};

OrderSummary.propTypes = {
  showDialogue: PropTypes.bool,
  setShowDialogue: PropTypes.func.isRequired,
  summary: PropTypes.object,
  orderSwitch: PropTypes.string.isRequired,
  orderPlaced: PropTypes.bool,
  orderSubmitHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  order: PropTypes.object,
  orderCollection: PropTypes.string.isRequired,
};

// OrderSummary.defaultProps = {
//   showDialogue: false,
//   summary: {},
//   orderPlaced: false,
//   loading: false,
//   order: {},
// };

export default OrderSummary;
