import { Typography, Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
  addDaystoTheDate,
  changeMillisToDate,
  getCurrentDate,
  getDateFromDateTimeStamp,
  getMissionStatusLabelData,
  getPaymentStatusLabelData,
  getProjectStatusLabelData,
  getQuoteCurrency,
  getQuoteTotalCost,
  getStatus,
} from "../../../lib/helpers";
import StatusBadge from "./StatusBadge";
import DefaultImage from "../../../assets/images/profileImage.jpg";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import theme from "../../../theme";
import { statusWrapper } from "../../../lib/styleConstants";
import {
  colorStatus,
  missionStatusLabels,
  missionTypeConstant,
  PilotPackageStatus,
  quoteStatus,
  userRoles,
} from "../../../lib/constants";
import DownloadProjectMission from "src/components/app/missions/FilesPackages/component/DownloadProjectMission";
import { getUser } from "../../../services/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, navigate } from "gatsby";

const styles = {
  bold_info: {
    fontSize: "17px",
    fontWeight: "500",
    cursor: "pointer",
  },
  bold_text: {
    fontWeight: "500 !important",
  },
  arrayBoxMain: {
    width: "140px",
    display: "inline-flex",
    flexWrap: "wrap",
  },
  boxArray: {
    padding: "0px 5px",
    border: `1px solid ${theme.palette.grey.dividersColor}`,
    margin: "2px 3px",
  },
  marginBottom: {
    marginBottom: "5px",
  },
  profileIdentifier: {
    display: "flex",
  },
  profileIdentifierWithId: { display: "flex" },
  profileLogo: {
    width: { xs: "18px", sm: "22px", md: "28px", lg: "32px" },
    height: { xs: "18px", sm: "22px", md: "28px", lg: "32px" },
    borderRadius: "50%",
  },
  textInMiddle: {
    margin: "auto 0 !important",
    marginLeft: "10px !important",
  },
  greenColor: {
    color: theme.palette.status.successDark,
  },
  blackText: {
    color: theme.palette.black.darkSeaBlack,
  },
  correction: {
    width: "140px",
    display: "flex",
    justifyContent: { xs: "left" },
    color: theme.palette.status.errorDark,
  },
  linkDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
};

const GlobalTableValueConvertHandler = ({
  column = undefined,
  field = undefined,
  value = undefined,
  statusBadge = false,
  mixedColumns = [],
  bold = false,
}) => {
  const currentUser = getUser("user");
  const isClient = currentUser.role === userRoles.client;
  switch (field) {
    case "projectStatus": {
      const statusLabel = getProjectStatusLabelData(value, currentUser);
      return (
        <Box>
          <Box>
            <StatusBadge
              name={
                statusLabel.label === missionStatusLabels.correction
                  ? missionStatusLabels.inProgress
                  : statusLabel.label
              }
              status={statusLabel.statusColor}
            />
            <Box
              dangerouslySetInnerHTML={{
                __html: statusLabel.info,
              }}
              sx={styles.info_message}
            />
          </Box>
        </Box>
      );
    }
    case "clientPaymentStatus": {
      if (value.missionType === missionTypeConstant.clientOrder) {
        const statusLabel = getMissionStatusLabelData(value, currentUser);
        const paymentClientStatusLabel = getPaymentStatusLabelData(
          value,
          "client"
        );
        return (
          <>
            <Box sx={styles.paymentStatusRow}>
              {value.subscriptionId ? (
                <StatusBadge
                  name={"SUBSCRIPTION"}
                  status={
                    paymentClientStatusLabel.statusColor === colorStatus.green
                      ? colorStatus.green
                      : paymentClientStatusLabel.statusColor === colorStatus.red
                      ? colorStatus.red
                      : colorStatus.yellow
                  }
                />
              ) : (
                <StatusBadge
                  name={"Client " + value["paymentStatusClient"] || "n/a"}
                  status={paymentClientStatusLabel.statusColor}
                />
              )}
            </Box>
            {value["paymentStatusClient"] !== "paid" && (
              <Box
                sx={{ ...styles.info_message, ...styles.status_text }}
                dangerouslySetInnerHTML={{
                  __html: statusLabel.info,
                }}
              />
            )}
          </>
        );
      } else {
        return (
          <Box sx={styles.paymentStatusRow}>
            <StatusBadge name="not applicable" status={colorStatus.neutral} />
          </Box>
        );
      }
    }
    case "date": {
      return (
        <Typography variant="body1">
          {value[column] ? getCurrentDate(value[column]) : "n/a"}
        </Typography>
      );
    }
    case "quotedeliverydate": {
      return (
        <Typography variant="body1">
          {value[column]
            ? getCurrentDate(
                addDaystoTheDate({
                  theDate: value.dateCreated,
                  days: value[column],
                })
              )
            : "n/a"}
        </Typography>
      );
    }
    case "dateTimeStamp": {
      const date = value[column]
        ? getDateFromDateTimeStamp(value[column])
        : "n/a";
      return <Typography variant="body1">{date}</Typography>;
    }
    case "capital": {
      return (
        <Typography sx={styles.bold_info} variant="body1">
          {value[column] || "n/a"}
        </Typography>
      );
    }
    case "array": {
      return (
        <Typography variant="body1" sx={bold && styles.bold_info}>
          {value[column] && value[column].length > 0
            ? value[column].toString()
            : "n/a"}
        </Typography>
      );
    }
    case "arrayBox": {
      return (
        <Box sx={styles.arrayBoxMain}>
          {value[column] && value[column].length > 0 ? (
            value[column].map((val, i) => {
              return (
                <Typography
                  variant="body1"
                  key={i}
                  sx={
                    bold
                      ? { ...styles.boxArray, ...styles.bold_info }
                      : styles.boxArray
                  }
                >
                  {val}
                </Typography>
              );
            })
          ) : (
            <Typography variant="body1" sx={bold && styles.bold_info}>
              n/a
            </Typography>
          )}
        </Box>
      );
    }
    case "statusBadge": {
      let str = value[column] || "n/a";
      return (
        <Box sx={statusWrapper}>
          <StatusBadge name={str} status={str} />
        </Box>
      );
    }
    case "missionQuotestatus": {
      let str = value[column] || "n/a";
      return (
        <Box sx={statusWrapper}>
          {}
          <StatusBadge
            name={str}
            status={
              str === quoteStatus.declined || str === quoteStatus.deleted
                ? colorStatus.red
                : str === quoteStatus.expired
                ? colorStatus.yellow
                : str === quoteStatus.accepted
                ? colorStatus.green
                : colorStatus.neutral
            }
          />
        </Box>
      );
    }
    case "ProjectStatusBadge": {
      let str = value[column] || "n/a";
      let { name, color } = getStatus({
        status: str,
        pilotPackageStatus: value["pilotPackageStatus"],
      });
      return (
        <Box sx={statusWrapper}>
          <StatusBadge name={name} status={color} />
          {value["pilotPackageStatus"] === PilotPackageStatus.rejected &&
            !isClient && (
              <Box sx={styles.correction}>
                <Typography variant="caption">Correction Required</Typography>
              </Box>
            )}
        </Box>
      );
    }
    case "firstCharUpper": {
      const str = value[column]
        ? value[column].charAt(0).toUpperCase() +
          value[column].slice(1).toLowerCase()
        : "n/a";
      return statusBadge ? (
        <Box sx={statusWrapper}>
          <StatusBadge name={str} status={str} />
        </Box>
      ) : (
        <Typography variant="body1">{str}</Typography>
      );
    }
    case "normal": {
      return <Typography variant="body1">{value[column] || "n/a"}</Typography>;
    }
    case "mixed": {
      const mixedCol1 =
        mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const mixedCol2 =
        mixedColumns && mixedColumns[1] ? mixedColumns[1] : null;

      return (
        <Box>
          <Typography
            variant="body1"
            sx={{
              ...styles.bold_text,
              ...styles.marginBottom,
              ...styles.blackText,
            }}
            onClick={() => navigate(value[column])}
          >
            {" "}
            {value[mixedCol1] || "N/A"}
          </Typography>
          <Typography variant="body3"> {value[mixedCol2] || "N/A"}</Typography>
        </Box>
      );
    }
    case "mixed_url": {
      const mixedCol1 =
        mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const mixedCol2 =
        mixedColumns && mixedColumns[1] ? mixedColumns[1] : null;
      return (
        <Box>
          <Typography
            variant="body1"
            sx={{
              ...styles.bold_text,
              ...styles.marginBottom,
              ...styles.blackText,
            }}
            onClick={() => navigate(value[column])}
          >
            {" "}
            {value[mixedCol1] || "N/A"}
          </Typography>
          <Typography variant="body3"> {value[mixedCol2] || "N/A"}</Typography>
        </Box>
      );
    }
    case "mission_project_mixed": {
      // const mixedCol1 =
      //   mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const valueMixedCol1 =
        mixedColumns?.length > 2
          ? value[mixedColumns[0]] || value[mixedColumns[1]]
          : "N/A";
      const mixedCol2 =
        mixedColumns && mixedColumns[2] ? mixedColumns[2] : null;
      return (
        <Box>
          <Typography
            variant="body1"
            sx={{
              ...styles.bold_text,
              ...styles.marginBottom,
              ...styles.blackText,
            }}
          >
            {" "}
            {valueMixedCol1}
          </Typography>
          <Typography variant="body3"> {value[mixedCol2] || "N/A"}</Typography>
        </Box>
      );
    }
    case "profileIdentifier": {
      const name = mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const profileImage =
        mixedColumns && mixedColumns[1] ? mixedColumns[1] : null;
      return (
        <Box sx={styles.profileIdentifier}>
          <Box
            component="img"
            sx={styles.profileLogo}
            src={value[profileImage] || DefaultImage}
          />
          <Typography variant="body1" sx={styles.textInMiddle}>
            {" "}
            {value[name] || "N/A"}
          </Typography>
        </Box>
      );
    }

    case "profileIdentifierWithId": {
      const name = mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const profileImage =
        mixedColumns && mixedColumns[1] ? mixedColumns[1] : null;
      const id = mixedColumns && mixedColumns[2] ? mixedColumns[2] : null;
      return (
        <Box sx={{ ...styles.profileIdentifierWithId }}>
          <Box
            component="img"
            sx={styles.profileLogo}
            src={value[profileImage] || DefaultImage}
          />
          <Box sx={{}}>
            <Typography
              variant="body1"
              sx={{ ...styles.textInMiddle, ...styles.bold_info }}
            >
              {" "}
              {value[name] || "N/A"}
            </Typography>
            <Typography variant="body3" sx={styles.textInMiddle}>
              {" "}
              {value[id] || "N/A"}
            </Typography>
          </Box>
        </Box>
      );
    }
    case "profileIdentifierWithEmail": {
      const name = mixedColumns && mixedColumns[0] ? mixedColumns[0] : null;
      const profileImage =
        mixedColumns && mixedColumns[1] ? mixedColumns[1] : null;
      const email = mixedColumns && mixedColumns[2] ? mixedColumns[2] : null;
      return (
        <Box sx={styles.profileIdentifier}>
          <Box
            component="img"
            sx={styles.profileLogo}
            src={value[profileImage] || DefaultImage}
          />
          <Typography
            variant="body1"
            sx={{ ...styles.textInMiddle, ...styles.bold_text }}
          >
            {" "}
            {value[name] || "N/A"}
          </Typography>

          {value[email] && (
            <MarkunreadOutlinedIcon
              sx={{ ...styles.textInMiddle, ...styles.greenColor }}
              onClick={() => (window.location = `mailto:${value[email]}`)}
              fontSize="medium"
            />
          )}
        </Box>
      );
    }
    case "quotefinalPrice":
      return (
        <Typography variant="h6">
          {/* {value["discountPercentage"]
            ? (
                value["priceBeforeDiscount"] -
                value["discountPercentage"] * value["priceBeforeDiscount"]
              ).toFixed(2)
            : value["priceBeforeDiscount"]} */}
          {getQuoteCurrency(value)} &nbsp;
          {getQuoteTotalCost(value)}
        </Typography>
      );
    case "quoteDiscount":
      return (
        <Typography variant="body1">
          {value["discountPercentage"]
            ? value["discountPercentage"] * 100 +
              "%" +
              (value["discountReason"]
                ? "-" + value["discountReason"].substring(0, 15)
                : "")
            : "n/a"}{" "}
        </Typography>
      );
    case "seconds":
      return (
        <Typography variant="body1">
          {value["dateCompleted"]
            ? changeMillisToDate(value["dateCompleted"])
            : "N/A"}
        </Typography>
      );

    case "issuedBy":
      return (
        <Typography variant="body1">
          {value?.issuedBy?.name || "n/a"}
        </Typography>
      );

    case "cancelBy":
      return (
        <Typography variant="body1">
          {value?.cancelBy?.name || "n/a"}
        </Typography>
      );

    case "download":
      return (
        currentUser !== userRoles.pilot &&
        value?.status === "completed" && (
          <DownloadProjectMission mission={value} />
        )
      );

    case "view":
      return (
        <VisibilityIcon
          onClick={() => navigate(value[column])}
          fontSize="medium"
        />
      );

    // case "pilotWithRating":
    //   return (
    //     <>
    //       <Box sx={style.avtarBox}>
    //         <Avatar
    //           alt="Remy Sharp"
    //           src={value?.pilot?.profileImageURL || defaultImage}
    //           sx={style.avtarImage}
    //         />

    //         <Typography variant="h5" marginTop={1} marginLeft={1}>
    //           {value?.name || "N/A"}
    //         </Typography>
    //       </Box>
    //       <Rating
    //         name={"overallRating"}
    //         value={value?.ratings?.overallRating || 0}
    //         precision={0.5}
    //         readOnly
    //       />
    //     </>
    //   );

    case "viewInNew":
      // return <VisibilityIcon onClick={() => navigate(value[column])} />;
      return (
        <Link
          to={value[column]}
          target={"_blank"}
          style={styles.linkDecoration}
        >
          <VisibilityIcon fontSize="medium" />
        </Link>
      );

    default: {
      return <Typography variant="body1">{value[column] || "n/a"}</Typography>;
    }
  }
};

GlobalTableValueConvertHandler.propTypes = {
  column: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  statusBadge: PropTypes.bool,
  mixedColumns: PropTypes.array,
  bold: PropTypes.bool,
};

// GlobalTableValueConvertHandler.defaultProps = {
//   statusBadge: false,
//   mixedColumns: [],
//   bold: false,
// };

export default GlobalTableValueConvertHandler;
