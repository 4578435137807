import { Divider, Box, Typography } from "@mui/material";
import React from "react";
import { Form, Formik } from "formik";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import { preventEnterSubmit } from "src/lib/form-helpers";
import theme from "../../../../theme";
import AppButton from "../../../global/elements/AppButton";
import SingleMultipleCheckbox from "../../../global/FormInputComponents/SingleMultipleCheckbox";
import { createProjectLogs } from "../../../../services/project";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { updatePilotPackageStatusForEmptyData } from "../../../../services/pilot-packages";
import { contractWaiverLabels } from "../../../../lib/constants";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";

const styles = {
  formikWidth: { width: "100%" },
  topDivider: {
    borderColor: theme.palette.black.darkSeaBlack,
    margin: "20px 0px",
  },
  topDividerII: {
    borderColor: theme.palette.black.darkSeaBlack,
    marginTop: "20px",
    marginBottom: "70px",
  },
  submitButton: {
    float: "right",
    marginTop: "10px",
    marginBottom: "10px",
  },
};

const SiteStatus = ({
  projectId,
  mission,
  contract,
  refreshMission = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    completed: false,
    notCompleted: false,
    unableToAccessLocation: false,
    noLOP: false,
    permissionDenied: false,
    weather: false,
    dataUploadProblems: false,
    otherReason: "",
    description: "",
    contract:
      mission?.contractAcceptedViaAdmin === contractWaiverLabels.accepted
        ? true
        : contract?.iUnderstandAll,
  };
  const logsValidationSchema = Yup.object({
    description: Yup.string(),
    completed: Yup.boolean(),
    notCompleted: Yup.boolean(),
    unableToAccessLocation: Yup.boolean(),
    noLOP: Yup.boolean(),
    permissionDenied: Yup.boolean(),
    weather: Yup.boolean(),
    dataUploadProblems: Yup.boolean(),
    otherReason: Yup.string(),
    contract: Yup.boolean(),
  }).test("myCustomTest", null, (obj) => {
    let errorMessage = "";
    if (obj.completed) {
      if (mission?.contractSignedByPilotOn) {
        errorMessage = "";
        return true; // everything is fine
      }
      errorMessage = "Please sign the contract to complete order";
    } else if (
      obj.notCompleted &&
      (obj.unableToAccessLocation ||
        obj.noLOP ||
        obj.permissionDenied ||
        obj.weather ||
        obj.dataUploadProblems ||
        obj.otherReason)
    ) {
      errorMessage = "";
      return true;
    } else if (
      obj.notCompleted &&
      !obj.completed &&
      !(
        obj.unableToAccessLocation ||
        obj.noLOP ||
        obj.permissionDenied ||
        obj.weather ||
        obj.dataUploadProblems ||
        obj.otherReason
      )
    ) {
      errorMessage = "";
      errorMessage =
        "Please select a reason why you are not able to complete this site";
    } else if (!obj.notCompleted && !obj.completed) {
      errorMessage = "Please select a chechek box site is completed or not";
    }

    return new Yup.ValidationError(errorMessage, null, "selectBarErrorMain");
  });

  const onSubmitStatus = (values, { setSubmitting }) => {
    let dataToSend = {};
    let siteNotCompletedReason = [];
    if (values.notCompleted) {
      if (values.unableToAccessLocation) {
        siteNotCompletedReason.push("Unable to access location");
      }
      if (values.noLOP) {
        siteNotCompletedReason.push("no LOP");
      }
      if (values.permissionDenied) {
        siteNotCompletedReason.push("Permission denied");
      }
      if (values.weather) {
        siteNotCompletedReason.push("Weather");
      }
      if (values.dataUploadProblems) {
        siteNotCompletedReason.push("Data Upload Problems");
      }
      dataToSend.logTitle = `${
        mission?.missionName || "Site"
      } is not completed.`;
      dataToSend.otherReason = values.otherReason;
      dataToSend.siteStatus = "notCompleted";
    } else {
      if (values.completed) {
        updatePilotPackageStatusForEmptyData({
          firebase,
          missionID: mission.id,
        });
      }
      siteNotCompletedReason = [];
      dataToSend.logTitle = `${mission?.missionName || "Site"} is completed.`;
      dataToSend.siteStatus = "completed";
    }

    dataToSend.logDescription = values.description;
    dataToSend.logType = "siteLog";
    dataToSend.siteNotCompletedReason = siteNotCompletedReason;
    dataToSend.showTo = ["all"];
    dataToSend.missionId = mission?.id;
    dataToSend.missionName = mission?.missionName;
    dataToSend.assignedPilot = mission?.assignedPilot;

    createProjectLogs(firebase, projectId, dataToSend)
      .then((snapshot) => {
        if (snapshot.status === "Success") {
          if (values.completed) {
            enqueueSnackbar("Site marked as completed successfully!", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Log has been created!", {
              variant: "success",
            });
          }
          refreshMission();

          document.getElementById("siteStatus").reset();
        } else {
          enqueueSnackbar("Error in creating logs.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar("Error in creating logs.", {
          variant: "danger",
        });
      })
      .finally(setSubmitting(false));
  };

  const checkboxFields = [
    { label: "Complete", name: "completed" },
    { label: "Not-Complete", name: "notCompleted" },
  ];

  const reasonArr = [
    { label: "Unable to access location", name: "unableToAccessLocation" },
    { label: "no LOP", name: "noLOP" },
    { label: "Permission denied", name: "permissionDenied" },
    { label: "Weather", name: "weather" },
    { label: "Data Upload Problems", name: "dataUploadProblems" },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitStatus}
        validationSchema={logsValidationSchema}
        style={styles.formikWidth}
      >
        {({ isSubmitting, values, setFieldValue, errors }) => {
          console.log("values", values);
          return (
            <Box sx={{ width: "100%" }}>
              <TextStructures
                text="Site status"
                customVariant="h3"
                marginBottom
              />
              <Typography variant="body1">
                Please add the status of this site. If you couldn&apos;t upload
                the data, please mention why below.
              </Typography>
              <Form id="siteStatus" onKeyDown={preventEnterSubmit}>
                <SingleMultipleCheckbox
                  checkboxFields={checkboxFields}
                  values={values}
                  multiple={false}
                  setFieldValue={setFieldValue}
                  errors={
                    !values.notCompleted ||
                    (!values.notCompleted && !values.completed)
                      ? errors
                      : {}
                  }
                  errorFieldName="selectBarErrorMain"
                />
                {values.notCompleted && (
                  <>
                    <Divider variant="fullWidth" sx={styles.topDivider} />
                    <SingleMultipleCheckbox
                      checkboxFields={reasonArr}
                      values={values}
                      multiple={true}
                      setFieldValue={setFieldValue}
                      others={true}
                      otherReasonFieldName={"otherReason"}
                      otherReasonLabel={"if other provide more description"}
                      errorFieldName="selectBarErrorMain"
                      errors={errors}
                    />
                  </>
                )}
                <Divider variant="fullWidth" sx={styles.topDividerII} />
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  name={"description"}
                  type="text"
                  required
                  placeholder={"If other provide more information"}
                  rows={4}
                  multiline={true}
                />

                <AppButton
                  label="Submit"
                  addtionalStyle={styles.submitButton}
                  type="submit"
                  look="purple"
                  form="siteStatus"
                  submittingState={isSubmitting}
                />
                <br />
              </Form>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

SiteStatus.propTypes = {
  projectId: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  refreshMission: PropTypes.func,
};

export default SiteStatus;
