import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FilterFieldsMission,
  missionTypes,
  FilterFieldActiveMissionList,
  missionStatus,
  FilterFieldCompleteMissionList,
  userRoles,
  PaymentStatus,
  archiveMissionTypes,
  FilterFieldsArchiveMission,
  pilotPaymentStatus,
  missionTypeConstant,
  FilterFieldCompleteClientOrderList,
  FilterFieldsAdminMissions,
  FilterFieldsClientArchiveMission,
  FilterFieldMissedMissions,
  FilterFieldRejectedMissions,
  adminActiveMissionStatus,
  adminConfirmedMissionStatus,
  pilotUnConfirmedMissionStatuses,
  pilotActiveMissionStatuses,
  otherMissionStatus,
  operatorSubmissionStatus,
} from "../../../lib/constants";
import { Box, TextField, Autocomplete } from "@mui/material";
import AutoCompleteFilter from "../../global/elements/AutoCompleteFilter";
import debounce from "lodash.debounce";
import { useCallback } from "react";

const styles = {
  field: {
    marginBottom: "15px",
    width: "100%",
  },
  filterFieldsCss: {
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 240px)",
    margin: "10px 0",
  },
  filterContainer: {
    padding: "5px 0",
    width: "100%",
  },
  TextField: {
    height: "20px",
  },
};

const FilterMission = ({
  valueChangeHandler = undefined,
  role = undefined,
  status = undefined,
  missionType = undefined,
}) => {
  const [filterField, setFilterField] = useState([]);
  const [changedFilterMonitor, setchangedFilterMonitor] = useState(false);
  const [filterData, setFilterData] = useState({
    Id: "",
    "Mission Type": "",
    "Mission Name": "",
  });
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    let options = getFilterOption();
    setFilterOptions(options);
  }, []);

  const getFilterOption = () => {
    if (missionType && missionType === missionTypeConstant.clientOrder) {
      if (
        status === missionStatus.active ||
        status === missionStatus.confirmedMission
      ) {
        return FilterFieldsAdminMissions;
      } else if (status === missionStatus.completed) {
        return FilterFieldCompleteClientOrderList;
      } else if (status === missionStatus.archive) {
        return FilterFieldsClientArchiveMission;
      } else {
        return FilterFieldsClientArchiveMission;
      }
    }
    if (
      missionType &&
      (missionType === missionTypeConstant.challengeMission ||
        missionType === missionTypeConstant.testMission ||
        missionType === missionTypeConstant.guinnessWorldRecord)
    ) {
      return FilterFieldsAdminMissions;
    }
    if (status === missionStatus.confirmedMission) {
      if (role === userRoles.pilot) {
        return FilterFieldActiveMissionList;
      } else {
        return FilterFieldsMission;
      }
    } else if (status === missionStatus.active) {
      return FilterFieldActiveMissionList;
    } else if (status === missionStatus.completed) {
      return FilterFieldCompleteMissionList;
    } else if (status === missionStatus.archive) {
      return FilterFieldsArchiveMission;
    } else if (status === missionStatus.missed) {
      return FilterFieldMissedMissions;
    } else if (status === missionStatus.rejected) {
      return FilterFieldRejectedMissions;
    } else {
      return FilterFieldsMission;
    }
  };

  const onChangeFilterField = (e, newValue) => {
    setFilterField(newValue);
    setchangedFilterMonitor(!changedFilterMonitor);
    const removedFilters = filterField.filter(
      (filter) => !newValue?.includes(filter)
    );
    if (newValue?.length === 0) {
      removedFilters.forEach((filter) => {
        valueChangeHandler("", filter);
      });
      setFilterData({});
    }
  };

  const getAdminStatus = (status) => {
    if (role === userRoles.admin) {
      if (status === missionStatus.active) {
        return adminActiveMissionStatus;
      }
      if (status === missionStatus.confirmedMission) {
        return adminConfirmedMissionStatus;
      }
    }
    if (role === userRoles.pilot) {
      if (status === missionStatus.active) {
        return pilotActiveMissionStatuses;
      }
      if (status === missionStatus.confirmedMission) {
        return pilotUnConfirmedMissionStatuses;
      }
    }
  };
  const adminStatus = getAdminStatus(status);

  const getStatusOptions = () => {
    if (role === userRoles.admin) {
      if (status === missionStatus.active) {
        if (missionType === missionTypeConstant.operatorSubmission) {
          return Object.values(operatorSubmissionStatus);
        }
        return Object.values(adminActiveMissionStatus);
      }
      if (status === missionStatus.confirmedMission) {
        return Object.values(adminConfirmedMissionStatus);
      }
    }
    if (role === userRoles.pilot) {
      if (status === missionStatus.active) {
        return Object.values(pilotActiveMissionStatuses);
      }
      if (status === missionStatus.confirmedMission) {
        return Object.values(pilotUnConfirmedMissionStatuses);
      }
    }
    if (
      missionType === missionTypeConstant.challengeMission ||
      missionType === missionTypeConstant.testMission ||
      missionType === missionTypeConstant.guinnessWorldRecord
    ) {
      return Object.values(otherMissionStatus);
    }
  };

  const statusOptions = getStatusOptions();

  const debounceHandler = useCallback(
    debounce((value, field) => {
      valueChangeHandler(value, field);
    }, 500),
    [valueChangeHandler, filterField]
  );

  const handleFilterChange = (value, field) => {
    const newData = { ...filterData, [field]: value };
    setFilterData(newData);
    debounceHandler(value, field);
  };

  return (
    <Box sx={styles.filterContainer}>
      <AutoCompleteFilter
        filterOptions={filterOptions}
        filterField={filterField}
        onChangeFilterField={onChangeFilterField}
        helperText="Select the fields to filter the missions with"
      />
      <Box sx={styles.filterFieldsCss}>
        {filterField?.includes("Mission Name") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Mission Name"]}
              onChange={(e) =>
                handleFilterChange(e.target.value, "Mission Name")
              }
              label="Mission Name"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Id") && (
          <Box>
            <TextField
              variant="standard"
              type="text"
              value={filterData["Id"]}
              onChange={(e) => handleFilterChange(e.target.value, "Id")}
              label="Id"
              sx={styles.field}
            />
          </Box>
        )}
        {filterField?.includes("Mission Type") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={missionTypes}
              value={filterData["Mission Type"]}
              onChange={(e, value) => handleFilterChange(value, "Mission Type")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Mission Type"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Mission Category") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={archiveMissionTypes}
              value={filterData["Mission Category"]}
              onChange={(e, value) =>
                handleFilterChange(value, "Mission Category")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Mission Category"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Mission Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={statusOptions}
              value={filterData["Mission Status"]}
              onChange={(e, value) => {
                const selectedKey = Object.keys(adminStatus).find(
                  (key) => adminStatus[key] === value
                );
                handleFilterChange(selectedKey, "Mission Status");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Mission Status"
                />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={statusOptions}
              value={filterData["Status"]}
              onChange={(e, value) => {
                handleFilterChange(value, "Status");
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Status" />
              )}
            />
          </Box>
        )}
        {filterField?.includes("Payment Status") && (
          <Box>
            <Autocomplete
              sx={styles.field}
              options={
                role === userRoles.pilot ? pilotPaymentStatus : PaymentStatus
              }
              value={filterData["Payment Status"]}
              onChange={(e, value) =>
                handleFilterChange(value, "Payment Status")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Payment Status"
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

FilterMission.propTypes = {
  valueChangeHandler: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  missionType: PropTypes.string,
};

export default FilterMission;
