import React from "react";
import GlobalTable from "../admin/GlobalTable";
import { navigate } from "gatsby";
import { Box } from "@mui/material";
import useGetContractList from "../../../hooks/useGetContractList";
import {
  contractStatus,
  columnForContractPilot,
  columnForContractAdmin,
  userRoles,
} from "../../../lib/constants";
import { getUser } from "../../../services/auth";
import StatusBadge from "../../global/elements/StatusBadge";
import Tips from "../../global/elements/Tips";
import Layout from "../../../Layout";
import UniversalLoader from "src/components/global/elements/UniversalLoader";

const ContractPage = () => {
  const [
    allContracts,
    loading,
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    isLastPage,
    lastPage,
    loadData,
    setLoadData,
  ] = useGetContractList();
  const status = contractStatus;
  const currentUser = getUser("user")?.role;

  const valueConvertHandler = (column, value) => {
    switch (column) {
      case "status": {
        return (
          <Box sx={{ display: "flex" }}>
            <StatusBadge
              name={status[value?.status][0]}
              status={status[value?.status][1]}
            />
          </Box>
        );
      }
      default:
        return;
    }
  };

  const goToPageFcn = (value) => {
    if (currentUser == userRoles.admin) {
      navigate(`/app/admin/dashboard/contracts/${value.id}`);
    } else {
      navigate(`/app/my-profile/contracts/${value.id}`);
    }
  };

  const limitHandler = (value) => {
    setLimit(value);
    setLoadData(!loadData);
  };

  const paginationBtnClick = (type) => {
    let pageNumberNew = currentPage;
    if (type == "next") {
      if (!(lastPage === currentPage)) {
        pageNumberNew = pageNumberNew + 1;
        setLoadData(!loadData);
      }
    } else {
      pageNumberNew = pageNumberNew - 1;
      setLoadData(!loadData);
    }
    setCurrentPage(pageNumberNew);
  };

  return (
    <Layout
      pageTitle={"Agreements"}
      hasSideMenu
      loading={loading}
      addBottomPadding
    >
      {loading ? (
        <Box sx={{ marginTop: "40vh" }}>
          <UniversalLoader />
        </Box>
      ) : (
        <>
          {currentUser === userRoles.admin ? (
            <Tips
              content={`Contracts are automatically created when the operator accepts a mission. They cannot upload data or get paid unless they sign the contract. 
          You can still waive contracts and allow pilots to upload data without signing any agreement. `}
            />
          ) : (
            <Tips
              content={`Here you will see a list of all your contracts with GLOBHE. Contracts are automatically created once you accept a mission. 
          Once you sign the mission contract, you can upload data to the mission and get paid later.`}
            />
          )}
          <br />
          <GlobalTable
            columns={
              currentUser == userRoles.admin
                ? columnForContractAdmin
                : columnForContractPilot
            }
            allData={allContracts}
            valueConvertHandler={valueConvertHandler}
            goToPageFcn={goToPageFcn}
            loading={loading}
            limitHandler={limitHandler}
            limit={limit}
            paginationBtnClick={paginationBtnClick}
            currentPage={currentPage}
            isLastPage={isLastPage}
            lastPage={lastPage}
          />
        </>
      )}
    </Layout>
  );
};

export default ContractPage;
