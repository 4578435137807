import PropTypes from "prop-types";
import React from "react";
import { Box, Typography } from "@mui/material";
import { useGetInterestList } from "../../../hooks/useGetInterestList";
import {
  interestInviteStatus,
  missionListSource,
} from "../../../lib/constants";
import UniversalLoader from "../../global/elements/UniversalLoader";
import StatusAlertContainer from "../../global/elements/StatusAlertContainer";
import { assignPilotToMissionThroughBidding } from "./../../../services/missions";
import { statusAlertSeverity } from "../../../lib/constants";
// import { maskFirstAndLastChar } from "../../../lib/helpers";
import MissionPilotsList from "../missions/MissionPilotsList";

const styles = {
  missionContainer: {
    width: "100%",
    padding: "0px",
  },
  unAuthorized: {
    width: "100%",
    marginTop: "10rem",
  },
};

const InterestInviteList = ({
  missionId = undefined,
  columnForPilot = undefined,
  mission = undefined,
  refreshPage = undefined,
  pilotID = undefined,
}) => {
  const [authorized, filterInterestList] = useGetInterestList(
    interestInviteStatus.pilotRequested,
    missionId,
    pilotID
  );

  const assignMission = async (data) => {
    try {
      await assignPilotToMissionThroughBidding(data, mission);
      if (refreshPage) {
        refreshPage();
      }
      return;
    } catch (e) {
      console.log("Error", e);
    }
  };
  return (
    <section>
      {authorized == true ? (
        <Box sx={styles.missionContainer}>
          {filterInterestList && filterInterestList?.length > 0 ? (
            <MissionPilotsList
              missionData={filterInterestList}
              columnForPilot={columnForPilot}
              source={missionListSource.interest}
              assignMission={(data) => assignMission(data)}
              mission={mission}
            />
          ) : (
            <Box>
              <Typography align="center" variant="h5">
                No drone operator has shown interest yet
              </Typography>
            </Box>
          )}
        </Box>
      ) : authorized == false ? (
        <Box sx={styles.unAuthorized}>
          <StatusAlertContainer
            heading="Unauthorized access! Your admin type does not allow you to work with missions.
            Please contact Napoleon to change your access rights."
            severity={statusAlertSeverity.error}
            coloredBackground
          />
        </Box>
      ) : (
        <UniversalLoader />
      )}
    </section>
  );
};

InterestInviteList.propTypes = {
  missionId: PropTypes.string.isRequired,
  mission: PropTypes.object.isRequired,
  columnForPilot: PropTypes.array.isRequired,
  refreshPage: PropTypes.func,
  pilotID: PropTypes.string,
};

// InterestInviteList.defaultProps = {
//   refreshPage: undefined,
//   pilotID: undefined,
// };

export default InterestInviteList;
